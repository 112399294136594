 <!-- 象形图配置 -->
<template>
  <div>
    <!-- text:标题颜色  -->
    <el-form-item :label="$t('optionPictorialbar.titleColor')">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
     <!-- text:标题大小  -->
    <el-form-item :label="$t('optionPictorialbar.titleSize')">
      <avue-input-number v-model="main.activeOption.fontSize"></avue-input-number>
    </el-form-item>
     <!-- text:数值颜色  -->
    <el-form-item :label="$t('optionPictorialbar.valueColor')">
      <avue-input-color v-model="main.activeOption.labelColor"></avue-input-color>
    </el-form-item>
     <!-- text:数值大小  -->
    <el-form-item :label="$t('optionPictorialbar.valueSize')">
      <avue-input-color v-model="main.activeOption.labelFontSize"></avue-input-color>
    </el-form-item>
     <!-- text:图标  -->
    <el-form-item :label="$t('optionPictorialbar.icon')">
      <img v-if="main.activeOption.symbol"
           :src="main.activeOption.symbol"
           alt=""
           width="100%" />
      <el-input v-model="main.activeOption.symbol">
        <div @click="main.handleOpenImg('activeOption.symbol')"
             slot="append">
          <i class="iconfont icon-img"></i>
        </div>
      </el-input>
    </el-form-item>
     <!-- text:图标大小  -->
    <el-form-item :label="$t('optionPictorialbar.iconSize')">
      <avue-input-number v-model="main.activeOption.symbolSize"></avue-input-number>
    </el-form-item>
     <!-- text:间距  -->
    <el-form-item :label="$t('optionPictorialbar.spacing')">
      <avue-slider v-model="main.activeOption.split"></avue-slider>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'pictorialbar',
  inject: ["main"]
}
</script>

<style>
</style>