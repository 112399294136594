<template>
<!-- text:分享  -->
  <el-dialog :title="$t('groupShare.share')"
             append-to-body
             :visible.sync="box"
             width="40%">
    <avue-form :option="option"
               v-model="form"
               @submit="handleSubmit">
      <template slot="href"
                slot-scope="{}">
        <el-input v-model="form.href"
                  type="textarea"
                  row="3">
        </el-input>
        <!-- text:查看大屏  -->
        <a @click="handleView">{{$t('groupShare.view_screen')}}</a>
        &nbsp;&nbsp;
        <!-- text:复制链接  -->
        <a @click="handleCopy">{{$t('groupShare.copy_link')}}</a>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import { updateObj } from '@/api/visual';
export default {
  inject: ["contain"],
  data () {
    return {
      box: false,
      form: {},
      visual: {},
      option: {
        emptyBtn: false,
        //text: 分享大屏
        submitText: this.$t('groupShare.share_screen'),
        size: 'small',
        span: 24,
        column: [{
           //text: 大屏名称
          label: this.$t('groupShare.screen_name'),
          prop: 'title',
        }, {
           //text: 分享
          label: this.$t('groupShare.share'),
          prop: 'status',
          type: 'switch',
          dicData: [{
            label: '',
            value: 0
          }, {
            label: '',
            value: 1
          }]
        }, {
           //text: 分享链接
          label: this.$t('groupShare.share_link'),
          type: 'textarea',
          prop: 'href'
        }, {
           //text: 分享密码
          label: this.$t('groupShare.share_password'),
          type: 'password',
          prop: 'password'
        }]
      }
    }
  },
  methods: {
    handleShow () {
      this.$parent.handleBuild(undefined, false);
      this.box = true
      let visual = this.contain.visual
      this.visual = visual
      this.form.title = visual.title
      this.form.status = visual.status
      this.form.href = location.origin + '/view/' + this.visual.id
      this.form.password = visual.password
    },
    handleView () {
      let routeUrl = this.$router.resolve({
        path: '/view/' + this.visual.id
      })
      window.open(routeUrl.href, '_blank');
    },
    handleCopy () {
      this.$Clipboard({
        text: this.visual.href
      }).then(() => {
         //text: 链接复制成功
        this.$message.success(this.$t('groupShare.link_copy_success'),)
      })

    },
    handleSubmit (form, done) {
      updateObj({
        id: this.visual.id,
        category: this.visual.category,
        password: this.form.password,
        status: this.form.status,
        title: this.form.title
      }).then(() => {
        this.contain.visual = Object.assign(this.contain.visual, this.form)
        done();
        this.box = false
         //text: 大屏分享成功
        this.$message.success(this.$t('groupShare.screen_share_success'),);
      })
    }
  }
}
</script>

<style>
</style>