<!-- 通用形配置 -->
<template>
  <div>
    <!-- text: 在线文档  点击查看 -->
    <el-form-item :label="$t('optionDatav.onlineDocument')">
      <a href="http://datav.jiaminghi.com/guide/"
         target="_blank">{{$t('optionDatav.clickToView')}}</a>
    </el-form-item>
    <!-- text: 模块名称 -->
    <el-form-item :label="$t('optionDatav.moduleName')">
      <avue-input v-model="main.activeOption.is"></avue-input>
    </el-form-item>
    <!-- text: 配置列表 编辑  -->
    <el-form-item :label="$t('optionDatav.configurationList')">
      <el-button size="small"
                 type="primary"
                 @click="openCode">{{$t('optionDatav.edit')}}</el-button>
    </el-form-item>
    <!-- text: 配置列表 -->
    <codeedit @submit="codeClose"
              :title="$t('optionDatav.configurationList')"
              v-model="code.obj"
              v-if="code.box"
              :type="code.type"
              :visible.sync="code.box"></codeedit>
  </div>
</template>

<script>
import codeedit from '../../page/group/code';
export default {
  name: 'datav',
  inject: ["main"],
  data () {
    return {
      code: {
        box: false,
        obj: {},
      }
    }
  },
  components: {
    codeedit
  },
  methods: {
    codeClose (value) {
      this.main.activeObj[this.code.type] = value;
    },
    openCode () {
      this.code.type = 'echartFormatter';
      this.code.obj = this.main.activeObj[this.code.type];
      this.code.box = true;
    },
  }
}
</script>

<style>
</style>