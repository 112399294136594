export default {
  compLibrary: {
    "Preview": "预览",
    "Run": "运行",
    "PreviewComponent": "预览组件",
    "ComponentName": "组件名称",
    "PlsEnterComponentName": "请输入组件名称",
    "ComponentData": "组件数据",
    "ComponentPreview": "组件预览",
    "DelConfirm": "此操作将永久删除, 是否继续?",
    "Prompt": "提示",
    "Confirm": "确定",
    "Cancel": "取消",
    "DelSuccess": "删除成功",
    "ModSuccess": "修改成功",
    "AddSuccess": "新增成功",
    add: '添加',
    description:'添加一个组件',
  },
};
