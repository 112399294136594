<template>
  <div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :title="title" width="700px" class="embeddedReport" @doClose="dialogVisible = false">
      <!-- text:你正在调试  -->
      <!-- text:的嵌入代码  -->
      <div class="top">{{ $t('embeddedReport.you_are_debugging') }} {{ parentData.visualName }} {{ $t('embeddedReport.embed_code') }}</div>
      <!-- text:嵌入配置  -->
      <el-descriptions style="color: #e9e9e9; margin-top: 10px" :title="$t('embeddedReport.embed_Configuration')"></el-descriptions>
      <div class="left">
        <el-form :model="formData" class="demo-form-inline" label-width="210px" :rules="rules">
          <!-- text: 嵌入对象 -->
          <el-form-item :label="$t('embeddedReport.embedded_Object')" prop="embeddedObject">
            <el-input v-model="formData.embeddedObject" clearable :style="{ width: '80%' }"></el-input>
          </el-form-item>
          <!-- text：安全认证类型 -->
          <el-form-item :label="$t('embeddedReport.security_Authentication_Type')" prop="certification">
            <el-radio-group v-model="formData.certification">
              <!-- text:Ticket认证  -->
              <el-radio label="1">{{ $t('embeddedReport.ticket_Authentication') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- text: Ticket链接 -->
          <el-form-item :label="$t('embeddedReport.ticket_Link')" prop="link">
            <!-- text:请输入Ticket链接  -->
            <!-- fix：不可以修改 -->
            <el-input disabled='true' v-model="formData.link" clearable :style="{ width: '70%', 'margin-right': '10px' }" :placeholder="$t('embeddedReport.please_enter')"></el-input>
            <!-- text: 快捷生成 -->
            <el-button v-if="ticketVisible" type="text" @click="ticketVisible = false">{{ $t('embeddedReport.quick_Generate') }}</el-button>
            <!-- text:收起 -->
            <el-button v-if="!ticketVisible" type="text" @click="ticketVisible = true">{{ $t('embeddedReport.collapse') }}</el-button>
          </el-form-item>
          <!-- text：获取嵌入代码 -->
          <el-form-item :label="$t('embeddedReport.get_Embed_Code_URL')" prop="embeddedCode">
            <el-radio-group v-model="formData.embeddedCode" :style="{ width: '80%', 'margin-right': '10px' }" @change="changeRadio">
              <!-- text:URL链接  -->
              <el-radio label="1">{{ $t('embeddedReport.uRL_Link') }}</el-radio>
              <!-- text:Iframe代码  -->
              <el-radio label="2">{{ $t('embeddedReport.iframe_Code') }}</el-radio>
            </el-radio-group>
            <!-- text：复制 -->
            <el-button v-clipboard:copy="formData.textareText" v-clipboard:success="firstCopySuccess" v-clipboard:error="firstCopyError" size="mini" type="primary">{{ $t('embeddedReport.copy') }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-input type="textarea" v-model="formData.textareText" clearable :style="{ width: '80%', 'margin-right': '10px' }"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="right" v-if="!ticketVisible">
        <!-- text:生成Ticket  -->
        <h3>{{ $t('embeddedReport.generate_Ticket') }}</h3>
        <el-form ref="generateTicket" :model="formDataRight" class="demo-form-inline" label-width="150px" :rules="rulesRight">
          <!-- text: 绑定用户 -->
          <el-form-item :label="$t('embeddedReport.bind_User')" prop="bindUserId">
            <el-select v-model="formDataRight.bindUserId" :placeholder="$t('newReport.select')" @change="changOption" :style="{ width: '50%', 'margin-right': '10px' }">
              <el-option v-for="item in userData" :key="item.id" :label="item.username" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- text：有效时长 -->
          <el-form-item :label="$t('embeddedReport.validity_Period')" prop="validDuration">
            <el-input v-model="formDataRight.validDuration" clearable :style="{ width: '41.5%', 'margin-right': '10px' }"></el-input>
            <!-- text:分钟  -->
            {{ $t('embeddedReport.minutes') }}
            <!-- text:默认过期时间为240分钟，支持自定义传失效时间。  -->
            <el-tooltip class="item" effect="dark" :content="$t('embeddedReport.the_default')" placement="top">
              <el-button type="text" icon="el-icon-info"></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- text：访问次数 -->
          <el-form-item :label="$t('embeddedReport.access_Count')" prop="accessCount">
            <el-input-number v-model="formDataRight.accessCount" controls-position="right" @change="handleChange" :min="1" :max="9999" :style="{ width: '50%', 'margin-right': '10px' }"></el-input-number>
            <!-- text:若输入1，表示生成的链接仅支持被访问1次。默认为1，最大支持输入9999次。  -->
            <el-tooltip class="item" effect="dark" :content="$t('embeddedReport.if_you')" placement="top">
              <el-button type="text" icon="el-icon-info"></el-button>
            </el-tooltip>
          </el-form-item>
        </el-form>
        <div class="">
          <!-- text:生成Ticket -->
          <el-button size="mini" type="primary" @click="generateTicket">{{ $t('embeddedReport.GenerateTicket') }}</el-button>
        </div>
      </div>
      <div slot="footer">
        <!-- text:关闭嵌入 -->
        <el-popover placement="top" width="160" v-model="deleteVisible">
          <!-- text:关闭嵌入后已经生成的链接会失效。确定要关闭吗？ -->
          <p>{{ $t('embeddedReport.tip') }}</p>
          <div style="text-align: right; margin: 0">
            <!-- text:取消 -->
            <el-button size="mini" type="text" @click="deleteVisible = false">{{ $t('embeddedReport.cancel') }}</el-button>
            <!-- text:确定 -->
            <el-button type="primary" size="mini" @click="handelConfirm">{{ $t('embeddedReport.confirm') }}</el-button>
          </div>
          <!-- text:关闭嵌入 -->
          <el-button size="mini" slot="reference" type="primary">{{ $t('embeddedReport.close_Embed') }}</el-button>
        </el-popover>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getTicket, removeUser } from '@/api/openPlatform';
import { getList } from '@/api/user';
export default {
  name: 'EmbConfigurationDialog',
  components: {},
  props: [],
  data() {
    return {
      dialogVisible: false,
      ticketVisible: true, //是否可编辑
      // text:新增嵌入报表
      title: this.$t('newReport.embeddedReport'),
      userData: [], //用户
      deleteVisible: false, //关闭嵌入状态
      formData: {
        embeddedObject: this.$t('embeddedReport.embeddedObject'),
        certification: '1',
        link: '',
        embeddedCode: '1',
        textareText: '',
      },
      formDataRight: {
        bindUserId: '',
        validDuration: '240',
        accessCount: 1,
      },
      // 左侧form rules
      rules: {
        embeddedObject: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        certification: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        link: [
          {
            required: true,
            // text:请输入Ticket链接
            message: this.$t('embeddedReport.please_enter'),
            trigger: 'blur',
          },
        ],
        embeddedCode: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      // 右侧form rules
      rulesRight: {
        bindUserId: [
          {
            required: true,
            message: '请选择用户',
            trigger: 'blur',
          },
        ],
        validDuration: [
          {
            required: true,
            message: '请输入有效时常',
            trigger: 'blur',
          },
        ],
        accessCount: [
          {
            required: true,
            message: '请输入访问次数',
            trigger: 'blur',
          },
        ],
      },
      parentData: {
        visualId: '',
      }, //父级数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 用户数据
    async getList() {
      const obj = {
        size: 10000,
        current: 1,
      };

      let res = await getList(obj);
      if (res.data.success) {
        const { records } = res.data.data;
        this.userData = records;
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    // 初始化，打开弹窗时
    onOpen(data) {
      this.parentData = data;

      this.dialogVisible = true;
      // 重置数据
      this.formDataRight = {
        bindUserId: '',
        validDuration: '240',
        accessCount: 1,
      };
      this.formData = {
        embeddedObject: this.$t('embeddedReport.embeddedObject'),
        certification: '1',
        link: '',
        embeddedCode: '1',
        textareText: '',
      };
      this.getTextareText();
    },
    close() {
      this.dialogVisible = false;
    },
    // 确定关闭
    async handelConfirm() {
      this.deleteVisible = false;

      // 调用接口
      let res = await removeUser({ embedAnalyIds: [this.parentData.id] });
      if (res.data.success) {
        this.$emit('refreshList');
        this.close();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    changeRadio() {
      this.getTextareText(this.formData.link);
    },
    // 复制成功
    firstCopySuccess(e) {
      if (!e.text) return;
      // text:复制成功
      this.$message.success(this.$t('embeddedReport.successful_replication') + e.text);
    },
    // 复制失败
    firstCopyError(e) {
      if (!e.text) return;
      // text:复制失败
      this.$message.error(this.$t('embeddedReport.replication_failure') + e.text);
    },
    getTextareText(val) {
      const { embeddedCode } = this.formData;
      let url = window.location.origin;

      let text = `${url}/preview/${this.parentData.visualId}`;
      // 如果 accessticket 存在
      if (val) {
        text = `${url}/preview/${this.parentData.visualId}?accessticket=${val}`;
        if (embeddedCode == 2) {
          text = `<iframe src="${url}/preview/${this.parentData.visualId}?accessticket=${val}"></iframe>`;
        }
      } else {
        if (embeddedCode == 2) {
          text = `<iframe src="${url}/preview/${this.parentData.visualId}"></iframe>`;
        }
      }

      this.formData.textareText = text;
    },
    // 生成Ticket
    async generateTicket() {
      this.$refs['generateTicket'].validate(async valid => {
        if (!valid) return;
        let obj = {
          id: this.parentData.id,
          ...this.formDataRight,
        };
        let res = await getTicket(obj);
        if (res.data.success) {
          const { accessticket } = res.data.data;
          this.formData.link = accessticket;
          this.getTextareText(accessticket);
          // text:生成Ticket成功
          this.$message.success(this.$t('embeddedReport.succeeded_Ticket'));
        } else {
          this.$message.error(res.data.subMsg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.el-dialog {
  position: relative;
  .top {
    margin-top: -25px;
    font-size: 14px;
    padding-bottom: 10px;
    background: rgba(84, 90, 255, 0.15);
    padding-top: 10px;
    padding-left: 10px;
    border-color: rgba(110, 84, 255, 1);
    box-shadow: inset 0px 0px 12px 0px #5475ff;
    color: #e9e9e9;
  }
  .right {
    background: rgba(32, 32, 35);
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(99.9%);
    height: 555px;
    padding-top: 18px;
    h3 {
      padding-left: 10px;
      margin-bottom: 15px;
    }
  }
}
</style>
