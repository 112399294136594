<!-- 文字配置 -->
<template>
  <div>
    <!-- text:文本内容  -->
    <el-form-item :label="$t('optionText.textContent')">
      <avue-input type="textarea"
                  v-model="main.activeObj.data.value"></avue-input>
    </el-form-item>
    <!-- text:字体类型  -->
    <el-form-item :label="$t('optionText.fontType')">
      <avue-select filterable
                   allow-create
                   v-model="main.activeOption.fontFamily"
                   :dic="dicOption.fontFamily"></avue-select>
    </el-form-item>
    <!-- text:字体大小  -->
    <el-form-item :label="$t('optionText.fontSize')">
      <avue-input-number v-model="main.activeOption.fontSize"
                         :max="200"></avue-input-number>
    </el-form-item>
    <!-- text:字体颜色  -->
    <el-form-item :label="$t('optionText.fontColor')">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
    <!-- text:字体间距  -->
    <el-form-item :label="$t('optionText.letterSpacing')">
      <avue-input-number v-model="main.activeOption.split"></avue-input-number>
    </el-form-item>
    <!-- text:字体行高  -->
    <el-form-item :label="$t('optionText.lineHeight')">
      <avue-input-number v-model="main.activeOption.lineHeight"></avue-input-number>
    </el-form-item>
    <!-- text:字体背景  -->
    <el-form-item :label="$t('optionText.fontBackground')">
      <avue-input-color v-model="main.activeOption.backgroundColor"></avue-input-color>
    </el-form-item>
    <!-- text:文字粗细  -->
    <el-form-item :label="$t('optionText.fontWeight')">
      <avue-select v-model="main.activeOption.fontWeight"
                   :dic="dicOption.fontWeight">
      </avue-select>
    </el-form-item>
    <!-- text:对齐方式  -->
    <el-form-item :label="$t('optionText.alignment')">
      <avue-select v-model="main.activeOption.textAlign"
                   :dic="dicOption.textAlign">
      </avue-select>
    </el-form-item>
    <el-collapse accordion>
      <!-- text:跑马灯设置  -->
      <el-collapse-item :title="$t('optionText.marqueeSetting')">
        <!-- text:开启  -->
        <el-form-item :label="$t('optionText.enableMarquee')">
          <avue-switch v-model="main.activeOption.scroll"></avue-switch>
        </el-form-item>
        <template v-if="main.activeOption.scroll">
          <!-- text:滚动速度  -->
          <el-form-item :label="$t('optionText.scrollingSpeed')">
            <avue-input v-model="main.activeOption.speed"></avue-input>
          </el-form-item>
        </template>
      </el-collapse-item>
      <!-- text:超链设置  -->
      <el-collapse-item :title="$t('optionText.hyperlinkSetting')">
        <!-- text:开启  -->
        <el-form-item :label="$t('optionText.enableHyperlink')">
          <avue-switch v-model="main.activeOption.link"></avue-switch>
        </el-form-item>
        <template v-if="main.activeOption.link">
         <!-- text:打开方式  -->
          <el-form-item :label="$t('optionText.openMode')">
            <avue-radio v-model="main.activeOption.linkTarget"
                        :dic="dicOption.target">
            </avue-radio>
          </el-form-item>
          <!-- text:超链地址  -->
          <el-form-item :label="$t('optionText.hyperlinkURL')">
            <avue-input v-model="main.activeOption.linkHref"></avue-input>
          </el-form-item>
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { dicOption } from '@/option/config'
export default {
  name: 'text',
  data () {
    return {
      dicOption: dicOption
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>