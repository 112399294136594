<!-- 翻牌器配置 -->
<template>
  <div>
    <!-- text:整体 -->
    <el-form-item :label="$t('optionFlop.overall')">
      <avue-switch v-model="main.activeOption.whole">
      </avue-switch>
    </el-form-item>
    <!-- text:精度 -->
    <el-form-item :label="$t('optionFlop.precision')">
      <avue-input-number v-model="main.activeOption.decimals">
      </avue-input-number>
    </el-form-item>
    <!-- text:长宽 -->
    <el-form-item :label="$t('optionFlop.width')">
      <avue-input-number v-model="main.activeOption.width">
      </avue-input-number>
      <avue-input-number v-model="main.activeOption.height">
      </avue-input-number>
    </el-form-item>
    <!-- text:外边距 -->
    <el-form-item :label="$t('optionFlop.margin')">
      <avue-input-number v-model="main.activeOption.marginTop"
                         :max="1000"></avue-input-number>
      <avue-input-number v-model="main.activeOption.marginLeft"
                         :max="1000"></avue-input-number>
    </el-form-item>
    <!-- text:内边距  -->
    <el-form-item :label="$t('optionFlop.padding')">
      <avue-input-number v-model="main.activeOption.paddingTop"
                         :max="1000"></avue-input-number>
      <avue-input-number v-model="main.activeOption.paddingLeft"
                         :max="1000"></avue-input-number>
    </el-form-item>
    <!-- text:边框 -->
    <el-form-item :label="$t('optionFlop.border')">
      <avue-switch v-model="main.activeOption.border">
      </avue-switch>
    </el-form-item>
    <template v-if="main.activeOption.border">
      <!-- text:边框颜色 -->
      <el-form-item :label="$t('optionFlop.borderColor')">
        <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
      </el-form-item>
      <!-- text:边框宽度 -->
      <el-form-item :label="$t('optionFlop.borderWidth')">
        <avue-input-number v-model="main.activeOption.borderWidth">
        </avue-input-number>
      </el-form-item>
      <!-- text:图片地址 -->
      <el-form-item :label="$t('optionFlop.imageURL')">
        <img v-if="main.activeOption.backgroundBorder"
             :src="main.activeOption.backgroundBorder"
             alt=""
             width="100%" />
        <el-input v-model="main.activeOption.backgroundBorder">
          <div @click="main.handleOpenImg('activeOption.backgroundBorder','border')"
               slot="append">
            <i class="iconfont icon-img"></i>
          </div>
        </el-input>
      </el-form-item>
    </template>
    <!-- text:背景颜色 -->
    <el-form-item :label="$t('optionFlop.backgroundColor')">
      <avue-input-color v-model="main.activeOption.backgroundColor"></avue-input-color>
    </el-form-item>
    <el-collapse accordion>
      <!-- text:内容设置 -->
      <el-collapse-item :title="$t('optionFlop.contentSetting')">
        <!-- text:内容 -->
        <el-form-item :label="$t('optionFlop.content')">
          <!-- <avue-input v-model="main.activeObj.data.value"></avue-input> -->
          <avue-input v-model="main.activeOption.value"></avue-input>
        </el-form-item>
        <!-- text:字体大小 -->
        <el-form-item :label="$t('optionFlop.fontSize')">
          <avue-input-number v-model="main.activeOption.fontSize"
                             :max="200"></avue-input-number>
        </el-form-item>
        <!-- text:字体颜色 -->
        <el-form-item :label="$t('optionFlop.fontColor')">
          <avue-input-color v-model="main.activeOption.color"></avue-input-color>
        </el-form-item>
        <!-- text:文字粗细 -->
        <el-form-item :label="$t('optionFlop.fontWeight')">
          <avue-select v-model="main.activeOption.fontWeight"
                       :dic="dicOption.fontWeight">
          </avue-select>
        </el-form-item>
        <!-- text:对其方式 -->
        <el-form-item :label="$t('optionFlop.alignment')">
          <avue-select v-model="main.activeOption.textAlign"
                       :dic="dicOption.textAlign">
          </avue-select>
        </el-form-item>
      </el-collapse-item>
      <!-- text:前缀设置 -->
      <el-collapse-item :title="$t('optionFlop.prefixSetting')">
        <!-- text:不换行 -->
        <el-form-item :label="$t('optionFlop.noLineBreak')">
          <avue-switch v-model="main.activeOption.prefixInline"></avue-switch>
        </el-form-item>
        <!-- text:前缀内容 -->
        <el-form-item :label="$t('optionFlop.prefixContent')">
          <!-- <avue-input v-model="main.activeObj.data.prefixText"></avue-input> -->
          <avue-input v-model="main.activeOption.prefixText"></avue-input>
        </el-form-item>
        <!-- text:对其方式 -->
        <el-form-item :label="$t('optionFlop.prefixAlignment')">
          <avue-select v-model="main.activeOption.prefixTextAlign"
                       :dic="dicOption.textAlign">
          </avue-select>
        </el-form-item>
        <!-- text:颜色 -->
        <el-form-item :label="$t('optionFlop.prefixColor')">
          <avue-input-color v-model="main.activeOption.prefixColor"></avue-input-color>
        </el-form-item>
        <!-- text:字体大小 -->
        <el-form-item :label="$t('optionFlop.prefixFontSize')">
          <avue-input-number v-model="main.activeOption.prefixFontSize"
                             :max="200">
          </avue-input-number>
        </el-form-item>
        <!-- text:字体行高 -->
        <el-form-item :label="$t('optionFlop.prefixLineHeight')">
          <avue-input-number v-model="main.activeOption.prefixLineHeight"
                             :max="200">
          </avue-input-number>
        </el-form-item>
      </el-collapse-item>
      <!-- text:后缀设置 -->
      <el-collapse-item :title="$t('optionFlop.suffixSetting')">
        <!-- text:不换行 -->
        <el-form-item :label="$t('optionFlop.noLineBreak')">
          <avue-switch v-model="main.activeOption.suffixInline"></avue-switch>
        </el-form-item>
        <!-- text:后缀内容 -->
        <el-form-item :label="$t('optionFlop.suffixContent')">
          <!--  <avue-input v-model="main.activeObj.data.suffixText"></avue-input> -->
          <avue-input v-model="main.activeOption.suffixText"></avue-input>
        </el-form-item>
        <!-- text:对其方式 -->
        <el-form-item :label="$t('optionFlop.prefixAlignment')">
          <avue-select v-model="main.activeOption.suffixTextAlign"
                       :dic="dicOption.textAlign">
          </avue-select>
        </el-form-item>
        <!-- text:颜色 -->
        <el-form-item :label="$t('optionFlop.suffixColor')">
          <avue-input-color v-model="main.activeOption.suffixColor"></avue-input-color>
        </el-form-item>
        <!-- text:字体大小 -->
        <el-form-item :label="$t('optionFlop.suffixFontSize')">
          <avue-input-number v-model="main.activeOption.suffixFontSize"
                             :max="200">
          </avue-input-number>
        </el-form-item>
        <!-- text:字体行高 -->
        <el-form-item :label="$t('optionFlop.suffixLineHeight')">
          <avue-input-number v-model="main.activeOption.suffixLineHeight"
                             :max="200">
          </avue-input-number>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { dicOption } from '@/option/config'
export default {
  name: 'flop',
  data () {
    return {
      dicOption: dicOption
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>