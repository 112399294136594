<!-- 通用形配置 -->
<template>
  <div>
    <!-- text:执行间隔  -->
    <el-form-item :label="$t('optionTime.executionInterval')">
      <avue-input-number v-model="main.activeOption.time">
      </avue-input-number>
    </el-form-item>
     <!-- text:执行逻辑  编辑 -->
    <el-form-item :label="$t('optionTime.executionLogic')">
      <el-button size="small"
                 type="primary"
                 @click="openCode">{{$t('optionTime.edit')}}</el-button>
    </el-form-item>
    <!-- text:执行逻辑  -->
    <codeedit @submit="codeClose"
              :title="$t('optionTime.executionLogic')"
              v-model="code.obj"
              v-if="code.box"
              :type="code.type"
              :visible.sync="code.box"></codeedit>
  </div>
</template>

<script>
import codeedit from '../../page/group/code';
export default {
  name: 'time',
  inject: ["main"],
  data () {
    return {
      code: {
        box: false,
        obj: {},
      }
    }
  },
  components: {
    codeedit
  },
  methods: {
    codeClose (value) {
      this.main.activeObj[this.code.type] = value;
    },
    openCode () {
      this.code.type = 'echartFormatter';
      this.code.obj = this.main.activeObj[this.code.type];
      this.code.box = true;
    },
  }
}
</script>

<style>
</style>