<!-- iframe配置 -->
<template>
  <div>
    <!-- text:装饰类型  -->
    <el-form-item :label="$t('optionDecoration.decorationType')">
      <avue-select v-model="main.activeOption.type"
                   :dic="dic">
      </avue-select>
    </el-form-item>
     <!-- text:主颜色  -->
    <el-form-item :label="$t('optionDecoration.primaryColor')">
      <avue-input-color v-model="main.activeOption.color1">
      </avue-input-color>
    </el-form-item>
     <!-- text:副颜色  -->
    <el-form-item :label="$t('optionDecoration.secondaryColor')">
      <avue-input-color v-model="main.activeOption.color2">
      </avue-input-color>
    </el-form-item>
     <!-- text:动画时长  -->
    <el-form-item :label="$t('optionDecoration.animationDuration')">
      <avue-input-number v-model="main.activeOption.dur">
      </avue-input-number>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'decoration',
  inject: ["main"],
  computed: {
    dic () {
      let result = new Array(12).fill('')
      return result.map((ele, index) => {
        index = index + 1;
        return {
          // text:装饰
          label: this.$t('optionClapper.decoration') + index,
          value: index
        }
      })
    }
  }
}
</script>

<style>
</style>