<template>
  <div class="build">
    <contentmenu ref="contentmenu"></contentmenu>
    <imglist ref="imglist" @change="handleSetimg"></imglist>
    <headers ref="headers"></headers>
    <top ref="top"></top>
    <div class="app" :class="{ 'app--none': !menuFlag }">
      <div class="menu" :style="{ width: setPx(this.menuParams.menuWidth) }" v-show="menuFlag && menuShow" @click.self="handleInitActive">
        <div class="menu__drag" ref="menuDrag" @mousedown="handleDrag($event, 'menu')"></div>
        <div class="menu_header title">
          <div class="title_box">
            <!-- text:图层 -->
            {{$t('build.Layer')}}
            <div class="title_menu">
              <div class="head_btn" :class="{ 'head_btn--active': layerType == 0 }" @click="layerType = 0">
                <i class="el-icon-monitor"></i>
              </div>
              <div class="head_btn" :class="{ 'head_btn--active': layerType == 1 }" @click="layerType = 1">
                <i class="el-icon-coin"></i>
              </div>
            </div>
          </div>
        </div>
        <el-scrollbar class="menu__scrollbar">
          <layer ref="layer" :type="layerType" style="padding: 0 5px" :nav="nav"></layer>
        </el-scrollbar>
      </div>
      <!-- 中间区域 -->
      <div ref="section" @contextmenu.prevent="() => {}" class="section">
        <div class="refer-line-img" @click="imgClick">
          <img :src="isShowReferLine ? imgOpenData : imgClose" />
        </div>
        <sketch-rule :thick="thick" :scale="scale" :width="width" :height="height" :startX="startX" :startY="startY" :isShowReferLine="isShowReferLine" :palette="palette" :shadow="shadow" :horLineArr="lines.h" :verLineArr="lines.v" />
        <div ref="screensRef" class="screens" @mousedown="handleInitActive" @wheel="handleWheel" @scroll="handleScroll">
          <div ref="containerRef" class="screen-container">
            <div class="canvas" ref="canvas" :style="canvasStyle">
              <container ref="container" :wscale="scale" pageType="view"></container>
            </div>
          </div>
        </div>
      </div>
      <div class="menu params" :style="{ width: setPx(this.menuParams.paramsWidth) }" v-show="menuFlag && paramsShow">
        <div class="menu__drag" ref="paramsDrag" @mousedown="handleDrag($event, 'params')" :style="{ left: '-4px' }"></div>
        <div class="menu_header">
          <el-tabs v-if="isMain" class="menu__tabs" @tab-click="handleTabClick" stretch v-model="menuTabs">
            <el-tab-pane name="0">
              <span slot="label">
                <i class="el-icon-setting"></i>
              <!-- text:配置 -->
                {{$t('build.Configuration')}}
              </span>
            </el-tab-pane>
            <!-- 数据配置 -->
            <el-tab-pane name="1" v-if="validProp('dataList')">
              <span slot="label">
                <i class="el-icon-document-copy"></i>
              <!-- text:数据 -->
                {{$t('build.Data')}}
              </span>
            </el-tab-pane>
            <!-- 交互事件配置 -->
            <el-tab-pane name="2" v-if="validProp('eventList')">
              <span slot="label">
                <i class="el-icon-thumb"></i>
              <!-- text:交互 -->
                {{$t('build.Interaction')}}
              </span>
            </el-tab-pane>
            <!-- 其他事件配置 -->
            <el-tab-pane name="3" v-if="!isFolder">
              <span slot="label">
                <i class="el-icon-mouse"></i>
              <!-- text:事件 -->
                {{$t('build.Event')}}
              </span>
            </el-tab-pane>
            <!-- 基本参数配置 -->
            <el-tab-pane name="4">
              <span slot="label">
                <i class="el-icon-folder"></i>
              <!-- text:参数 -->
                {{$t('build.Parameter')}}
              </span>
            </el-tab-pane>
          </el-tabs>
           <!-- text:配置 -->
          <p class="title">{{ isMain ? `${activeObj.title}（${activeObj.name}）` : `${$t('build.Configuration')}` }}</p>
        </div>
        <el-scrollbar class="menu__scrollbar" :style="scrollStyleName">
          <template v-if="menuTabs == 0">
            <el-form label-width="110px" label-position="left" size="small">
              <!-- 多选配置选项 -->
              <template v-if="isSelectActive">
                <!-- text:水平方式 -->
                <el-form-item :label="$t('build.Horizontal_Alignment')">
                  <!-- text:左对齐 -->
                  <el-tooltip :content="$t('build.Left_Alignment')" placement="top">
                    <i class="el-icon-s-fold icon" @click="$refs.container.handlePostionSelect('left')"></i>
                  </el-tooltip>
                  <!-- text:居中对齐 -->
                  <el-tooltip :content="$t('build.Center_Alignment')" placement="top">
                    <i class="el-icon-s-operation icon" @click="$refs.container.handlePostionSelect('center')"></i>
                  </el-tooltip>
                  <!-- text:右对齐 -->
                  <el-tooltip :content="$t('build.Right_Alignment')" placement="top">
                    <i class="el-icon-s-unfold icon" @click="$refs.container.handlePostionSelect('right')"></i>
                  </el-tooltip>
                </el-form-item>
                <!-- text:垂直方式 -->
                <el-form-item :label="$t('build.Vertical_Alignment')">
                  <!-- text:顶对齐 -->
                  <el-tooltip :content="$t('build.Top_Alignment')" placement="top">
                    <i class="el-icon-s-fold icon" @click="$refs.container.handlePostionSelect('top')"></i>
                  </el-tooltip>
                  <!-- text:中部对齐 -->
                  <el-tooltip :content="$t('build.Middle_Alignment')" placement="top">
                    <i class="el-icon-s-operation icon" @click="$refs.container.handlePostionSelect('middle')"></i>
                  </el-tooltip>
                  <!-- text:底对齐 -->
                  <el-tooltip :content="$t('build.Bottom_Alignment')" placement="top">
                    <i class="el-icon-s-unfold icon" @click="$refs.container.handlePostionSelect('bottom')"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label-width="0">
                  <!-- text:成组 -->
                  <el-button type="primary" size="small" class="block" @click="handleFolder">{{$t('build.Group')}}</el-button>
                </el-form-item>
                <el-form-item label-width="0">
                  <!-- text:删除 -->
                  <el-button type="danger" size="small" class="block" @click="handleDeleteSelect">{{$t('build.Delete')}}</el-button>
                </el-form-item>
              </template>
              <!-- 组件配置 -->
              <template v-else-if="activeIndex">
                <!-- text:图层名称 -->
                <el-form-item :label="$t('build.Layer_Name')">
                  <avue-input v-model="activeObj.name"></avue-input>
                </el-form-item>
                <template v-if="!isFolder">
                  <!-- text:隐藏 -->
                  <el-form-item :label="$t('build.Hide')">
                    <avue-switch v-model="activeObj.display"></avue-switch>
                  </el-form-item>
                  <!-- text:锁定 -->
                  <el-form-item :label="$t('build.Lock')">
                    <avue-switch v-model="activeObj.lock"></avue-switch>
                  </el-form-item>
                </template>
                <template v-if="validProp('colorList')">
                  <!-- text:系统配色 -->
                  <el-form-item :label="$t('build.System_Colors')">
                    <avue-switch v-model="activeOption.switchTheme"></avue-switch>
                  </el-form-item>
                </template>
                <component :is="activeComponent.prop + 'Option'"></component>
                <main-option></main-option>
              </template>
              <!-- 主屏的配置项 -->
              <template v-else>
                <!-- text:大屏大小 -->
                <el-form-item :label="$t('build.Screen_Size')">
                  <div class="flex">
                    <avue-input-number v-model="config.width"></avue-input-number>
                    &nbsp;x&nbsp;
                    <avue-input-number v-model="config.height"></avue-input-number>
                  </div>
                </el-form-item>
                <!-- text:大屏简介 -->
                <el-form-item :label="$t('build.Screen_Description')">
                  <avue-input v-model="config.info" type="textarea" :min-rows="5"></avue-input>
                </el-form-item>
                <!-- text:背景颜色 -->
                <el-form-item :label="$t('build.Background_Color')">
                  <avue-input-color v-model="config.backgroundColor"></avue-input-color>
                </el-form-item>
                <!-- text:背景图片 -->
                <el-form-item :label="$t('build.Background_Image')">
                  <img :src="config.backgroundImage" @click="handleOpenImg('config.backgroundImage', 'background')" alt="" width="100%" />
                  <el-input clearable v-model="config.backgroundImage">
                    <div @click="handleOpenImg('config.backgroundImage', 'background')" slot="append">
                      <i class="iconfont icon-img"></i>
                    </div>
                  </el-input>
                </el-form-item>
                <!-- text:预览方式 -->
                <el-form-item :label="$t('build.Preview_Mode')">
                  <el-radio-group v-model="config.screen">
                    <!-- text:X轴铺满，Y轴自适应滚动 -->
                    <el-radio label="x">{{$t('build.X_Axis_Fill')}}</el-radio>
                    <!-- text:Y轴铺满，X轴自适应滚动 -->
                    <el-radio label="y">{{$t('build.Y_Axis_Fill')}}</el-radio>
                    <!-- text:强行拉伸画面，填充所有视图 -->
                    <el-radio label="xy">{{$t('build.Stretch_Fill_View')}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label-width="0">
                  <!-- text:更多设置 -->
                  <el-button size="small" type="primary" class="block" @click="globShow = true">{{$t('build.More_Settings')}}</el-button>
                </el-form-item>
              </template>
            </el-form>
          </template>
          <template v-else-if="menuTabs == 1">
            <el-form label-width="120px" label-position="left" size="small">
              <!-- text:数据类型 -->
              <el-form-item :label="$t('build.Data_Type')">
                <avue-select v-model="activeObj.dataType" :dic="dicOption.dataType"></avue-select>
              </el-form-item>
              <template v-if="isSql">
                <!-- text:数据源选择 -->
                <el-form-item :label="$t('build.Data_Source_Selection')">
                  <avue-select :dic="DIC.sql" v-model="db"></avue-select>
                </el-form-item>
                <!-- text:SQL语句 -->
                <el-form-item :label="$t('build.SQL_Statement')" label-position="top">
                  <monaco-editor v-model="sql" language="sql" height="100"></monaco-editor>
                </el-form-item>
              </template>
              <template v-else-if="isApi">
                <!-- text:接口地址 -->
                <el-form-item :label="$t('build.API_Address')">
                  <avue-input v-model="activeObj.url"></avue-input>
                </el-form-item>
                <!-- text:请求方式 -->
                <el-form-item :label="$t('build.Request_Method')">
                  <avue-select v-model="activeObj.dataMethod" :dic="dicOption.dataMethod"></avue-select>
                </el-form-item>
              </template>
              <template v-else-if="isWs">
                <!-- text:WS地址 -->
                <el-form-item :label="$t('build.WS_Address')">
                  <el-input v-model="activeObj.wsUrl"></el-input>
                </el-form-item>
              </template>
              <template v-else-if="isRecord">
                <!-- text:数据集选择 -->
                <el-form-item :label="$t('build.Data_Set_Selection')">
                  <avue-select :dic="DIC.data" v-model="activeObj.record"></avue-select>
                </el-form-item>
              </template>
              <!-- text:刷新时间 -->
              <el-form-item :label="$t('build.Refresh_Time')" v-if="isApi || isWs || isSql">
                <avue-input-number v-model="activeObj.time" placeholder="0"></avue-input-number>
              </el-form-item>
              <el-form-item label-width="0">
                <!-- text:更多设置 -->
                <el-button size="small" type="primary" class="block" @click="handleSetting">{{$t('build.More_Settings')}}</el-button>
              </el-form-item>
              <el-form-item label-width="0">
                <!-- text:编辑样式 -->
                <el-button size="small" type="danger" class="block" @click="openCode('stylesFormatter', `${$t('build.Edit_Style')}`)">{{$t('build.Edit_Style')}}</el-button>
              </el-form-item>
              <el-form-item label-width="0">
                <!-- text:请求数据 -->
                <el-button size="small" type="primary" class="block" @click="handleRes">{{$t('build.Request_Data')}}</el-button>
              </el-form-item>
              <el-form-item label-width="0">
                <monaco-editor v-model="dataRes" disabled height="400"></monaco-editor>
              </el-form-item>
            </el-form>
          </template>
          <template v-else-if="menuTabs == 2">
            <el-form label-width="120px" label-position="left" size="small">
              <!-- text:子类 -->
              <el-form-item :label="$t('build.Subclass')">
                <avue-select multiple v-model="activeObj.child.index" :dic="childList" :props="childProps"></avue-select>
              </el-form-item>
              <!-- text:参数名称 -->
              <el-form-item :label="$t('build.Parameter_Name')">
                <avue-input v-model="activeObj.child.paramName"></avue-input>
              </el-form-item>
              <!-- text:映射字段 -->
              <el-form-item :label="$t('build.Mapped_Field')">
                <!-- text:默认为value -->
                <avue-input v-model="activeObj.child.paramValue" :placeholder="$t('build.Default_Value')"></avue-input>
              </el-form-item>
              <!-- text:其它参数 -->
              <el-form-item :label="$t('build.Other_Parameters')">
                <!-- text:新增参数 -->
                <el-button type="primary" @click="addParamsList">{{$t('build.Add_Parameter')}}</el-button>
              </el-form-item>
              <template v-if="activeObj.child.paramList">
                <!-- text:参数 -->
                <el-form-item :label="`${$t('build.Parameter')}` + (index + 1)" v-for="(item, index) in activeObj.child.paramList" :key="index">
                  <el-form-item label="" label-width="0">
                    <!-- text:参数名称 -->
                    <avue-input v-model="item.name" :placeholder="$t('build.Parameter_Name')"></avue-input>
                  </el-form-item>
                  <el-form-item label="" label-width="0">
                    <!-- text:映射字段 -->
                    <avue-input v-model="item.value" :placeholder="$t('build.Mapped_Field')"></avue-input>
                  </el-form-item>
                </el-form-item>
              </template>
            </el-form>
          </template>
          <template v-else-if="menuTabs == 3">
            <el-form label-width="120px" label-position="left" size="small">
              <!-- text:点击事件 -->
              <el-form-item :label="$t('build.Click_Event')">
                <!-- text:点击事件     编辑 -->
                <el-button size="small" type="primary" @click="openCode('clickFormatter', `${$t('build.Click_Event')}`)">{{$t('build.Edit')}}</el-button>
              </el-form-item>
              <template v-if="validProp('labelFormatterList')">
                <!-- text:提示事件 -->
                <el-form-item :label="$t('build.Tooltip_Event')">
                  <!-- text:提示事件    编辑 -->
                  <el-button size="small" type="primary" @click="openCode('formatter', `${$t('build.Tooltip_Event')}`)">{{$t('build.Edit')}}</el-button>
                </el-form-item>
                <!-- text:标题事件 -->
                <el-form-item :label="$t('build.Title_Event')">
                  <!-- text:标题事件    编辑-->
                  <el-button size="small" type="primary" @click="openCode('labelFormatter', `${$t('build.Title_Event')}`)">{{$t('build.Edit')}}</el-button>
                </el-form-item>
              </template>
            </el-form>
          </template>
          <template v-else-if="menuTabs == 4">
            <el-form label-width="90px" label-position="left" v-if="menuTabs == 4" size="small">
              <!-- text:序号 -->
              <el-form-item :label="$t('build.Number')">
                <avue-input @click.native="handleCopyIndex" v-model="activeIndex" disabled></avue-input>
              </el-form-item>
              <!-- text:配置 -->
              <el-form-item :label="$t('build.Configuration')">
                <monaco-editor v-model="activeObj" disabled language="javascript" height="100"></monaco-editor>
              </el-form-item>
              <template v-if="!isFolder">
                <!-- text:进入动画 -->
                <el-form-item :label="$t('build.Enter_Animation')">
                  <avue-select filterable allow-create v-model="activeComponent.animated" :dic="dicOption.animated"></avue-select>
                  <div>
                    <!-- text:点击查看动画类型 -->
                    <a href="https://www.dowebok.com/demo/2014/98/" target="_blank">{{$t('build.Click_View_Animation_Type')}}</a>
                  </div>
                </el-form-item>
                <!-- text:位置 -->
                <el-form-item :label="$t('build.Position')">
                  <div class="flex">
                    <avue-input-number style="width: 130px" v-model="activeObj.left"></avue-input-number>
                    &nbsp;x&nbsp;
                    <avue-input-number style="width: 130px" v-model="activeObj.top"></avue-input-number>
                  </div>
                </el-form-item>
                <!-- text:尺寸 -->
                <el-form-item :label="$t('build.Size')">
                  <div class="flex">
                    <avue-input-number style="width: 130px" v-model="activeComponent.width"></avue-input-number>
                    &nbsp;x&nbsp;
                    <avue-input-number style="width: 130px" v-model="activeComponent.height"></avue-input-number>
                  </div>
                </el-form-item>
                <!-- text:字体 -->
                <el-form-item :label="$t('build.Font')">
                  <!-- text:输入字体库的url -->
                  <avue-input v-model="activeComponent.fontFamily" :placeholder="$t('build.Input_Font_Library_URL')"></avue-input>
                </el-form-item>
                <!-- text:透视 -->
                <el-form-item :label="$t('build.Perspective')">
                  <avue-slider v-model="activeComponent.perspective" :max="1000"></avue-slider>
                </el-form-item>
                <!-- text:缩放 -->
                <el-form-item :label="$t('build.Scale')">
                  <avue-slider v-model="activeComponent.scale" :max="10"></avue-slider>
                </el-form-item>
                <!-- text:透明度 -->
                <el-form-item :label="$t('build.Opacity')">
                  <avue-slider :step="0.1" v-model="activeComponent.opacity" :max="1"></avue-slider>
                </el-form-item>
                <!-- text:X旋转度 -->
                <el-form-item :label="$t('build.X_Rotation')">
                  <avue-slider v-model="activeComponent.rotateX" :min="-360" :max="360"></avue-slider>
                </el-form-item>
                <!-- text:Y旋转度 -->
                <el-form-item :label="$t('build.Y_Rotation')">
                  <avue-slider v-model="activeComponent.rotateY" :min="-360" :max="360"></avue-slider>
                </el-form-item>
                <!-- text:Z旋转度 -->
                <el-form-item :label="$t('build.Z_Rotation')">
                  <avue-slider v-model="activeComponent.rotateZ" :min="-360" :max="360"></avue-slider>
                </el-form-item>
              </template>
            </el-form>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <codeedit @submit="closeCode" v-if="code.box" :title="code.title" :type="code.type" v-model="code.obj" :visible.sync="code.box"></codeedit>
    <!-- text:更多设置 -->
    <el-dialog append-to-body top="1%" :title="$t('build.More_Settings')" :close-on-click-modal="false" :visible.sync="globShow" width="70%">
      <el-form size="small" label-width="200px">
        <!-- text:全局请求地址 -->
        <el-form-item :label="$t('build.Global_Request_Address')">
          <avue-input v-model="config.url" placeholder="/"></avue-input>
        </el-form-item>
        <!-- text:全局请求参数 -->
        <el-form-item :label="$t('build.Global_Request_Parameters')">
          <monaco-editor v-model="config.query" language="javascript" disabled height="100"></monaco-editor>
          <!-- text: 全局请求参数  编辑 -->
          <el-button type="primary" icon="el-icon-edit" @click="openCode('query', `${$t('build.Global_Request_Headers')}`)">{{$t('build.Edit')}}</el-button>
        </el-form-item>
        <!-- text:全局请求头 -->
        <el-form-item :label="$t('build.Global_Request_Headers')">
          <monaco-editor v-model="config.header" language="javascript" disabled height="100"></monaco-editor>
          <!-- text:全局请求头 编辑 -->
          <el-button type="primary" icon="el-icon-edit" @click="openCode('header', `${$t('build.Global_Request_Headers')}`)">{{$t('build.Edit')}}</el-button>
        </el-form-item>
        <!-- text:大屏水印 -->
        <el-form-item :label="$t('build.Screen_Watermark')">
          <avue-switch v-model="config.mark.show"></avue-switch>
        </el-form-item>
        <template v-if="config.mark.show">
          <!-- text:内容 -->
          <el-form-item :label="$t('build.Content')">
            <avue-input v-model="config.mark.text"></avue-input>
          </el-form-item>
          <!-- text:大小 -->
          <el-form-item :label="$t('build.bigSize')">
            <avue-input-number v-model="config.mark.fontSize"></avue-input-number>
          </el-form-item>
          <!-- text:颜色 -->
          <el-form-item :label="$t('build.Color')">
            <avue-input-color v-model="config.mark.color"></avue-input-color>
          </el-form-item>
          <!-- text:角度 -->
          <el-form-item :label="$t('build.Angle')">
            <avue-input-number v-model="config.mark.degree"></avue-input-number>
          </el-form-item>
        </template>
      </el-form>
    </el-dialog>
    <!-- text:更多设置 -->
    <el-dialog append-to-body top="1%" :title="$t('build.More_Settings')" :close-on-click-modal="false" :visible.sync="show" width="70%">
      <el-form size="small" label-width="130px">
        <!-- text:数据类型 -->
        <el-form-item :label="$t('build.Data_Type')">
          <avue-select v-model="activeObj.dataType" :dic="dicOption.dataType"></avue-select>
        </el-form-item>
        <template v-if="isStatic">
          <!-- text:数据值 -->
          <el-form-item :label="$t('build.Data_Value')" label-position="top">
            <!-- text:数据值   编辑数据值 -->
            <el-button size="small" type="primary" icon="el-icon-edit" @click="openCode('data', `${$t('build.Data_Value')}`)">{{$t('build.Edit_Data_Value')}}</el-button>
            &nbsp;
            <el-upload :show-file-list="false" :auto-upload="false" style="display: inline-block" accept=".xls,.xlsx" :on-change="handleImport">
              <!-- text:导入数据(Excel) -->
              <el-button size="small" icon="el-icon-upload" type="success">{{$t('build.Import_Data_Excel')}}</el-button>
            </el-upload>
          </el-form-item>
        </template>
        <template v-else-if="isSql">
          <!-- text:数据源选择 -->
          <el-form-item :label="$t('build.Data_Source_Selection')">
            <avue-select :dic="DIC.sql" v-model="db"></avue-select>
          </el-form-item>

          <!-- text:SQL语句 -->
          <el-form-item :label="$t('build.SQL_Statement')" label-position="top">
            <!-- text:如果要获取变量，直接写成函数返回sql语句即可 -->
            <el-tooltip effect="dark" :content="$t('build.If_you_want')" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
            <monaco-editor v-model="sql" language="sql" height="100"></monaco-editor>
          </el-form-item>
        </template>
        <template v-else-if="isApi">
          <!-- text:接口地址 -->
          <el-form-item :label="$t('build.API_Address')">
            <avue-input v-model="activeObj.url"></avue-input>
          </el-form-item>
          <!-- text:请求方式 -->
          <el-form-item :label="$t('build.Request_Method')">
            <avue-select v-model="activeObj.dataMethod" :dic="dicOption.dataMethod"></avue-select>
          </el-form-item>
        </template>
        <template v-else-if="isWs">
          <!-- text:WS地址 -->
          <el-form-item :label="$t('build.WS_Address')">
            <el-input v-model="activeObj.wsUrl"></el-input>
          </el-form-item>
        </template>
        <template v-else-if="isRecord">
          <!-- text:数据集选择 -->
          <el-form-item :label="$t('build.Refresh_Time')">
            <avue-select :dic="DIC.data" v-model="activeObj.record"></avue-select>
          </el-form-item>
        </template>
        <!-- text:请求配置 -->
        <el-form-item :label="$t('build.Request_Configuration')" v-if="isWs || isApi">
          <el-tabs class="menu__tabs" v-model="dataTabs">
            <!-- text:请求参数（Body） -->
            <el-tab-pane :label="$t('build.Request_Parameter')" name="0">
              <template v-if="dataTabs == 0">
                <el-radio-group v-if="['post', 'put'].includes(activeObj.dataMethod)" v-model="activeObj.dataQueryType">
                  <!-- text:JSON数据 -->
                  <el-radio label="json">{{$t('build.JSON_Data')}}</el-radio>
                  <!-- text:FORM表单 -->
                  <el-radio label="form">{{$t('build.FORM_Data')}}</el-radio>
                </el-radio-group>
                <monaco-editor v-model="activeObj.dataQuery" language="javascript" disabled height="100"></monaco-editor>
                <!-- text:请求参数  编辑-->
                <el-button size="small" type="primary" icon="el-icon-edit" @click="openCode('dataQuery', `${$t('build.Request_parameters')}`)">{{$t('build.Edit')}}</el-button>
              </template>
            </el-tab-pane>
            <!-- text:请求头（Headers） -->
            <el-tab-pane :label="$t('build.Request_headers')" name="1" v-if="isApi">
              <template v-if="dataTabs == 1">
                <monaco-editor v-model="activeObj.dataHeader" language="javascript" disabled height="100"></monaco-editor>
                <!-- text:请求头   编辑   -->
                <el-button size="small" type="primary" icon="el-icon-edit" @click="openCode('dataHeader', `${$t('build.Request_header')}`)">{{$t('build.Edit')}}</el-button>
              </template>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <!-- text:过滤器 -->
        <el-form-item :label="$t('build.Filter')">
          <monaco-editor v-model="activeObj.dataFormatter" language="javascript" disabled height="100"></monaco-editor>
          <!-- text:编辑过滤器    编辑 -->
          <el-button size="small" type="primary" icon="el-icon-edit" @click="openCode('dataFormatter', `${$t('build.Edit_filter')}`)">{{$t('build.Edit')}}</el-button>
        </el-form-item>
        <!-- text:响应数据 -->
        <el-form-item :label="$t('build.Response_data')">
          <monaco-editor v-model="dataRes" disabled height="200"></monaco-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- text:请求数据 -->
        <el-button size="small" type="primary" icon="el-icon-refresh" @click="handleRes">{{$t('build.Request_Data')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MonacoEditor from '@/page/components/editor';
import layer from '@/page/group/layer';
import top from '@/page/group/top';
import headers from '@/page/group/header';
import imglist from '@/page/group/imglist';
import contentmenu from '@/page/group/contentmenu';
import codeedit from '@/page/group/code';
import { dicOption } from '@/option/config';
import init from '@/mixins/';
import { createFile } from '@/utils/utils';
import components from '@/option/components';
import SketchRule from 'vue-sketch-ruler';
import { getList as getDbList } from '@/api/db';
import { getList as getRecordList } from '@/api/record';
import crypto from '@/utils/crypto';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales'

export default {
  mixins: [init, components],
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales)
    return {
      layerType: 0,
      currentHistoryIndex: -1,
      menuId: 'avue-data-menu',
      menuShow: true,
      paramsShow: true,
      globShow: false,
      show: false,
      cacheList: {
        timer: null,
        nav: null,
        copy: null,
        history: [],
      },
      keys: {
        ctrl: false,
      },
      dataRes: '',
      db: '',
      sql: '',
      nav: [],
      DIC: {
        sql: [],
        data: [],
      },
      loading: '',
      childProps: {
        label: 'name',
        value: 'index',
      },
      key: '',
      menuParams: {
        menuWidth: 200,
        paramsWidth: 330,
      },
      menuFlag: true,
      code: {
        title: '',
        box: false,
        type: '',
        obj: '',
      },
      form: {},
      dicOption: dicOption,
      menuTabs: 0,
      dataTabs: 0,
      // 标尺
      width: document.body.clientWidth - 550,
      height: document.body.clientHeight - 104,
      scale: 0.94,
      startX: 0,
      startY: 0,
      palette: {
        bgColor: '#18181c',
        longfgColor: '#BABBBC',
        shortfgColor: '#9C9C9C',
        fontColor: '#fff',
        shadowColor: '#18181c',
        lineColor: '#EB5648',
        borderColor: '#B5B5B5',
        cornerActiveColor: '#fff',
      },
      lines: {
        h: [],
        v: [],
      },
      thick: 20,
      isShowReferLine: true,
      imgOpenData:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAbCAYAAAAOEM1uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAQNSURBVHja7JdvSON1HMdfP126/shSmaZ1ntuZbTLihOnSdJlPhIquB0VR1DZM9En0wCB3qCXKVOh86mmakdGDOqyHityBidYN1HPYZqbhOZprMGTOUk/9/XryWyxvek5NIu4Lg/H+fPj8Xt/P98/n8xUkSeK/PIT7gP8GoCAI8cTQAoWAHkgFRCAA3AKmgeBRA8VkOSZgMvAy8DZQCqQf4OcFRoDPgYmzAnwdaAAuxpFlCbgGfAR4ThUwYhcE4QngExnw71FWVuax2WwBk8mkSE9PV+7t7Ymrq6vbw8PD0uDgYO7CwsK5KPc14ENJkj497FtxAwqCYAK+kvcbANXV1U6Hw6HIyMh4GlAckJHwzMzMrM1my3a5XNoo01XgPUmSdk8MCLwEfAmoAPLz872jo6OrOTk5xVGBQ0tLS575+fnt7OzsRIPBcD4pKelctL2/v3+mtrbWLIpigixfA94BNk8C+JoMlyRn7WZvb68mISEhI+IQCASmKyoq0jweT25EU6lU4aGhoZnKykpzdNzl5eWbRqPxyWAwmCZL3wJvAHeOA/iCPMsHARwOx7jdbi+JXs7t7e3lrKys1LW1NVWsJXa73ZN6vb40WltfX3cbDIZ0r9ebKUv9wLvxAhYC1+V7je7u7rG6urrn9vu1tbWNNzU1lR90KgsLC5emp6cfB5TRejgc9mg0msyoTNYDXfEAjgFmQGxpaZlobm6OBbFrMpl+dTqd+YdcLztbW1ve5ORk7X6D3++f0ul0+aFQKAXYAF6RJOn6UQGDQBrgE0VRJQjCw7EAjUbj8tTUVN4hgLubm5u3lUrlhVjG+vr6ya6ursgWaJck6fJRAW8AzwNia2vrRGNjY8xltNvtEx0dHc8eRKfX62+73W418NB+m8/nm9LpdE+Fw+FHgD+AS/Fk8CJwI7IHe3t7x2pqau7agxsbG/NqtTp3a2tLGQtwcnJyvKSk5K7JhcPhnzQaTXYwGEyVpQ+AK8c5xd9EZt/e3v59Q0ND6f5LeXFx8cfy8vILfr9fHdEUCsVOX1/fhMViqdgfOxQKzRUUFKh9Pt+JTnHk76vyPZgcqR49PT3nExMTM/+x0XZ3fePj47/Mzc2RlZVFVVVVRkpKin5/3JWVFWdRUVFeIBCInN7v5NJ55ySV5EUZ8lEArVb728jIiDcvL++ZONqo0MDAwK2ampoyURQTZXlI7ob+PI1aXAR8AegiutVqdXZ2dt6zFs/OzrosFstjLpcr+iR3A+9LkrRzKs2CrGUCV4C3on3NZrPHarX+Xlxc/MARu5nLkiRdPbVuJsa4BDQCxjj6QRH4GvgY+PksOmqF3FG/KVcc9T066s+AH86y5Y8eOXI282XQyJtkRv6d/pvk/rPz/wT41wBibRrpeMs+PAAAAABJRU5ErkJggg==', // 左上角图片
      imgClose:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAPYSURBVHja7JVPTFRXFIe/+96gGRHEhQzBFMJKSKSkLTgtGxemSXcktohW2RBmTAjUClIYZ6Cxi+fQDgOCLJgJhqTSBAtLdjaxaSqQWnDSNp2U0I6FFh0SDdV0oMx7pwuZCUXjnyZNuvBsbnLvufe7555zfleJCP+1qReQfwVRSqWmqoFDIE3A+iZXQDbGlMmmNTatP5xPn/0ohOOgLgNtIB8DOlAKvAzsBTKBP4FF4Dvge1DrzwsBaAAuAJ8CxbpuezU/P397QcFLZGVlcf/+fRYXF1lc/G3VNJM/AJ8Dw8CdZ4QoQI4AIWBXaWkpQ0ND5v79+zW73Z5+n9XVVWZnb8rExIQ2MnKZWCz2M/Dhw1d4eiTngXafz2dmZ2ebPp9P6+vrl5qaI2p8fFyi0aheUlJiHj78tpaTs0sHJB6PW4HAJ3og0I2I+AHPkyAeu91uDA4OmrW1tRpAT09vsr29XXM4ciWZTJKXl2ctLCyoHTsy1ZUro+J0OjNSWR8ZGbFcLpeeSCTOAucfBykDpvr7+7c3NjamS+bevXvJ4uIS4vE7tra2tjW/32+7ffu21Ne7rJmZb7VIJKL27Nmjp0ADAwM0NjauAW+IyM2tkAGn8/WG69e/NjVNS20iHA6vd3Z2qrq6OtMwDFswGLROnz6dcffu3WRRUZEVCARwuVzbUv6WZVmVlZXa9PT0RRFp2gq56na7Dw0ODv6jGc6cOWNdu/Zl8saNb/RgMGi1tLSo7u5uaW5u1srLK8yDBw/aursD2ubmcbtPqnA4dFVE3twKuVBeXv7e5OSkabPZ0pGEQuG/WltbicV+0Xbv3m0LBnvWW1qatbNnvclLl4b0c+c+ErfblcoLa2trptPp1CORSEBEWrdCSoBpwzB2ejyedE6Wl5fNsrIyqaiokFAopBwOh/J4PKbf79/mcDiS0WiUnJwcWyoKr9erDMNYBg6ISOxx1dWg6/pAb2+vtZF8DWBqanr96NEalUgkpKCgQJaWljRN04jH43R1dcmpU6dsgBiGobxer3qoGnz2pD5pBfx1dXWaz+czi4qKNECtrKyYY2NjVjQa1fftK7aqq99hdHRUmpqaVEdHhzU/P58xPDycAN4Hws8iK28B/tzc3LJjx96lqqrKOnCggszMzLRmPHjwQM3MzFj19fX63NwcwDJQBUymK+ApEEDtBDkOnFBKvZafv9deWFhIdnYWKysr3Lr1K0tLvydE5CvgJ1AnQZqBi88DSV1aA0qAV4CCDRX+A1gAZoEfN/w/ALqAEyAjzwvZ8mc8KukblgGqD/gCZOyxkBd//P8G8vcAMK383pmr7aEAAAAASUVORK5CYII=',
    };
  },
  components: {
    MonacoEditor,
    imglist,
    layer,
    codeedit,
    top,
    headers,
    contentmenu,
    SketchRule,
  },
  computed: {
    isMain() {
      return this.isActive && !this.isSelectActive;
    },
    scrollStyleName() {
      let calc = this.setPx(!this.isMain ? 50 : 85);
      return { paddingTop: calc };
    },
    shadow() {
      return {
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
      };
    },
    // 能否撤销
    canUndo() {
      return this.currentHistoryIndex > 0;
    },
    canRedo() {
      return this.cacheList.history.length > this.currentHistoryIndex + 1;
    },
    isKeysCtrl() {
      return this.keys.ctrl == true;
    },
    isStatic() {
      return this.activeObj.dataType == 0;
    },
    isApi() {
      return this.activeObj.dataType == 1;
    },
    isSql() {
      return this.activeObj.dataType == 2;
    },
    isWs() {
      return this.activeObj.dataType === 3;
    },
    isRecord() {
      return this.activeObj.dataType == 4;
    },
    isFolder() {
      return this.activeObj.children;
    },
    isActive() {
      return this.activeIndex;
    },
    isSelectActive() {
      return this.active.length > 1;
    },
    childList() {
      return this.list.filter(ele => {
        if (ele.children) return false;
        let component = ele.component || {};
        return !['tabs'].includes(component.prop);
      });
    },
    activeComponent() {
      return this.activeObj.component || {};
    },
    activeOption() {
      return this.activeObj.option || {};
    },
    activeObj() {
      let item = this.findList(this.activeIndex) || {};
      if (!item.child) item.child = {};
      return item;
    },
    activeList() {
      let result = [];
      this.active.forEach(ele => {
        let item = this.findnav(ele);
        result.push(item.item);
      });
      return result;
    },
    canvasStyle() {
      return {
        width: this.setPx(this.width),
        height: this.setPx(this.height),
        transform: `scale(${this.scale})`,
      };
    },
  },
  watch: {
    nav: {
      handler(val, old) {
        this.recordHistoryCache(val);
      },
      deep: true,
    },
    activeObj: {
      handler(val) {
        if (this.activeObj.sql && this.isSql) {
          let mode = JSON.parse(crypto.decrypt(this.activeObj.sql));
          this.db = mode.id;
          this.sql = mode.sql;
        } else {
          this.db = '';
          this.sql = '';
        }
      },
      deep: true,
    },
    'activeObj.dataType'() {
      this.dataTabs = '0';
    },
    activeOverIndex(n, o) {
      [o, n].forEach((ele, index) => {
        if (!ele) return;
        this.setActive(ele, index === 1, 'setOverActive');
      });
    },
    active(n, o) {
      [o, n].forEach((ele, index) => {
        ele.forEach(item => {
          this.setActive(item, index === 1, 'setActive');
        });
      });
      // 初始化选项卡
      this.menuTabs = '0';
    },
  },
  created() {
    this.initDataList();
  },
  mounted() {
    setTimeout(() => {
      this.initFun();
      this.initSize();
      this.listenKey();
    });
  },
  methods: {
    addParamsList() {
      if (!this.activeObj.child.paramList) {
        this.$set(this.activeObj.child, 'paramList', []);
      }
      this.activeObj.child.paramList.push({
        name: '',
        value: '',
      });
    },
    handleDrag(e, name) {
      let resize = this.$refs[name + 'Drag'];
      if (!resize) return;
      let startX = e.clientX;
      document.onmousemove = e => {
        let endX = e.clientX;
        let moveLen = endX - startX;
        startX = endX;
        this.menuParams[name + 'Width'] += name == 'params' ? -moveLen : moveLen;
        this.$refs.headers.handleSet();
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      return false;
    },
    handleCopyIndex() {
      this.$Clipboard({
        text: this.activeObj.index,
      })
        .then(() => {
          //text:复制成功
          this.$message.success(this.$t('build.Copy_successful'));
        })
        .catch(() => {
          //text:复制失败
          this.$message.error(this.$t('build.Copy_failed'));
        });
    },
    handleTabClick(tab) {
      if (tab.name == '1') {
        this.handleRes(false);
      }
    },
    handleImport(file, fileLis) {
      this.$Export.xlsx(file.raw).then(data => {
        this.activeObj.data = data.results;
        //text:导入成功
        this.$message.success(this.$t('build.Import_successful'));
        this.handleRes();
      });
    },
    handleRefresh() {
      return this.$refs.container.handleRefresh();
    },
    handleRes(tip = true) {
      if (this.isSql) {
        this.$set(
          this.activeObj,
          'sql',
          crypto.encrypt(
            JSON.stringify({
              id: this.db,
              sql: this.sql,
            })
          )
        );
      }
      this.handleRefresh().then((res = {}) => {
        if (!this.validatenull(res)) {
          this.dataRes = JSON.stringify(res, null, 4);
        } else {
          this.dataRes = '';
        }

        //text:请求数据成功
        if (tip) this.$message.success(this.$t('build.Request_data'));
      });
    },
    handleParams(type, obj) {
      const deepList = (list, flag) => {
        list.forEach(ele => {
          ele[type] = flag;
          if (ele.children) deepList(ele.children, flag);
        });
      };
      if (obj) {
        obj[type] = !obj[type];
        deepList([obj], obj[type]);
      } else {
        this.active.forEach(ele => {
          let { item } = this.findnav(ele);
          item[type] = !item[type];
          deepList([item], item[type]);
        });
      }
      this.handleInitActive();
    },
    handleSetting() {
      this.dataTabs = 0;
      this.dataRes = '';
      this.handleRes(false);
      this.show = true;
    },
    initDataList() {
      getDbList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.DIC.sql = data.records.map(ele => {
          return {
            label: ele.name,
            value: ele.id,
          };
        });
      });
      getRecordList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.DIC.data = data.records.map(ele => {
          return {
            label: ele.name,
            value: ele.id,
          };
        });
      });
    },
    closeCode(value) {
      if (this.configData.includes(this.code.type)) {
        this.config[this.code.type] = value;
      } else {
        this.activeObj[this.code.type] = value;
      }
      this.handleRes(false);
    },
    openCode(type, title) {
      this.code.type = type;
      this.code.title = title;
      if (this.configData.includes(type)) {
        this.code.obj = this.config[type];
      } else {
        this.code.obj = this.activeObj[type];
      }
      this.code.box = true;
    },
    initFun() {
      ['setScale'].forEach(ele => {
        this[ele] = this.$refs.container[ele];
      });
    },
    // 右键菜单
    handleContextMenu(item = {}, done) {
      if (!item.index || this.isKeysCtrl) return;
      else if (!this.isSelectActive) {
        this.active = [item.index];
        this.activeIndex = item.index;
      }
      done();
    },
    //监听键盘的按键
    listenKey() {
      let section = this.$refs.section;
      section.onkeydown = e => {
        const keyCode = e.keyCode;
        e.preventDefault();
        if (keyCode == 17) {
          this.keys.ctrl = true;
        } else if (keyCode == 76 && this.isKeysCtrl) {
          if (!this.activeObj) return;
          this.handleParams('lock');
          this.handleInitActive();
        } else if (keyCode == 67 && this.isKeysCtrl) {
          if (!this.activeObj) return;
          this.$refs.contentmenu.handleCopy(fn => {
            this.cacheList.copy = fn;
            //text:复制组件成功
            this.$message.success(this.$t('build.Copy_component'));
          });
        } else if (keyCode == 86 && this.isKeysCtrl) {
          if (!this.cacheList.copy) return;
          let active = [];
          let { itemList } = this.findnav(this.activeIndex);
          itemList = itemList;
          this.cacheList.copy.forEach(ele => {
            active = active.concat(ele(itemList));
          });
          setTimeout(() => this.selectNav(active));
          //text:粘贴组件成功
          this.$message.success(this.$t('build.Paste_component'));
        } else if (keyCode == 90 && this.isKeysCtrl) {
          this.editorUndo();
        } else if (keyCode == 89 && this.isKeysCtrl) {
          this.editorRedo();
        } else if (keyCode == 83 && this.isKeysCtrl) {
          this.$refs.headers.handleBuild();
        } else if (keyCode == 8 || keyCode == 46) {
          this.$refs.contentmenu.handleDel();
        }
      };
      section.onkeyup = e => {
        const keyCode = e.keyCode;
        if (keyCode == 17) {
          this.keys.ctrl = false;
        }
      };
    },
    setActive(val, result, fun) {
      const obj = this.$refs.container.getListRef(val);
      if (obj) obj[fun](result);
    },
    //批量成组
    handleFolder() {
      let folder = createFile();
      this.active.forEach(index => {
        let { itemList, itemIndex } = this.findnav(index);
        let obj = itemList.splice(itemIndex, 1)[0];
        folder.children.push(obj);
      });
      this.nav.push(folder);
      this.handleInitActive();
    },
    //批量删除
    handleDeleteSelect() {
      //text:是否批量删除所选图层？   提示
      this.$confirm(this.$t('build.Batch_delete'), this.$t('build.Prompt'), {
        //text:确定
        confirmButtonText: this.$t('build.OK'),
        //text:取消
        cancelButtonText: this.$t('build.Cancel'),
        type: 'warning',
      }).then(() => {
        this.active.forEach(index => {
          let { itemList, itemIndex } = this.findnav(index);
          itemList.splice(itemIndex, 1);
        });
        this.handleInitActive();
      });
    },
    validProp(name) {
      return this.dicOption[name].includes(this.activeComponent.prop);
    },
    formatTooltip(val) {
      return parseInt(val);
    },
    //打开图库
    handleOpenImg(item, type) {
      this.$refs.imglist.openImg(item, type);
    },
    //图库框回调赋值
    handleSetimg(val, type) {
      let params = type.split('.')[1];
      if (type.includes('config')) {
        this.config[params] = val;
      } else if (type.includes('activeObj.data.value')) {
        this.activeObj.data.value = val;
      } else if (type.includes('activeObj.data')) {
        this.activeObj.data = val;
      } else if (type.includes('activeObj')) {
        this.activeObj[params] = val;
      } else if (type.includes('activeOption')) {
        this.activeOption[params] = val;
      }
    },
    handleScroll() {
      this.$nextTick(() => {
        const screensRect = this.$refs.screensRef.getBoundingClientRect();
        const canvasRect = this.$refs.canvas.getBoundingClientRect();
        // 标尺开始的刻度
        const startX = (screensRect.left + this.thick - canvasRect.left) / this.scale;
        const startY = (screensRect.top + this.thick - canvasRect.top) / this.scale;
        this.startX = startX >> 0;
        this.startY = startY >> 0;
      });
    },
    // 控制缩放值
    handleWheel(e) {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const nextScale = parseFloat(Math.max(0.2, this.scale - e.deltaY / 500).toFixed(2));
        this.scale = nextScale;
      }
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    // 初始化标尺数值
    initSize() {
      // 滚动居中
      let containerRect = this.$refs.containerRef.getBoundingClientRect();
      let screensRect = this.$refs.screensRef.getBoundingClientRect();
      this.$refs.screensRef.scrollLeft = containerRect.width / 2 - 10;
      this.$refs.screensRef.scrollTop = containerRect.height / 2 - 50;
    },
    // 图片点击事件
    imgClick() {
      this.isShowReferLine = !this.isShowReferLine;
    },
    selectNav(item) {
      if (this.isKeysCtrl) {
        if (Array.isArray(item)) {
          this.active = this.active.concat(item);
        } else {
          this.active.push(item);
        }
      }
      if (Array.isArray(item)) {
        this.active = item;
        this.activeIndex = item[item.length - 1];
      } else if (this.active.includes(item)) {
        this.activeIndex = item;
      } else {
        this.active = [item];
        this.activeIndex = item;
      }
    },
    recordHistoryCache(val) {
      const debounce = (func, delay) => {
        return () => {
          let context = this;
          let args = arguments;
          clearTimeout(this.cacheList.timer);
          this.cacheList.timer = setTimeout(() => {
            func.apply(context, args);
          }, delay);
        };
      };
      const callback = () => {
        let nav = JSON.stringify(val);
        if (nav != this.cacheList.nav) {
          this.cacheList.nav = nav;
          this.addHistoryCache(val);
        }
      };
      debounce(callback, 300)();
    },
    addHistoryCache(val) {
      if (this.currentHistoryIndex + 1 < this.cacheList.history.length) {
        this.cacheList.history.splice(this.currentHistoryIndex + 1);
      }
      this.cacheList.history.push({
        nav: this.deepClone(val),
      });
      this.cacheList.history.splice(100);
      this.currentHistoryIndex++;
    },
    editorUndo() {
      if (!this.canUndo) return;
      this.currentHistoryIndex--;
      this.recoveryHistoryCache();
    },
    editorRedo() {
      if (!this.canRedo) return;
      this.currentHistoryIndex++;
      this.recoveryHistoryCache();
    },
    recoveryHistoryCache() {
      const prevState = this.cacheList.history[this.currentHistoryIndex];
      this.nav = this.deepClone(prevState.nav);
      this.cacheList.nav = JSON.stringify(prevState.nav);
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/style.scss';
.refer-line-img {
  position: absolute;
  left: 0;
  z-index: 5;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}
.screens {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.screen-container {
  position: relative;
  width: 5000px;
  height: 3000px;
  background: url('/img/screen.png') repeat;
}

.dragghanle {
  cursor: pointer;
}
.canvas {
  position: absolute;
  top: 50%;
  left: 50%;
}
.section {
  flex: 1;
  overflow: hidden;
  position: relative;
}
</style>
