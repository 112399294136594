<template>
  <div class="pageCont">
    <!-- 新增按钮 -->
    <optionsButton :list="optionsList" />
    <tableSty>
      <div class="tableList">
        <el-table :data="tableData" style="width: 100%">
          <!-- text:姓名 -->
          <el-table-column prop="username" :label="$t('userControl.username')" width="180"></el-table-column>
          <!-- text:昵称 -->
          <el-table-column prop="nickname" :label="$t('userControl.nickname')" width="180"></el-table-column>
          <!-- text:性别 -->
          <el-table-column prop="sex" :label="$t('userControl.sex')">
            <template slot-scope="{ row }">
              {{ sexObj[row.sex] }}
            </template>
          </el-table-column>
          <!-- text:手机号 -->
          <el-table-column prop="phone" :label="$t('userControl.phone')"></el-table-column>
          <!-- text:创建时间 -->
          <el-table-column :label="$t('userControl.createTime')">
            <template slot-scope="{ row }">
              {{ row.createTime | conversionTime }}
            </template>
          </el-table-column>
          <!-- text:操作 -->
          <el-table-column :label="$t('userControl.action')" width="220">
            <template slot-scope="scope">
              <div class="options">
                <el-button @click="changePwd(scope.row)" type="text" size="small" class="edit">
                  <i class="el-icon-edit-outline"></i>
                  <!-- text:修改密码 -->
                  {{ $t('userControl.change_pwd') }}
                </el-button>
                <el-button @click="deleteDialog(scope.row)" type="text" size="small" class="del">
                  <i class="icon iconfont icon_icon_gm1_d-01"></i>
                  <!-- text:删除 -->
                  {{ $t('userControl.delete') }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <div class="pagination">
          <el-pagination v-if="total > 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="current" :page-sizes="[10, 20, 30, 50, 100]" :page-size="10" :layout="layout" :total="total"
            background></el-pagination>
        </div>
      </div>
    </tableSty>
    <!-- 弹窗 -->
    <!-- 新增用户 -->
    <UserDialog @refreshList="getList" ref="userDialog" />
    <!-- 修改密码 -->
    <ChangPwdDialog @refreshList="getList" ref="ChangPwdDialog" />
    <!-- 删除用户 -->
    <UserDeleteDialog @refreshList="getList" @deleteConfirm="deleteUser" ref="userDeleteDialog" />
  </div>
</template>
<script>
import UserDialog from './userDialog.vue';
import ChangPwdDialog from './changPwdDialog.vue';
import UserDeleteDialog from '@/page/components/deleteDialog';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales';
import { getList, removeUser } from '@/api/user';
import tableSty from '@/page/components/tableSty';
import { mapGetters } from 'vuex';
import optionsButton from '@/page/components/optionsButton';
export default {
  // 组件名字
  name: 'UserControl',
  components: {
    UserDialog,
    UserDeleteDialog,
    tableSty,
    ChangPwdDialog,
    optionsButton,
  },
  // 状态数据
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales);
    return {
      current: 1, //第几页
      size: 10, //每页多少条
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      tableData: [],
      sexObj: {
        M: '男',
        F: '女',
        N: '未知',
      },
      id: '',
      optionsList: [
        {
          icon: require('@/page/category/img/add.png'),
          title: this.$t('userControl.add'),
          description: this.$t('userControl.description'),
          click: () => {
            if (!this.authorityVerify('RESOURCE_USERMANAGEMENT_ADD_BTN')) return
            this.addUser();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    user(newVal) {
      this.changeRouter(newVal);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // talbe数据
    async getList() {
      const { size, current } = this;
      const obj = {
        size,
        current,
      };

      let res = await getList(obj);
      if (res.data.success) {
        const { total, size, current, records } = res.data.data;
        this.size = Number(size);
        this.total = Number(total);
        this.current = Number(current);
        this.tableData = records;
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    addUser() {
      this.$refs.userDialog.onOpen({ type: 'add' });
    },
    // 修改密码
    changePwd(data) {
      if (!this.authorityVerify('RESOURCE_USERMANAGEMENT_MODIFY_PWD_BTN')) return
      this.$refs.ChangPwdDialog.onOpen(data);
    },
    // 改变每页条数
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 修改页数
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },
    // 删除弹窗
    deleteDialog(data) {
      if (!this.authorityVerify('RESOURCE_USERMANAGEMENT_DEL_BTN')) return
      this.id = data.id;
      this.$refs.userDeleteDialog.onOpen();
    },
    // 删除用户
    async deleteUser() {

      let obj = {
        accountId: this.id,
        isDeleted: 1,
      };
      let res = await removeUser(obj);
      if (res.data.success) {
        this.getList();
        this.$message.success(res.data.msg);
        this.$refs.userDeleteDialog.close();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 监控路由
    changeRouter(data) {
      if (data.username != 'admin') {
        this.$router.push({ path: '/' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pageCont {
  padding: 10px 0;
}
</style>
