export default {
  map: {
    "AddMoreMaps": "Add more maps",
    "MapName": "Map Name",
    "PlsEnterMapName": "Please enter map name",
    "MapData": "Map Data",
    "DelConfirm": "This operation will permanently delete. Continue?",
    "Prompt": "Prompt",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "DelSuccess": "Deletion successful",
    "ModSuccess": "Modification successful",
    "AddSuccess": "Addition successful",
    "AddMap": "Add Map",
    "AddMapDescription":"Add map data for use in the screen.",
    "AddMapDescription2":"Add more map data from third-party sources.",
  }
}