<template>
  <div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :title="title" width="500px">
      <h3>{{ $t('deleteDialog.popover') }}</h3>
      <div slot="footer">
        <!-- text:取消 -->
        <el-button @click="dialogVisible = false">{{ $t('deleteDialog.Cancel') }}</el-button>
        <!-- text: 确定  -->
        <el-button type="primary" @click="confirm">{{ $t('deleteDialog.Confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales';
export default {
  name: 'deleteDialog',
  components: {},
  props: [],
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales);
    return {
      dialogVisible: false,
      // text:提示
      title: this.$t('deleteDialog.tip'),
      id: '',
    };
  },
  created() {
    // 设置国际化语言
    Object.keys(locales).forEach(key => {
      this.$i18n.mergeLocaleMessage(key, locales[key]);
    });
  },
  methods: {
    onOpen(id) {
      this.dialogVisible = true;
      this.id = id;
    },
    close() {
      this.dialogVisible = false;
    },
    async confirm() {
      this.$emit('deleteConfirm')
    },
  },
};
</script>
