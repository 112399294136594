import { render, staticRenderFns } from "./newEmbeddedDialog.vue?vue&type=template&id=5178061b&scoped=true&"
import script from "./newEmbeddedDialog.vue?vue&type=script&lang=js&"
export * from "./newEmbeddedDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5178061b",
  null
  
)

export default component.exports