<!-- 选项卡配置 -->
<template>
  <div>
    <!-- text:字体大小  -->
    <el-form-item :label="$t('optionTabs.fontSize')">
      <avue-input-number v-model="main.activeOption.fontSize"
                         :max="200"></avue-input-number>
    </el-form-item>
     <!-- text:字体颜色  -->
    <el-form-item :label="$t('optionTabs.fontColor')">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
     <!-- text:类型  -->
    <el-form-item :label="$t('optionTabs.type')">
      <avue-radio v-model="main.activeOption.type"
                  :dic="dicOption.tabsTypeList"></avue-radio>
    </el-form-item>
    <template v-if="main.activeOption.type==='tabs'">
       <!-- text:字体间距  -->
      <el-form-item :label="$t('optionTabs.letterSpacing')">
        <avue-input-number v-model="main.activeOption.split"></avue-input-number>
      </el-form-item>
      <el-collapse accordion>
         <!-- text:边框设置  -->
        <el-collapse-item :title="$t('optionTabs.borderSetting')">
           <!-- text:背景颜色  -->
          <el-form-item :label="$t('optionTabs.backgroundColor')">
            <avue-input-color v-model="main.activeOption.backgroundColor"></avue-input-color>
          </el-form-item>
           <!-- text:图标间距  -->
          <el-form-item :label="$t('optionTabs.iconSpacing')">
            <avue-input-number v-model="main.activeOption.iconSplit"></avue-input-number>
          </el-form-item>
           <!-- text:图标大小  -->
          <el-form-item :label="$t('optionTabs.iconSize')">
            <avue-input-number v-model="main.activeOption.iconSize"></avue-input-number>
          </el-form-item>
           <!-- text:图片地址  -->
          <el-form-item :label="$t('optionTabs.imageURL')">
            <img v-if="main.activeOption.backgroundImage"
                 :src="main.activeOption.backgroundImage"
                 alt=""
                 width="100%" />
            <el-input v-model="main.activeOption.backgroundImage">
              <div @click="main.handleOpenImg('activeOption.backgroundImage','border')"
                   slot="append">
                <i class="iconfont icon-img"></i>
              </div>
            </el-input>
          </el-form-item>
           <!-- text:边框颜色  -->
          <el-form-item :label="$t('optionTabs.borderColor')">
            <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
          </el-form-item>
           <!-- text:边框宽度  -->
          <el-form-item :label="$t('optionTabs.borderWidth')">
            <avue-input-number v-model="main.activeOption.borderWidth"
                               :max="10">
            </avue-input-number>
          </el-form-item>
        </el-collapse-item>
         <!-- text:高亮设置  -->
        <el-collapse-item :title="$t('optionTabs.highlightSetting')">
           <!-- text:字体高亮颜色  -->
          <el-form-item :label="$t('optionTabs.highlightFontColor')">
            <avue-input-color v-model="main.activeOption.empColor"></avue-input-color>
          </el-form-item>
           <!-- text:背景图片  -->
          <el-form-item :label="$t('optionTabs.backgroundImage')">
            <img v-if="main.activeOption.empBackgroundImage"
                 :src="main.activeOption.empBackgroundImage"
                 alt=""
                 width="100%" />
            <el-input clearable
                      v-model="main.activeOption.empBackgroundImage">
              <div @click="main.handleOpenImg('activeOption.empBackgroundImage','border')"
                   slot="append">
                <i class="iconfont icon-img"></i>
              </div>
            </el-input>
          </el-form-item>
           <!-- text:边框颜色  -->
          <el-form-item :label="$t('optionTabs.borderColor')">
            <avue-input-color v-model="main.activeOption.empBorderColor"></avue-input-color>
          </el-form-item>
           <!-- text:边框宽度  -->
          <el-form-item :label="$t('optionTabs.borderWidth')">
            <avue-input-number v-model="main.activeOption.empBorderWidth"
                               :max="10">
            </avue-input-number>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </template>
  </div>
</template>

<script>
import { dicOption } from '@/option/config'
export default {
  name: 'tabs',
  data () {
    return {
      dicOption: dicOption
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>