<template>
<!-- text:快捷键  -->
  <el-dialog :title="$t('groupKeys.shortcut_key')"
             append-to-body
             :visible.sync="show"
             width="50%">
    <div class="avue-crud">
      <el-table :data="data"
                size="mini">
                <!-- text:功能  -->
        <el-table-column prop="name"
                         :label="$t('groupKeys.function')">
        </el-table-column>
        <!-- text:快捷键  -->
        <el-table-column prop="value"
                         width="200"
                         :label="$t('groupKeys.shortcut_key')">
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      show: false,
      data: [{
        //text:向上移动
        name: this.$t('groupKeys.move_up'),
        value: '↑'
      }, {
        //text:向右移动
        name: this.$t('groupKeys.move_right'),
        value: '→'
      }, {
        //text:向下移动
        name: this.$t('groupKeys.move_down'),
        value: '↓'
      }, {
        //text:向左移动
        name: this.$t('groupKeys.move_left'),
        value: '←'
      }, {
        //text:长按向一个方向移动
        name: this.$t('groupKeys.long_press_to_move_in_one_direction'),
        value: 'SHIST'
      }, {
        //text:删除组件
        name: this.$t('groupKeys.delete_component'),
        value: 'DELETE'
      }, {
        //text:复制
        name: this.$t('groupKeys.copy'),
        value: 'CTRL + C'
      }, {
        //text:粘贴
        name: this.$t('groupKeys.paste'),
        value: 'CTRL + V'
      }, {
        //text:回退
        name: this.$t('groupKeys.undo'),
        value: 'CTRL + Y'
      }, {
        //text:前进
        name: this.$t('groupKeys.redo'),
        value: 'CTRL + Y'
      }, {
        //text:锁定/解锁
        name: this.$t('groupKeys.lock_unlock'),
        value: 'CTRL + L'
      }, {
        //text:保存大屏
        name: this.$t('groupKeys.save_screen'),
        value: 'CTRL + S'
      }, {
        //text:放大/缩小布局'
        name: this.$t('groupKeys.zoom_in_out_layout'),
        value: 'CTRL + 鼠标滑轮'
      }, {
        //text:单击文件夹图标可以全选
        name: this.$t('groupKeys.select_all_with_clicking_folder_icon'),
        value: '-'
      }, {
        //text:双击文件夹名称可以修改文字
        name: this.$t('groupKeys.modify_text_by_double_clicking_folder_name'),
        value: '-'
      }]
    }
  }
}
</script>