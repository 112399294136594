export default {
    home: {
        title: 'Choose a way to create',
        createScreen: 'Create New Screen',
        export: 'Export',
        preview: 'Preview',
        edit: 'Edit',
        modify: 'Modify',
        delete: 'Delete',
        copy: 'Copy',
        published: 'Published',
        unpublished: 'Unpublished',
        editScreen: 'Edit Screen',
        modifyScreen: 'Modify Screen',
        group: 'Group',
        selectGroup: 'Select a group',
        screenName: 'Screen Name',
        inputScreenName: 'Please input screen name',
        password: 'Password',
        publishStatus: 'Publish Status',
        unpublishedStatus: 'Unpublished',
        publishedStatus: 'Published',
        exportSuccess: 'Screen exported successfully',
        confirmCopy: 'Confirm to copy the current screen',
        confirm: 'Confirm',
        cancel: 'Cancel',
        copySuccess: 'Copy successful',
        confirmDelete: 'Are you sure to permanently delete?',
        deleteSuccess: 'Delete successful',
        modifySuccess: 'Modify successful',
        prompt: 'Prompt'
    }
}
