<!--折线图配置 -->
<template>
  <div>
    <el-collapse accordion>
      <!-- text:折线设置  -->
      <el-collapse-item :title="$t('optionLine.lineSetting')">
         <!-- text:平滑曲线  -->
        <el-form-item :label="$t('optionLine.smoothCurve')">
          <avue-switch v-model="main.activeOption.smooth">
          </avue-switch>
        </el-form-item>
         <!-- text:面积堆积  -->
        <el-form-item :label="$t('optionLine.stackedArea')">
          <avue-switch v-model="main.activeOption.areaStyle"></avue-switch>
        </el-form-item>
         <!-- text:线条宽度  -->
        <el-form-item :label="$t('optionLine.lineWidth')">
          <avue-slider v-model="main.activeOption.lineWidth">
          </avue-slider>
        </el-form-item>
         <!-- text:圆点  -->
        <el-form-item :label="$t('optionLine.dot')">
          <avue-switch v-model="main.activeOption.symbolShow">
          </avue-switch>
        </el-form-item>
         <!-- text:点的大小  -->
        <el-form-item :label="$t('optionLine.dotSize')"
                      v-if="main.activeOption.symbolShow">
          <avue-slider v-model="main.activeOption.symbolSize">
          </avue-slider>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'line',
  inject: ["main"]
}
</script>

<style>
</style>