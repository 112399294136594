<!-- 饼图的配置 -->
<template>
  <div>
    <el-collapse accordion>
      <!-- text:饼图设置  -->
      <el-collapse-item :title="$t('optionPie.pieChartSetting')">
        <!-- text:设置为环形  -->
        <el-form-item :label="$t('optionPie.setAsDonutChart')">
          <avue-switch v-model="main.activeOption.radius"></avue-switch>
        </el-form-item>
        <!-- text:南丁格尔玫瑰  -->
        <el-form-item :label="$t('optionPie.nightingaleRoseChart')">
          <avue-switch v-model="main.activeOption.roseType"></avue-switch>
        </el-form-item>
        <!-- text:自动排序  -->
        <el-form-item :label="$t('optionPie.autoSorting')">
          <avue-switch v-model="main.activeOption.sort"></avue-switch>
        </el-form-item>
        <!-- text:不展示零  -->
        <el-form-item :label="$t('optionPie.hideZeroValues')">
          <avue-switch v-model="main.activeOption.notCount"></avue-switch>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'pie',
  inject: ["main"]
}
</script>

<style>
</style>