<!-- 地图配置 -->
<template>
  <div>
    <!-- text:地图选择  请选择地图 -->
    <el-form-item :label="$t('optionMap.mapSelection')">
      <avue-select :dic="main.DIC.MAP"
                   v-model="main.activeOption.mapData"
                   :placeholder="$t('optionMap.pleaseSelectMap')"></avue-select>
    </el-form-item>
    <!-- text:地图比例 -->
    <el-form-item :label="$t('optionMap.mapScale')">
      <avue-slider v-model="main.activeOption.zoom"
                   :max="5"
                   :step="0.1"></avue-slider>
    </el-form-item>
    <!-- text:地图缩放 -->
    <el-form-item :label="$t('optionMap.mapZoom')">
      <avue-switch v-model="main.activeOption.roam"></avue-switch>
    </el-form-item>
    <el-collapse accordion>
      <!-- text:轮播设置 -->
      <el-collapse-item :title="$t('optionMap.carouselSetting')">
        <!-- text:开启轮播 -->
        <el-form-item :label="$t('optionMap.enableCarousel')">
          <avue-switch v-model="main.activeOption.banner"></avue-switch>
        </el-form-item>
        <template v-if="main.activeOption.banner">
          <!-- text:轮播时间 -->
          <el-form-item :label="$t('optionMap.carouselTime')">
            <avue-input v-model="main.activeOption.bannerTime"></avue-input>
          </el-form-item>
        </template>
      </el-collapse-item>
      <!-- text:基本设置 -->
      <el-collapse-item :title="$t('optionMap.basicSetting')">
        <!-- text:字体大小 -->
        <el-form-item :label="$t('optionMap.fontSize')">
          <avue-input-number v-model="main.activeOption.fontSize"></avue-input-number>
        </el-form-item>
        <!-- text:字体高亮颜色 -->
        <el-form-item :label="$t('optionMap.fontHighlightColor')">
          <avue-input-color v-model="main.activeOption.empColor"></avue-input-color>
        </el-form-item>
        <!-- text:字体颜色 -->
        <el-form-item :label="$t('optionMap.fontColor')">
          <avue-input-color v-model="main.activeOption.color"></avue-input-color>
        </el-form-item>
        <!-- text:边框颜色 -->
        <el-form-item :label="$t('optionMap.borderColor')">
          <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
        </el-form-item>
      </el-collapse-item>
      <!-- text:区域设置 -->
      <el-collapse-item :title="$t('optionMap.areaSetting')">
        <!-- text:区域线 -->
        <el-form-item :label="$t('optionMap.areaLine')">
          <avue-input-number v-model="main.activeOption.borderWidth"></avue-input-number>
        </el-form-item>
        <!-- text:区域颜色 -->
        <el-form-item :label="$t('optionMap.areaColor')">
          <avue-input-color v-model="main.activeOption.areaColor"></avue-input-color>
        </el-form-item>
        <!-- text:区域高亮颜色 -->
        <el-form-item :label="$t('optionMap.areaHighlightColor')">
          <avue-input-color v-model="main.activeOption.empAreaColor"></avue-input-color>
        </el-form-item>
      </el-collapse-item>
      <!-- text:提示设置 -->
      <el-collapse-item :title="$t('optionMap.tooltipSetting')">
        <!-- text:背景色 -->
        <el-form-item :label="$t('optionMap.backgroundColor')">
          <avue-input-color v-model="main.activeOption.tipBackgroundColor"></avue-input-color>
        </el-form-item>
        <!-- text:文字颜色 -->
        <el-form-item :label="$t('optionMap.textColor')">
          <avue-input-color v-model="main.activeOption.tipColor"></avue-input-color>
        </el-form-item>
        <!-- text:文字大小 -->
        <el-form-item :label="$t('optionMap.textSize')">
          <avue-input-color v-model="main.activeOption.tipFontSize"></avue-input-color>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'map',
  inject: ["main"]
}
</script>

<style>
</style>