<template>
  <div class="db">
    <tableSty>
      <avue-crud :option="option" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud"
        @row-update="rowUpdate" @row-save="rowSave" @row-del="rowDel" @refresh-change="refreshChange" @on-load="onLoad">
        <!-- text:测试连接  -->
        <template slot="menuForm">
          <el-button type="primary" size="small" @click="handleTest()">{{ $t('db.test_connection') }}</el-button>
        </template>
        <template slot="menu" slot-scope="{ row, size, type }">
          <!-- text:测试连接  -->
          <el-button icon="el-icon-check" :size="size" :type="type" @click="handleTest(row)">{{ $t('db.test_connection')
          }}</el-button>
        </template>
        <template slot-scope="scope" slot="menuLeft">
          <optionsButton :list="optionsList" />
        </template>
      </avue-crud>
    </tableSty>
  </div>
</template>

<script>
import { getList, getDetail, add, update, remove, dbTest } from '@/api/db';
import tableSty from '@/page/components/tableSty';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales';
import optionsButton from '@/page/components/optionsButton';
export default {
  components: {
    tableSty,
    optionsButton,
  },
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales);
    return {
      form: {},
      query: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      connectionUrlMap: {
        'com.mysql.cj.jdbc.Driver': 'jdbc:mysql://ip:port/dbName',
        'org.postgresql.Driver': 'jdbc:postgresql://ip:port/dbName',
        'oracle.jdbc.OracleDriver': 'jdbc:oracle:thin:@ip:port:serverName',
        'com.microsoft.sqlserver.jdbc.SQLServerDriver': 'jdbc:sqlserver://ip:port;DatabaseName=dbName',
      },
      option: {
        // 取消菜单 Fixed
        menuFixed: false,
        indexFixed: false,

        addBtn: false,

        // 新建按钮
        addBtnText: this.$t('crud.add'),

        // 编辑按钮
        editBtnText: this.$t('crud.editBtnText'),
        editBtnIcon: 'el-icon-edit-outline',

        // 删除按钮
        delBtnText: this.$t('crud.delBtnText'),
        delBtnIcon: 'icon iconfont icon_icon_gm1_d-01',

        // 修改按钮
        updateBtnText: this.$t('crud.update'),

        // 取消按钮
        cancelBtnText: this.$t('crud.cancel'),

        // 复制按钮
        copyBtnText: this.$t('crud.copyBtnText'),

        // 保存按钮
        saveBtnText: this.$t('crud.saveBtnText'),

        // 操作文字
        menuTitle: this.$t('crud.action'),
        index: true,
        copyBtn: true,
        labelWidth: 110,
        column: [
          {
            // text:名称
            label: this.$t('db.name'),
            prop: 'name',
            width: 120,
            rules: [
              {
                required: true,
                // text:请输入数据源名称
                message: this.$t('db.enter_data_source_name'),
                trigger: 'blur',
              },
            ],
          },
          {
            // text:驱动类
            label: this.$t('db.driver_class'),
            prop: 'driverClass',
            type: 'select',
            control: (val) => {
              if (val) {
                return {
                  url: {
                    placeholder: `${this.$t('db.please_enter')}：${this.connectionUrlMap[val]}`,
                  }
                }
              }
            },
            dicData: [
              {
                label: 'com.mysql.cj.jdbc.Driver',
                value: 'com.mysql.cj.jdbc.Driver',
              },
              {
                label: 'org.postgresql.Driver',
                value: 'org.postgresql.Driver',
              },
              {
                label: 'oracle.jdbc.OracleDriver',
                value: 'oracle.jdbc.OracleDriver',
              },
              {
                label: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
                value: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
              },
            ],
            width: 200,
            rules: [
              {
                required: true,
                // text:请输入驱动类
                message: this.$t('db.enter_driver_class'),
                trigger: 'blur',
              },
            ],
          },
          {
            // text:用户名
            label: this.$t('db.username'),
            prop: 'username',
            width: 120,
            rules: [
              {
                required: true,
                // text:请输入用户名
                message: this.$t('db.enter_username'),
                trigger: 'blur',
              },
            ],
          },
          {
            // text:密码
            label: this.$t('db.password'),
            prop: 'password',
            type: 'password',
            hide: true,
            rules: [
              {
                required: true,
                // text:请输入密码
                message: this.$t('db.enter_password'),
                trigger: 'blur',
              },
            ],
          },
          {
            // text:连接地址
            label: this.$t('db.connection_url'),
            prop: 'url',
            type: 'textarea',
            placeholder: this.conUrlPlaceholder,
            span: 24,
            rules: [
              {
                required: true,
                // text:请输入连接地址
                message: this.$t('db.enter_connection_url'),
                trigger: 'blur',
              },
            ],
          },
          // {
          //   // text:备注
          //   label: this.$t('db.note'),
          //   prop: 'remark',
          //   span: 24,
          //   minRows: 3,
          //   hide: true,
          //   type: 'textarea',
          // },
        ],
      },
      data: [],
      optionsList: [
        {
          icon: require('@/page/category/img/add.png'),
          title: this.$t('db.add'),
          description: this.$t('db.description'),
          click: () => {
            if (!this.authorityVerify('RESOURCE_DATASOURCEMANAGEMENT_ADD_DATASOURCE_BTN')) return
            this.$refs.crud.rowAdd();
          },
        },
      ],
    };
  },
  methods: {
    vaildData(id) {
      return [0, 1, 2, 3].includes(id);
    },
    rowSave(row, done, loading) {

      add(row).then(
        () => {
          this.onLoad();
          this.$message({
            type: 'success',
            // text:操作成功
            message: this.$t('db.operation_success'),
          });
          done();
        },
        error => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {

      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip);
        done();
        return false;
      }
      update(row).then(
        () => {
          done();
          this.onLoad();
          this.$message({
            type: 'success',
            // text:操作成功
            message: this.$t('db.operation_success'),
          });
        },
        error => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowDel(row, index) {
      if (!this.authorityVerify('RESOURCE_DATASOURCEMANAGEMENT_DEL_DATASOURCE_BTN')) return
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip);
        return false;
      }
      // text:确定将选择数据删除
      this.$confirm(this.$t('db.confirm_to_delete_data'), {
        // text:确定
        confirmButtonText: this.$t('db.confirm'),
        // text:取消
        cancelButtonText: this.$t('db.cancel'),
        type: 'warning',
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad();
          this.$message({
            type: 'success',
            // text:操作成功
            message: this.$t('db.connect_success'),
          });
        });
    },
    beforeOpen(done, type) {
      console.log(type, 111111);
      if (!this.authorityVerify('RESOURCE_DATASOURCEMANAGEMENT_UPDATE_DATASOURCE_BTN')) return
      if (!this.authorityVerify('RESOURCE_DATASOURCEMANAGEMENT_COPY_DATASOURCE_BTN')) return
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data.data;
        });
      }
      done();
    },
    refreshChange() {
      this.onLoad();
    },
    handleTest(form) {
      if (!this.authorityVerify('RESOURCE_DATASOURCEMANAGEMENT_TEST_DATASOURCE_BTN')) return
      form = form || this.form;
      dbTest({
        driverClass: form.driverClass,
        url: form.url,
        username: form.username,
        password: form.password,
      }).then(res => {
        const data = res.data;
        if (data.success) {
          // text:连接成功
          this.$message.success(this.$t('db.connect_success'));
        } else {
          // text:连接失败，请检查相关配置参数
          this.$message.error(this.$t('db.connect_failure_check_config'));
        }
      });
    },
    onLoad() {
      getList({
        current: this.page.currentPage,
        size: this.page.pageSize,
      }).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.db {
  padding-top: 10px;

  ::v-deep .iconfont {
    font-size: 14px;
  }

  ::v-deep i {
    font-size: 14px;
  }

  ::v-deep .el-button--text {
    color: rgb(128, 126, 255);
  }

  ::v-deep .el-button--text:nth-child(3) {
    color: rgb(255, 126, 126);
  }

  ::v-deep .avue-crud {
    padding: 0;
  }
}
</style>
