export default {
  compLibrary: {
    "Preview": "Preview",
    "Run": "Run",
    "PreviewComponent": "Preview Component",
    "ComponentName": "Component Name",
    "PlsEnterComponentName": "Please enter component name",
    "ComponentData": "Component Data",
    "ComponentPreview": "Component Preview",
    "DelConfirm": "This operation will permanently delete. Continue?",
    "Prompt": "Prompt",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "DelSuccess": "Deletion successful",
    "ModSuccess": "Modification successful",
    "AddSuccess": "Addition successful",
    add: 'Add',
    description:'Add Component',
  },
};
