<template>
  <el-container class="list" :style="{ height: setPx(height) }">
    <!-- <el-aside width="180px">
      <ul class="menu">
        <li :index="item.categoryValue" :key="item.categoryValue" v-for="item in typeList"
          :class="{ 'is-active': item.categoryValue === activeName }" @click="handleSelect(item.categoryValue)">
          <i class="el-icon-s-order"></i>
          {{ item.categoryKey }}
        </li>
      </ul>
    </el-aside> -->
    <el-container>
      <el-header class="content__header">
        <p class="avue-tip-title">{{ $t('home.title') }}</p>
        <div class="content__box content__nav">
          <div class="content__add" @click="handleAdd">
            <img :src="`${$router.options.base}img/new-project.png`" height="40px" alt="">
            <p>{{ $t('home.createScreen') }}</p>
          </div>
          <div class="content__page">
            <el-pagination v-if="page.total > 0" layout="total, sizes, prev, pager, next, jumper" background size="small"
              @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="page.size"
              :current-page.sync="page.page" :total="page.total">
            </el-pagination>
          </div>
        </div>
      </el-header>
      <el-main class="content">
        <div class="content__box">
          <div class="content__item" v-for="(item, index) in list" :key="index" @mouseover="item._menu = true"
            @mouseout="item._menu = false">
            <div class="content__info">
              <img v-if="item.backgroundUrl" :src="item.backgroundUrl" alt="" />
              <div class="content__menu" v-show="item._menu">
                <div class="content__right">
                  <el-tooltip :content="$t('home.export')">
                    <i class="el-icon-download" @click="handleExport(item, index)"></i>
                  </el-tooltip>
                  <el-tooltip :content="$t('home.preview')">
                    <i class="el-icon-view" @click="handleViews(item, index)"></i>
                  </el-tooltip>
                </div>
                <div class="content__btn" @click="handleEdit(item)">
                  {{$t('home.edit')}}
                </div>
                <div class="content__list">
                  <el-tooltip :content="$t('home.modify')">
                    <i class="el-icon-edit" @click="handleUpdate(item, index)"></i>
                  </el-tooltip>
                  <el-tooltip :content="$t('home.delete')">
                    <i class="el-icon-delete" @click="handleDel(item, index)"></i>
                  </el-tooltip>
                  <el-tooltip :content="$t('home.copy')">
                    <i class="el-icon-copy-document" @click="handleCopy(item, index)"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="content__main">
              <span class="content__name">{{ item.title }}</span>
              <div class="content__menulist">
                <div class="content__view">
                </div>
                <span class="content__status" :class="{ 'content__status--active': item.status }">
                  {{ item.status == 1 ? $t('home.published') : $t('home.unpublished') }}
                </span>
              </div>

            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog :title="$t('home.editScreen')" width="50%" :close-on-click-modal="false" :visible.sync="box">
      <avue-form :option="option" v-model="form" @submit="handleSave"></avue-form>
    </el-dialog>
  </el-container>
</template>
<script>
import { getList, updateObj, delObj, getCategory, copyObj } from '@/api/visual';
import { getObj } from '@/api/visual'
import { url } from '@/config';
import locales from './locales'
import { bindLocalesToVue } from '@/utils/utils';
import { mapGetters } from 'vuex'
export default {
  name: "list",
  computed: {
      ...mapGetters(['myGroups']),
  },
  data() {
    // 设置国际化语言
    bindLocalesToVue(this,locales)

    return {
      categoryId:'',

      height: 0,
      typeList: [],
      box: false,
      option: {
        submitText: this.$t('home.modifyScreen'),
        column: [
          {
            label: this.$t('home.group'),
            prop: 'category',
            span: 24,
            labelWidth: 100,
            type: 'select',
            dicUrl: url + '/category/list',
            props: {
              label: 'categoryKey',
              value: 'categoryValue'
            },
            rules: [
              {
                required: true,
                message: this.$t('home.selectGroup'),
                trigger: 'blur'
              }
            ]
          },
          {
            label: this.$t('home.screenName'),
            span: 24,
            labelWidth: 100,
            prop: 'title',
            rules: [
              {
                required: true,
                message: this.$t('home.inputScreenName'),
                trigger: 'blur'
              }
            ]
          },
          {
            label: this.$t('home.password'),
            span: 24,
            type: 'password',
            labelWidth: 100,
            prop: 'password'
          },
          {
            label: this.$t('home.publishStatus'),
            prop: 'status',
            span: 24,
            labelWidth: 100,
            type: 'select',
            dicData: [
              {
                label: this.$t('home.unpublishedStatus'),
                value: 0
              },
              {
                label: this.$t('home.publishedStatus'),
                value: 1
              }
            ]
          }
        ]
      },
      page: {
        page: 1,
        size: 50,
        total: 0
      },
      form: {},
      activeName: '',
      list: []
    }

  },
  created() {
    this.initCategoryId()
    this.getCategory()
  },
  mounted() {
    this.height = document.body.clientHeight - 230
  },
  watch: {
        //监听路由地址的改变
        $route: {
            immediate: true,
            handler() {
                this.setCategoryId()
                this.handleSelect(this.categoryId)
            }
        }
    },
  methods: {
    // 设置 分类id
    setCategoryId() {
        this.categoryId = this.$router.history.current.query.categoryId
    },
    // 检测分类id 没有参数跳转
    initCategoryId(){
      var query = this.$router.history.current.query
      var myGroups = this.myGroups
      // 获取第一个分类
      if (!query.categoryId && myGroups[0]) {
          setTimeout(()=>{
            this.$router.replace({ path: '/', query: { categoryId: myGroups[0].categoryValue } })
          },50)
      }
    },
    handleSelect(key) {
      this.activeName = key;
      this.page.page = 1;
      this.getList();
    },
    vaildData(id) {
      const list = [];
      for (var i = 0; i < 20; i++) {
        list.push(i)
      }
      return list.includes(id)
    },
    getCategory() {
      getCategory().then(res => {
        const data = res.data.data;
        this.typeList = data;
        this.activeName = (data[0] || {}).categoryValue;
        this.getList();
      })
    },
    handleExport(item) {
      getObj(item.id).then(res => {
        this.$message.success(this.$t('home.exportSuccess'))
        const data = res.data.data;
        let mode = {
          detail: JSON.parse(data.config.detail),
          component: JSON.parse(data.config.component)
        }
        var zip = new window.JSZip();
        zip.file("view.js", `const option =${JSON.stringify(mode, null, 4)}`);
        zip.file("说明.txt", `把view.js替换index.zip中文件即可,运行index.html即可看到效果`);
        zip.generateAsync({ type: "base64" })
          .then((content) => {
            this.downFile("data:application/zip;base64," + content, 'view.zip')
            setTimeout(() => {
              location.href = '/index.zip'
            }, 1000);
          });
      })
    },
    handleCopy(item) {
      if (this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return
      }
      this.$confirm(this.$t('home.confirmCopy'), this.$t('home.prompt'), {
        confirmButtonText: this.$t('home.confirm'),
        cancelButtonText: this.$t('home.cancel'),
        type: 'warning'
      }).then(() => {
        copyObj(item.id).then((res) => {
          this.$message.success(this.$t('home.copySuccess'));
          const id = res.data.data;
          this.handleEdit({ id })
        })
      }).catch(() => {

      });
    },
    handleDel(item, index) {
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.$confirm(this.$t('home.confirmDelete'), this.$t('home.prompt'), {
        confirmButtonText: this.$t('home.confirm'),
        cancelButtonText: this.$t('home.cancel'),
        type: 'warning'
      }).then(() => {
        delObj(item.id).then(() => {
          this.list.splice(index, 1)
          this.$message.success(this.$t('home.deleteSuccess'))
        })
      }).catch(() => {

      });
    },
    handleAdd() {
      this.$router.push({
        path: '/create', query: {
          category: this.activeName
        }
      })
    },
    handleUpdate(item, index) {
      if (this.vaildData(Number(index)) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.form = item
      this.form.category = this.form.category + '';
      this.box = true;
    },
    handleEdit(item) {
      let routeUrl = this.$router.resolve({
        path: '/build/' + item.id
      })
      window.open(routeUrl.href, '_blank');
    },
    handleViews(item) {
      let routeUrl = this.$router.resolve({
        path: '/view/' + item.id
      })
      window.open(routeUrl.href, '_blank');
    },
    handleSave(form, done) {
      updateObj({
        id: this.form.id,
        category: this.form.category,
        password: this.form.password,
        status: this.form.status,
        title: this.form.title
      }).then(() => {
        done();
        this.$message.success(this.$t('home.modifySuccess'));
        this.getList();

      })
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    getList(category) {
      this.list = []
      getList({
        // category: category || this.activeName,
        category:this.categoryId,
        current: this.page.page,
        size: this.page.size,
      }).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.list = data.records
        this.initData();
      })
    },
    initData() {
      this.list.forEach((ele, index) => {
        this.$set(this.list[index], '_menu', false)
      })
    }
  }
}
</script>
<style lang="scss">
@import "@/styles/list.scss";
</style>
