export default {
    category: {
      "ModName": "Module Name",
      "PlsEnterModName": "Please enter module name",
      "ModValue": "Module Value",
      "PlsEnterModValue": "Please enter module value",
      "DelConfirm": "This operation will permanently delete. Continue?",
      "Confirm": "Confirm",
      "Cancel": "Cancel",
      "DelSuccess": "Deletion successful",
      "ModSuccess": "Modification successful",
      "AddSuccess": "Addition successful",
      "prompt": "Prompt",
      "AddCategory": "Add Category",
      "AddScreenCategory": "Add a screen category for categorizing screens based on business needs",
    }
  }