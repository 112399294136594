import { website } from '@/config.js';
import i18n from '../locales/index';
//基本配置
export const config = {
  width: 1920,
  height: 1080,
  query: 'function(){\n    return window.$glob.params || {}\n}',
  header: 'function(){\n    return window.$glob.params || {}\n}',
  screen: 'x',
  mark: {
    show: false,
    text: '',
    fontSize: 20,
    color: 'rgba(100,100,100,0.2)',
    degree: -20,
  },
  scale: 1,
  backgroundImage: `/img/bg/bg.png`,
  url: '',
  gradeShow: false,
  gradeLen: 30,
};
// 颜色的配置
export const colorOption = {
  menuWidth: 150,
  refreshBtn: false,
  columnBtn: false,
  labelWidth: 100,
  column: [
    {
      // text:颜色1
      label: i18n.t('optionConfig.color1'),
      prop: 'color1',
      type: 'color',
    },
    {
      // text:渐变色
      label: i18n.t('optionConfig.gradientColor'),
      prop: 'color2',
      type: 'color',
    },
    {
      // text:位置
      label: i18n.t('optionConfig.position'),
      prop: 'postion',
      type: 'number',
    },
  ],
};
// 表格的列配置
export const tableOption = {
  menuWidth: 150,
  refreshBtn: false,
  columnBtn: false,
  labelWidth: 100,
  column: [
    {
      // text:名称
      label: i18n.t('optionConfig.name'),
      prop: 'label',
    },
    {
      // text:key值
      label: i18n.t('optionConfig.keyValue'),
      prop: 'prop',
    },
    {
      // text:宽度
      label: i18n.t('optionConfig.width'),
      prop: 'width',
      hide: true,
    },
    {
      // text:状态
      label: i18n.t('optionConfig.status'),
      prop: 'hide',
      type: 'switch',
      hide: true,
      value: false,
      dicData: [
        {
          // text:隐藏
          label: i18n.t('optionConfig.hidden'),
          value: true,
        },
        {
          // text:显示
          label: i18n.t('optionConfig.displayed'),
          value: false,
        },
      ],
    },
    {
      // text:格式化
      label: i18n.t('optionConfig.formatting'),
      prop: 'formatter',
      span: 24,
      hide: true,
    },
  ],
};
//一些字典的配置
export const dicOption = {
  line: [
    // text:线条
    { label: i18n.t('optionConfig.line'), value: 'line' },
    // text:圆环
    { label: i18n.t('optionConfig.circle'), value: 'circle' },
  ],
  fontWeight: [
    { label: 'normal', value: 'normal' },
    { label: 'bold', value: 'bold' },
    { label: 'bolder', value: 'bolder' },
    { label: 'ligter', value: 'ligter' },
  ],
  border: [
    // text:无边框
    { label: i18n.t('optionConfig.noBorder'), value: '' },
    // text:内置图片
    { label: i18n.t('optionConfig.builtInImage'), value: 'img' },
    // text:内置边框
    { label: i18n.t('optionConfig.builtInBorder'), value: 'border' },
  ],
  textAlign: [
    // text:居中
    { label: i18n.t('optionConfig.center'), value: 'center' },
    // text:左对齐
    { label: i18n.t('optionConfig.leftAlignment'), value: 'left' },
    // text:右对齐
    { label: i18n.t('optionConfig.rightAlignment'), value: 'right' },
  ],
  dataType: [
    // text:静态数据
    { label: i18n.t('optionConfig.staticData'), value: 0 },
    // text:API接口数据
    { label: i18n.t('optionConfig.apiData'), value: 1 },
    // text:SQL数据库数据
    { label: i18n.t('optionConfig.sqlDatabaseData'), value: 2 },
    { label: 'WebScoket', value: 3 },
    // text:Record数据集
    { label: i18n.t('optionConfig.recordDataset'), value: 4 },
  ],
  orientList: [
    // text:竖排
    { label: i18n.t('optionConfig.vertical'), value: 'vertical' },
    // text:横排
    { label: i18n.t('optionConfig.horizontal'), value: 'horizontal' },
  ],
  dataMethod: [
    { label: 'GET', value: 'get' },
    { label: 'DELETE', value: 'delete' },
    { label: 'POST', value: 'post' },
    { label: 'PUT', value: 'put' },
  ],
  eventList: ['vue', 'common', 'datav', 'bar', 'line', 'flop', 'funnel', 'gauge', 'map', 'pie', 'radar', 'text', 'tabs', 'rectangle'],
  dataList: ['vue', 'common', 'datav', 'text', 'wordcloud', 'img', 'tabs', 'map', 'video', 'clapper', 'pie', 'pictorialbar', 'iframe', 'swiper', 'flop', 'bar', 'line', 'progress', 'table', 'gauge', 'funnel', 'scatter', 'radar', 'img', 'imgborder', 'imgList', 'imgTabs', 'rectangle'].concat(
    website.componentsList.filter(ele => ele.data === true).map(ele => ele.name)
  ),
  barList: ['bar', 'line', 'scatter'],
  titleList: ['bar', 'pie', 'line'],
  labelList: ['bar', 'line', 'pie', 'rectangle', 'funnel', 'radar', 'scatter'],
  legendList: ['bar', 'pie', 'line', 'radar', 'funnel'],
  colorList: ['bar', 'pie', 'line', 'gauge', 'funnel', 'scatter', 'radar'],
  tipList: ['bar', 'pie', 'line', 'rectangle', 'funnel', 'scatter', 'radar'],
  positionList: ['bar', 'line', 'pictorialbar', 'scatter'],
  labelFormatterList: ['bar', 'map', 'line', 'pie', 'gauge', 'funnel', 'scatter', 'radar', 'rectangle'],
  tabsTypeList: [
    {
      // text:选项卡
      label: i18n.t('optionConfig.tab'),
      value: 'tabs',
    },
    {
      // text:选择框
      label: i18n.t('optionConfig.checkbox'),
      value: 'select',
    },
  ],
  mapType: [
    {
      // text:原生
      label: i18n.t('optionConfig.native'),
      value: 0,
    },
  ],
  target: [
    // text:本窗口
    { label: i18n.t('optionConfig.sameWindow'), value: '_self' },
    // text:新窗口
    { label: i18n.t('optionConfig.newWindow'), value: '_blank' },
  ],
  swiperType: [
    // text:普通
    { label: i18n.t('optionConfig.normal'),value: '' },
    // text:立体
    { label: i18n.t('optionConfig.stereoscopic'), value: 'card' },
  ],
  swiperIndicator: [
    // text:外部
    { label: i18n.t('optionConfig.external'), value: 'indicator' },
    // text:不显示
    { label: i18n.t('optionConfig.hide'), value: 'none' },
  ],
  format: [
    // text:日期
    { label: i18n.t('optionConfig.date'), value: 'yyyy-MM-dd' },
    // text:日期+时分
    { label: i18n.t('optionConfig.dateAndTime'), value: 'yyyy-MM-dd hh:mm' },
    // text:日期+时分秒
    { label: i18n.t('optionConfig.dateAndTimeWithSeconds'), value: 'yyyy-MM-dd hh:mm:ss' },
    // text:日期(无年)
    { label: i18n.t('optionConfig.dateNoYear'), value: 'MM-dd' },
    // text:时分
    { label: i18n.t('optionConfig.time'), value: 'hh:mm' },
    // text:时分秒
    { label: i18n.t('optionConfig.timeWithSeconds'), value: 'hh:mm:ss' },
    // text:星期
    { label: i18n.t('optionConfig.weekday'), value: 'day' },
  ],
  fontFamily: [
    // text:宋体
    { label: i18n.t('optionConfig.simSun'), value: 'SimSun' },
    // text:新宋体
    { label: i18n.t('optionConfig.nSimSun'), value: 'NSimSun' },
    // text:黑体
    { label: i18n.t('optionConfig.simHei'), value: 'SimHei' },
    // text:楷体
    { label: i18n.t('optionConfig.kaiTi'), value: 'KaiTi_GB2312' },
    // text:微软雅黑
    { label: i18n.t('optionConfig.microsoftYaHei'), value: 'Microsoft YaHei' },
    // text:幼园
    { label: i18n.t('optionConfig.youYuan'), value: 'YouYuan' },
    // text:华文细黑
    { label: i18n.t('optionConfig.stXihei'), value: 'STXihei' },
    // text:细明体
    { label: i18n.t('optionConfig.ming'),value: 'MingLiU' },
    // text:新细明体
    { label: i18n.t('optionConfig.newMing'), value: 'PMingLiU' },
  ],
  animated: ['bounce', 'bounceIn', 'fadeInDownBig', 'fadeInLeftBig', 'fadeInRightBig', 'fadeInUpBig', 'flip', 'flipInX', 'flipInY'].map(ele => {
    return { label: ele, value: ele };
  }),
};

function concat(prop, count, type, extend = [], defaults) {
  let list = [];
  for (let i = 1; i <= count; i++) {
    list.push({
      label: prop + i,
      value: `/img/${prop}/${prop}${i}.${extend.includes(i) ? defaults : type}`,
    });
  }
  return list;
}
//加载图片素材库
export const imgOption = [concat('bg', 18, 'jpg', [1, 2, 3], 'png'), concat('border', 16, 'png'), concat('source', 260, 'svg', [1, 15, 16, 20, 239.24, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260], 'png'), concat('banner', 10, 'png')];
