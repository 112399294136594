export default {
    home: {
        myProject:'我的项目',
        title:'选择下面的方式进行创建',
        createScreen: '新建大屏',
        createScreen1: '使用模版创建',
        description1:'自定义创建大屏设计、数据嵌入和图表图例',
        createScreen2: '使用空白模版创建',
        description2:'大屏幕设计、数据嵌入和图表图例的习惯创建',
        export: '导出',
        preview: '预览',
        edit: '编辑',
        modify: '修改',
        delete: '删除',
        copy: '复制',
        published: '已发布',
        unpublished: '未发布',
        editScreen: '编辑大屏',
        modifyScreen: '修改大屏',
        group: '分组',
        selectGroup: '请选择分组',
        screenName: '大屏名称',
        inputScreenName: '请输入大屏名称',
        password: '密码',
        publishStatus: '发布状态',
        unpublishedStatus: '未发布',
        publishedStatus: '已发布',
        exportSuccess: '大屏导出成功',
        confirmCopy: '确认复制当前大屏',
        confirm: '确认',
        cancel: '取消',
        copySuccess: '复制成功',
        confirmDelete: '是否确认永久删除?',
        deleteSuccess: '删除成功',
        modifySuccess: '修改成功',
        prompt: '提示'
    }
}
