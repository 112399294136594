<template>
  <div class="record">
    <tableSty>
      <avue-crud ref="crud" :option="option" v-model="form" @row-save="rowSave" @row-update="rowUpdate" @row-del="rowDel"
        :before-close="beforeClose" :before-open="beforeOpen" :data="data">
        <template slot="contentForm" slot-scope="{}">
          <template v-if="isStatic">
            <!-- text:数据值 -->
            <el-form-item :label="$t('record.data_value')" label-position="top">
              <monaco-editor v-model="form.data" language="javascript" height="100"></monaco-editor>
              <br />
              <el-upload :show-file-list="false" :auto-upload="false" style="display: inline-block" accept=".xls,.xlsx"
                :on-change="handleImport">
                <!-- text:导入数据(Excel) -->
                <el-button size="small" icon="el-icon-upload" type="success">{{ $t('record.import_data_excel')
                }}</el-button>
              </el-upload>
            </el-form-item>
          </template>
          <template v-else-if="isSql">
            <!-- text:数据源选择 -->
            <el-form-item :label="$t('record.data_source_selection')">
              <avue-select :dic="DIC.sql" v-model="form.dbId"></avue-select>
            </el-form-item>

            <!-- text:SQL语句 -->
            <el-form-item :label="$t('record.sql_statement')" label-position="top">
              <monaco-editor v-model="form.dbSql" language="sql" height="100"></monaco-editor>
            </el-form-item>
          </template>
          <template v-else-if="isApi">
            <!-- text:接口地址 -->
            <el-form-item :label="$t('record.api_address')">
              <avue-input v-model="form.url"></avue-input>
            </el-form-item>
            <!-- text:请求方式 -->
            <el-form-item :label="$t('record.request_method')">
              <avue-select v-model="form.dataMethod" :dic="DIC.method"></avue-select>
            </el-form-item>
          </template>
          <template v-else-if="isWs">
            <!-- text:WS地址 -->
            <el-form-item :label="$t('record.ws_address')">
              <el-input v-model="form.wsUrl"></el-input>
            </el-form-item>
          </template>
          <!-- text:请求配置 -->
          <el-form-item :label="$t('record.request_config')" v-if="isWs || isApi">
            <el-tabs class="menu__tabs" v-model="tabs">
              <!-- text:请求参数（Body） -->
              <el-tab-pane :label="$t('record.request_body')" name="0">
                <template v-if="tabs == 0">
                  <el-radio-group size="mini" v-if="['post', 'put'].includes(form.dataMethod)"
                    v-model="form.dataQueryType">
                    <!-- text:数据值 -->
                    <el-radio label="json">{{ $t('record.json_data') }}</el-radio>
                    <!-- text:数据值 -->
                    <el-radio label="form">{{ $t('record.form_data') }}</el-radio>
                  </el-radio-group>
                  <monaco-editor v-model="form.dataQuery" language="javascript" height="100"></monaco-editor>
                </template>
              </el-tab-pane>
              <!-- text:请求头（Headers） -->
              <el-tab-pane :label="$t('record.request_headers')" v-if="isApi" name="1">
                <template v-if="tabs == 1">
                  <monaco-editor v-model="form.dataHeader" language="javascript" height="100"></monaco-editor>
                </template>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <!-- text:过滤器 -->
          <el-form-item :label="$t('record.filter')">
            <monaco-editor v-model="form.dataFormatter" height="100"></monaco-editor>
          </el-form-item>
          <!-- text:响应数据 -->
          <el-form-item :label="$t('record.response_data')">
            <monaco-editor v-model="result" disabled height="100"></monaco-editor>
          </el-form-item>
        </template>
        <template slot="menuForm">
          <!-- text:刷新数据 -->
          <el-button @click="handleRes" size="small" icon="el-icon-upload" type="primary">{{ $t('record.refresh_data')
          }}</el-button>
        </template>

        <template slot-scope="scope" slot="menuLeft">
          <optionsButton :list="optionsList" />
        </template>
      </avue-crud>
    </tableSty>
  </div>
</template>

<script>
import crypto from '@/utils/crypto';
import { addParam } from '@/echart/util';
import { getFunction, funEval } from '@/utils/utils';
import MonacoEditor from '@/page/components/editor';
import { getList, getObj, addObj, delObj, updateObj } from '@/api/record';
import { getList as getDbList, dynamicSql } from '@/api/db';
import { dicOption } from '@/option/config';
import tableSty from '@/page/components/tableSty';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales';
import optionsButton from '@/page/components/optionsButton';
dicOption.dataType.splice(4, 1);
const dataType = dicOption.dataType;
export default {
  components: {
    MonacoEditor,
    tableSty,
    optionsButton,
  },
  data() {
    bindLocalesToVue(this, locales);
    return {
      tabs: 0,
      box: false,
      client: {},
      DIC: {
        method: dicOption.dataMethod,
        sql: [],
      },
      result: {},
      form: {},
      data: [],
      option: {
        // 取消菜单 Fixed
        menuFixed: false,
        indexFixed: false,

        addBtn: false,

        // 新建按钮
        addBtnText: this.$t('crud.add'),

        // 编辑按钮
        editBtnText: this.$t('crud.editBtnText'),
        editBtnIcon: 'el-icon-edit-outline',

        // 删除按钮
        delBtnText: this.$t('crud.delBtnText'),
        delBtnIcon: 'icon iconfont icon_icon_gm1_d-01',

        // 修改按钮
        updateBtnText: this.$t('crud.update'),

        // 取消按钮
        cancelBtnText: this.$t('crud.cancel'),

        // 保存按钮
        saveBtnText: this.$t('crud.saveBtnText'),

        // 操作文字
        menuTitle: this.$t('crud.action'),

        labelWidth: 130,
        index: true,
        align: 'center',
        headerAlign: 'center',
        column: [
          {
            // text:名称
            label: this.$t('record.name'),
            prop: 'name',
            rules: [
              {
                required: true,
                // text:请输入名称
                message: this.$t('record.please_enter_name'),
                trigger: 'blur',
              },
            ],
          },
          {
            // text:类型
            label: this.$t('record.type'),
            prop: 'dataType',
            type: 'select',
            dicData: dataType,
            rules: [
              {
                required: true,
                // text:请选择类型
                message: this.$t('record.please_select_type'),
                trigger: 'blur',
              },
            ],
          },
          {
            label: '',
            prop: 'content',
            hide: true,
            labelWidth: 0,
            span: 24,
          },
        ],
      },
      optionsList: [
        {
          icon: require('@/page/category/img/add.png'),
          title: this.$t('record.add'),
          description: this.$t('record.description'),
          click: () => {
            if (!this.authorityVerify('RESOURCE_DATASETMANAGEMENT_ADD_BTN')) return
            this.$refs.crud.rowAdd()
          }
        },
      ],
    };
  },
  computed: {
    isStatic() {
      return this.form.dataType === 0;
    },
    isApi() {
      return this.form.dataType == 1;
    },
    isSql() {
      return this.form.dataType == 2;
    },
    isWs() {
      return this.form.dataType === 3;
    },
  },
  watch: {
    'form.dataType'() {
      this.result = '';
      this.tabs = '0';
    },
  },
  created() {
    this.init();
    this.getList();
  },
  methods: {
    init() {
      getDbList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.DIC.sql = data.records.map(ele => {
          return {
            label: ele.name,
            value: ele.id,
          };
        });
      });
    },
    getSql(row) {
      return crypto.encrypt(
        JSON.stringify({
          id: row.dbId,
          sql: row.dbSql,
        })
      );
    },
    handleClose() {
      this.client.close && this.client.close();
    },
    handleRes(tip = true) {
      const formatter = data => {
        const dataFormatter = getFunction(this.form.dataFormatter);
        // text:数据刷新成功
        if (tip) this.$message.success(this.$t('record.data_refresh_success'));
        if (typeof dataFormatter == 'function') {
          return dataFormatter(data);
        }

        return data;
      };
      if (this.isStatic) {
        let result = funEval(this.form.data);
        this.result = formatter(result);
      } else if (this.isApi) {
        let dataQuery = this.form.dataQuery ? getFunction(this.form.dataQuery)() : {};
        let dataHeader = this.form.dataHeader ? getFunction(this.form.dataHeader)() : {};
        let params = {},
          method = this.form.dataMethod;
        if (['post', 'put'].includes(method)) {
          params.data = dataQuery;
          if (this.form.dataQueryType == 'form') {
            let formData = new FormData();
            Object.keys(dataQuery).forEach(ele => {
              formData.append(ele, dataQuery[ele]);
            });
            params.data = formData;
          }
        } else if (['get', 'delete'].includes(method)) {
          params.params = dataQuery;
        }
        this.$axios({
          ...{
            method: this.form.dataMethod,
            url: this.form.url,
            headers: dataHeader,
          },
          ...params,
        }).then(res => {
          this.result = formatter(res.data);
        });
      } else if (this.isSql) {
        dynamicSql(this.getSql(this.form)).then(res => {
          this.result = formatter(res.data.data);
        });
      } else if (this.isWs) {
        this.handleClose();
        let dataQuery = this.form.dataQuery ? getFunction(this.form.dataQuery)() : {};
        let url = this.form.wsUrl + addParam(dataQuery);
        this.client = new WebSocket(url);
        this.client.onmessage = (msgEvent = {}) => {
          const data = JSON.parse(msgEvent.data);
          this.result = formatter(data);
        };
      }
    },
    handleImport(file, fileLis) {
      this.$Export.xlsx(file.raw).then(data => {
        this.form.data = data.results;
        // text:导入成功
        this.$message.success(this.$t('record.import_success'));
      });
    },
    vaildData(id) {
      return [0, 1, 2].includes(id);
    },
    beforeClose(done) {
      this.handleClose();
      done();
    },
    beforeOpen(done, type) {
      this.result = '';
      if (type == 'edit') {
        if (!this.authorityVerify('RESOURCE_DATASETMANAGEMENT_UPDATE_BTN')) return
        getObj(this.form.id).then(res => {
          const data = res.data.data;
          this.form = data;
          if (this.isSql) {
            let mode = JSON.parse(crypto.decrypt(this.form.data));
            this.form.dbId = mode.id;
            this.form.dbSql = mode.sql;
          }
          this.handleRes(false);
          done();
        });
      } else {
        done();
      }
      this.form.dataFormatter = this.form.dataFormatter || '(data)=>{\n    return {\n        data\n    }\n}';
    },
    rowDel(row, index) {
      if (!this.authorityVerify('RESOURCE_DATASETMANAGEMENT_DEL_BTN')) return
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip);
        return false;
      }
      // text:此操作将永久删除, 是否继续?  提示
      this.$confirm(this.$t('record.confirm_delete'), this.$t('record.prompt'), {
        // text:确定
        confirmButtonText: this.$t('record.confirm'),
        // text:取消
        cancelButtonText: this.$t('record.cancel'),
        type: 'warning',
      })
        .then(() => {
          delObj(row.id).then(() => {
            // text:删除成功
            this.$message.success(this.$t('record.delete_success'));
            this.getList();
          });
        })
        .catch(() => { });
    },
    rowUpdate(row, index, done) {
      if (this.vaildData(index) && this.$website.isDemo) {
        done();
        this.$message.error(this.$website.isDemoTip);
        return false;
      }
      if (this.isSql) row.data = this.getSql(row);
      updateObj(row).then(() => {
        done();
        // text:修改成功
        this.$message.success(this.$t('record.update_success'));
        this.getList();
      });
    },
    rowSave(row, done) {
      if (this.isSql) row.data = this.getSql(row);
      addObj(row).then(() => {
        // text:新增成功
        this.$message.success(this.$t('record.add_success'));
        this.getList();
        done();
      });
    },
    getList() {
      getList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.data = data.records;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  padding-top: 10px;

  ::v-deep .iconfont {
    font-size: 14px;
  }

  ::v-deep i {
    font-size: 14px;
  }

  ::v-deep .el-button--text {
    color: rgb(128, 126, 255);
  }

  ::v-deep .el-button--text:nth-child(2) {
    color: rgb(255, 126, 126);
  }

  ::v-deep .avue-crud {
    padding: 0;
  }
}
</style>
