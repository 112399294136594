import { url } from '@/config';
import request from '../axios';
// 列表
export const getList = (data) => {
  return request({
    url: url + '/visual/embedAnalysis/list',
    method: 'get',
    params: data,
  })
}

//新增嵌入报表 table 列表
export const getEmbList = (data) => {
  return request({
    url: url + '/visual/unembedVisual/list',
    method: 'get',
    params: data,
  })
}
//新增嵌入报表 数据对象类型
export const getOption = () => {
  return request({
    url: url + '/category/list',
    method: 'get',
  })
}
// 删除嵌入报表
export const removeUser = (data) => {
  return request({
    url: url + '/visual/embedAnalysis/remove',
    method: 'delete',
    data: data
  })
}


//新增嵌入报表 
export const add = (data) => {
  console.log('data: ', data);
  return request({
    url: url + '/visual/embedAnalysis/embed',
    method: 'post',
    data: data
  })
}
// 生成 ticket
export const getTicket = (data) => {
  return request({
    url: url + '/visual/embedAnalysis/ticket',
    method: 'post',
    data: data
  })
}



