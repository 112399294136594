<template>
  <div>
    页面无权限
  </div>
</template>
<script>

export default {

  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped></style>
