<!-- 通用配置-->
<template>
  <div>
    <!-- 折叠公共配置 -->
    <el-collapse accordion>
      <!-- 标题设置 -->
      <template v-if="main.validProp('titleList')">
       <!-- text:标题设置 -->
        <el-collapse-item :title="$t('optionMain.titleSetting')">
          <!-- text:标题 -->
          <el-form-item :label="$t('optionMain.title')">
            <avue-switch v-model="main.activeOption.titleShow"></avue-switch>
          </el-form-item>
          <!-- text:标题 -->
          <el-form-item :label="$t('optionMain.title')">
            <avue-input v-model="main.activeOption.title"></avue-input>
          </el-form-item>
          <!-- text:字体颜色 -->
          <el-form-item :label="$t('optionMain.titleFontColor')">
            <avue-input-color v-model="main.activeOption.titleColor"></avue-input-color>
          </el-form-item>
          <!-- text:字体大小 -->
          <el-form-item :label="$t('optionMain.titleFontSize')">
            <avue-input-number v-model="main.activeOption.titleFontSize"></avue-input-number>
          </el-form-item>
          <!-- text:字体位置 -->
          <el-form-item :label="$t('optionMain.titleFontPosition')">
            <avue-select v-model="main.activeOption.titlePosition"
                         :dic="dicOption.textAlign">
            </avue-select>
          </el-form-item>
          <!-- text:副标题 -->
          <el-form-item :label="$t('optionMain.subtitle')">
            <avue-input v-model="main.activeOption.subtext"></avue-input>
          </el-form-item>
          <!-- text:字体颜色 -->
          <el-form-item :label="$t('optionMain.subtitleFontColor')">
            <avue-input-color v-model="main.activeOption.subTitleColor"></avue-input-color>
          </el-form-item>
          <!-- text:字体大小 -->
          <el-form-item :label="$t('optionMain.subtitleFontSize')">
            <avue-input-number v-model="main.activeOption.subTitleFontSize">
            </avue-input-number>
          </el-form-item>
        </el-collapse-item>
      </template>
      <!-- 轴设置 -->
      <template v-if="main.validProp('barList')">
        <!-- text:X轴设置 -->
        <el-collapse-item :title="$t('optionMain.xAxisSetting')">
          <!-- text:名称 -->
          <el-form-item :label="$t('optionMain.name')">
            <avue-input v-model="main.activeOption.xAxisName">
            </avue-input>
          </el-form-item>
          <!-- text:显示 -->
          <el-form-item :label="$t('optionMain.display')">
            <avue-switch v-model="main.activeOption.xAxisShow">
            </avue-switch>
          </el-form-item>
          <!-- text:显示网格线 -->
          <el-form-item :label="$t('optionMain.displayGridLines')">
            <avue-switch v-model="main.activeOption.xAxisSplitLineShow">
            </avue-switch>
          </el-form-item>
          <!-- text:标签间距 -->
          <el-form-item :label="$t('optionMain.labelSpacing')">
            <avue-switch v-model="main.activeOption.xAxisInterval">
            </avue-switch>
          </el-form-item>
          <!-- text:文字角度 -->
          <el-form-item :label="$t('optionMain.textAngle')">
            <avue-input-number v-model="main.activeOption.xAxisRotate">
            </avue-input-number>
          </el-form-item>
          <!-- text:轴反转 -->
          <el-form-item :label="$t('optionMain.axisReversal')">
            <avue-switch v-model="main.activeOption.xAxisInverse">
            </avue-switch>
          </el-form-item>
          <!-- text:文字大小 -->
          <el-form-item :label="$t('optionMain.textSize')">
            <avue-input-number v-model="main.activeOption.xAxisFontSize">
            </avue-input-number>
          </el-form-item>
          <!-- text:文字颜色 -->
          <el-form-item :label="$t('optionMain.textColor')">
            <avue-input-color v-model="main.activeOption.xAxisColor">
            </avue-input-color>
          </el-form-item>
          <!-- text:轴线颜色 -->
          <el-form-item :label="$t('optionMain.axisLineColor')">
            <avue-input-color v-model="main.activeOption.xAxisLineColor"></avue-input-color>
          </el-form-item>
        </el-collapse-item>
        <!-- text:Y轴设置 -->
        <el-collapse-item :title="$t('optionMain.yAxisSetting')">
          <!-- text:名称 -->
          <el-form-item :label="$t('optionMain.name')">
            <avue-input v-model="main.activeOption.yAxisName">
            </avue-input>
          </el-form-item>
          <!-- text:显示 -->
          <el-form-item :label="$t('optionMain.display')">
            <avue-switch v-model="main.activeOption.yAxisShow">
            </avue-switch>
          </el-form-item>
          <!-- text:轴网格线 -->
          <el-form-item :label="$t('optionMain.axisGridLine')">
            <avue-switch v-model="main.activeOption.yAxisSplitLineShow">
            </avue-switch>
          </el-form-item>
          <!-- text:反转 -->
          <el-form-item :label="$t('optionMain.reversal')">
            <avue-switch v-model="main.activeOption.yAxisInverse">
            </avue-switch>
          </el-form-item>
          <!-- text:文字大小 -->
          <el-form-item :label="$t('optionMain.textSize')">
            <avue-input-number v-model="main.activeOption.yAxisFontSize">
            </avue-input-number>
          </el-form-item>
          <!-- text:文字颜色 -->
          <el-form-item :label="$t('optionMain.textColor')">
            <avue-input-color v-model="main.activeOption.yAxisColor">
            </avue-input-color>
          </el-form-item>
          <!-- text:轴线颜色 -->
          <el-form-item :label="$t('optionMain.axisLineColor')">
            <avue-input-color v-model="main.activeOption.yAxisLineColor"></avue-input-color>
          </el-form-item>
        </el-collapse-item>
      </template>
      <!-- 数值设置 -->
      <template v-if="main.validProp('labelList')">
       <!-- text:数值设置 -->
        <el-collapse-item :title="$t('optionMain.valueSetting')">
          <!-- text:显示 -->
          <el-form-item :label="$t('optionMain.display')">
            <avue-switch v-model="main.activeOption.labelShow">
            </avue-switch>
          </el-form-item>
          <!-- text:字体大小 -->
          <el-form-item :label="$t('optionMain.fontSize')">
            <avue-input-number v-model="main.activeOption.labelShowFontSize">
            </avue-input-number>
          </el-form-item>
          <!-- text:字体颜色 -->
          <el-form-item :label="$t('optionMain.fontColor')"
                        v-if="labelShowColorAuto!=true">
            <avue-input-color v-model="main.activeOption.labelShowColor">
            </avue-input-color>
          </el-form-item>
          <!-- text:字体粗细 -->
          <el-form-item :label="$t('optionMain.fontWeight')">
            <avue-select v-model="main.activeOption.labelShowFontWeight"
                         :dic="dicOption.fontWeight">
            </avue-select>
          </el-form-item>
        </el-collapse-item>
      </template>
      <!-- 提示语设置  -->
      <template v-if="main.validProp('tipList')">
       <!-- text:提示语设置 -->
        <el-collapse-item :title="$t('optionMain.tooltipSetting')">
          <!-- text:显示 -->
          <el-form-item :label="$t('optionMain.display')">
            <avue-switch v-model="main.activeOption.tipShow"></avue-switch>
          </el-form-item>
          <!-- text:字体大小 -->
          <el-form-item :label="$t('optionMain.fontSize')">
            <avue-input-number v-model="main.activeOption.tipFontSize"></avue-input-number>
          </el-form-item>
          <!-- text:字体颜色 -->
          <el-form-item :label="$t('optionMain.fontColor')">
            <avue-input-color v-model="main.activeOption.tipColor"></avue-input-color>
          </el-form-item>
          <!-- text:背景颜色 -->
          <el-form-item :label="$t('optionMain.backgroundColor')">
            <avue-input-color v-model="main.activeOption.tipBackgroundColor"></avue-input-color>
          </el-form-item>
        </el-collapse-item>
      </template>
      <!-- 轴距离设置 -->
      <template v-if="main.validProp('positionList')">
        <!-- text:坐标轴边距设置 -->
        <el-collapse-item :title="$t('optionMain.axisMarginSetting')">
          <!-- text:左边距(像素) -->
          <el-form-item :label="$t('optionMain.leftMargin')">
            <avue-slider v-model="main.activeOption.gridX"
                         :max="400"></avue-slider>
          </el-form-item>
          <!-- text:顶边距(像素) -->
          <el-form-item :label="$t('optionMain.topMargin')">
            <avue-slider v-model="main.activeOption.gridY"
                         :max="400"></avue-slider>
          </el-form-item>
          <!-- text:右边距(像素) -->
          <el-form-item :label="$t('optionMain.rightMargin')">
            <avue-slider v-model="main.activeOption.gridX2"
                         :max="400"></avue-slider>
          </el-form-item>
          <!-- text:底边距(像素) -->
          <el-form-item :label="$t('optionMain.bottomMargin')">
            <avue-slider v-model="main.activeOption.gridY2"
                         :max="400"></avue-slider>
          </el-form-item>
        </el-collapse-item>
      </template>
      <!-- 图例设置 -->
      <template v-if="main.validProp('legendList')">
        <!-- text:图例操作 -->
        <el-collapse-item :title="$t('optionMain.legendOperation')">
          <!-- text:图例 -->
          <el-form-item :label="$t('optionMain.legend')">
            <avue-switch v-model="main.activeOption.legend"></avue-switch>
          </el-form-item>
          <!-- text:位置 -->
          <el-form-item :label="$t('optionMain.position')">
            <avue-select v-model="main.activeOption.legendPosition"
                         :dic="dicOption.textAlign">
            </avue-select>
          </el-form-item>
          <!-- text:布局朝向 -->
          <el-form-item :label="$t('optionMain.layoutOrientation')">
            <avue-select v-model="main.activeOption.legendOrient"
                         :dic="dicOption.orientList">
            </avue-select>
          </el-form-item>
          <!-- text:字体大小 -->
          <el-form-item :label="$t('optionMain.fontSize')">
            <avue-input-number v-model="main.activeOption.legendFontSize">
            </avue-input-number>
          </el-form-item>
        </el-collapse-item>
      </template>
      <!-- 颜色设置 -->
      <template v-if="main.validProp('colorList')">
        <!-- text:自定义配色 -->
        <el-collapse-item :title="$t('optionMain.customColor')">
          <avue-crud :option="colorOption"
                     :data="main.activeOption.barColor"
                     @row-save="rowSave"
                     @row-del="rowDel"
                     @row-update="rowUpdate"></avue-crud>
        </el-collapse-item>
      </template>
    </el-collapse>
  </div>
</template>

<script>
import { dicOption, colorOption } from '@/option/config'
export default {
  name: 'main',
  inject: ["main"],
  data () {
    return {
      dicOption: dicOption,
      colorOption: colorOption
    }
  },
  methods: {
    rowSave (row, done) {
      this.main.activeOption.barColor.push(this.deepClone(row));
      done()
    },
    rowDel (row, index) {
      this.main.activeOption.barColor.splice(index, 1);
    },
    rowUpdate (row, index, done) {
      this.main.activeOption.barColor.splice(index, 1, this.deepClone(row));
      done()
    },
  }
}
</script>

<style>
</style>