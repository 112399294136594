export default {
  openPlatform: {
    add: 'Create a new large-screen embed',
    name: 'Name',
    openPerson: 'Open Person',
    openTime: 'Open Time',
    operation: 'Action',
    edit:'Edit',
    del:'Delete'
  },
  newReport: {
    embeddedReport: 'Added embedded large screen',
    dataObjectType: 'Data object type',
    dataObjectName:'Data object name',
    workspace: 'Workspace',
    reportName: 'Please enter report name',
    embeddingEnabled: 'Enable embedding',
    name: 'Data object name',
    type: 'Type',
    cancel: 'Cancel',
    confirm: 'Open embedding',
    option: 'Please select',
    select: 'Enter your choice',
  },
  embeddedReport: {
    bind_User: 'Bind User',
    validity_Period: 'Validity Period',
    watermark_Parameter: 'Watermark Parameter',
    access_Count: 'Access Count',
    embedded_Object: 'Embedded Object',
    security_Authentication_Type: 'Security Authentication Type',
    ticket_Link: 'Ticket Link',
    get_Embed_Code_URL: 'Get Embed Code URL',
    iframe_Code: 'Iframe Code',
    copy: 'Copy',

    close_Embed: 'Close Embed',
    uRL_Link: 'URL Link',
    embed_Configuration: 'Embed Configuration',
    ticket_Authentication: 'Ticket Authentication',
    quick_Generate: 'Quick Generate',
    collapse: 'Collapse',
    generate_Ticket: 'Generate Ticket',

    minutes: 'Minutes',
    you_are_debugging: 'You are debugging',
    embed_code: 'Embed code',
    please_enter: 'Please enter the ticket link',
    the_default: 'The default expiration time is 240 minutes, and custom expiration time is supported.',
    watermark_parameter: 'Watermark parameter of the report, which cannot exceed 50 characters.',
    if_you: 'If 1 is entered, the generated link can be accessed only once. The default value is 1 and a maximum of 9999 entries are supported.',
    embeddedObject: 'Full page',
    watermarking:'The watermarking parameter of the report contains a maximum of 50 characters',
    GenerateTicket:'Generate Ticket',
    cancel: 'Cancel',
    confirm:'Confirm',
    tip:'Links that have already been generated after closing the embed are invalidated. Are you sure you want to close it?',
    successful_replication:'Successful replication:',
    replication_failure:'Replication failure:',
    succeeded_Ticket:'Succeeded in generating a Ticket'
  },
};
