<template>
  <div class="menuLayout">
    <el-container class="layoutCont">
      <el-aside style="width:auto">
        <Menu />
      </el-aside>
      <el-container>
        <el-header>
          <Header />
        </el-header>
        <el-main class="pageRoot">
            <div class="pageRootCont">
              <router-view /> 
            </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>

import Header from './header'
import Menu from './menu'
export default {
  components: {
    Header,
    Menu,
  }
}
</script>
<style lang="scss" scoped>
.menuLayout {}

.layoutCont {
  background-color: #1A0F1F;
  color: #fff;
}

.el-header {
  padding: 0;
}

.el-aside {
  
}

.el-main {
  overflow: auto;
}

.pageRoot{
  position: relative;
}

.pageRootCont{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
}
</style>