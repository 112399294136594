<template>
  <div class="category">
    <tableSty>
      <avue-crud :option="option" v-model="form" ref="crud" @row-save="rowSave" @row-update="rowUpdate" @row-del="rowDel"
        :before-open="beforeOpen" :data="data">

        <template slot-scope="scope" slot="menuLeft">
          <optionsButton :list="optionsList" />
        </template>
      </avue-crud>
    </tableSty>
  </div>
</template>

<script>
import { getList, getObj, addObj, delObj, updateObj } from '@/api/category'
import tableSty from '@/page/components/tableSty';
import optionsButton from '@/page/components/optionsButton';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales'
export default {
  components: {
    tableSty,
    optionsButton,
  },
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales)
    return {
      form: {},
      data: [],
      option: {
        // 取消菜单 Fixed
        menuFixed: false,
        indexFixed: false,

        addBtn: false,
        // 新建按钮
        addBtnText: this.$t('crud.add'),

        // 编辑按钮
        editBtnText: this.$t('crud.editBtnText'),
        editBtnIcon: 'el-icon-edit-outline',

        // 删除按钮
        delBtnText: this.$t('crud.delBtnText'),
        delBtnIcon: 'icon iconfont icon_icon_gm1_d-01',

        // 修改按钮
        updateBtnText: this.$t('crud.update'),

        // 保存按钮
        saveBtnText: this.$t('crud.saveBtnText'),

        // 取消按钮
        cancelBtnText: this.$t('crud.cancel'),

        index: true,
        align: 'center',
        headerAlign: 'center',
        column: [
          {
            label: this.$t('category.ModName'),
            prop: 'categoryKey',
            rules: [{
              required: true,
              message: this.$t('category.PlsEnterModName'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('category.ModValue'),
            prop: 'categoryValue',
            type: 'number',
            rules: [{
              required: true,
              message: this.$t('category.PlsEnterModValue'),
              trigger: "blur"
            }]
          }
        ]
      },
      optionsList: [
        {
          icon: require('./img/add.png'),
          title: this.$t('category.AddCategory'),
          description: this.$t('category.AddScreenCategory'),
          click: () => {
            if (!this.authorityVerify('RESOURCE_CATEGORYMANAGEMENT_ADD_BTN')) return
            this.$refs.crud.rowAdd()
          }
        },
      ],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    vaildData(id) {
      return [0, 1, 2].includes(id)
    },
    beforeOpen(done, type) {
      if (type == 'edit') {
        if (!this.authorityVerify('RESOURCE_CATEGORYMANAGEMENT_UPDATE_BTN')) return
        getObj(this.form.id).then(res => {
          const data = res.data.data;
          this.form = data
          done()
        })
      } else {
        done()
      }
    },
    rowDel(row, index) {
      if (!this.authorityVerify('RESOURCE_CATEGORYMANAGEMENT_DEL_BTN')) return
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.$confirm(this.$t('category.DelConfirm'), this.$t('category.prompt'), {
        confirmButtonText: this.$t('category.Confirm'),
        cancelButtonText: this.$t('category.Cancel'),
        type: 'warning'
      }).then(() => {
        delObj(row.id).then(() => {
          this.$message.success(this.$t('category.DelSuccess'));
          this.getList()
        })
      }).catch(() => {

      });

    },
    rowUpdate(row, index, done) {
      if (this.vaildData(index) && this.$website.isDemo) {
        done();
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      updateObj(row).then(() => {
        done();
        this.$message.success(this.$t('category.ModSuccess'));
        this.getList()

      })
    },
    rowSave(row, done) {
      addObj(row).then(() => {
        this.$message.success(this.$t('category.AddSuccess'));
        this.getList()
        done();
      })
    },
    getList() {
      getList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.data = data;
        this.$store.commit('home/setState', {
          myGroups: data
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  padding-top: 10px;

  ::v-deep .iconfont {
    font-size: 14px;
  }

  ::v-deep i {
    font-size: 14px;
  }

  ::v-deep .el-button--text {
    color: rgb(128, 126, 255);
  }

  ::v-deep .el-button--text:nth-child(2) {
    color: rgb(255, 126, 126);
  }

  ::v-deep .avue-crud {
    padding: 0;
  }
}
</style>