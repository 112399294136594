<!-- 散点图配置 -->
<template>
  <div>
    <!-- text:点的大小  -->
    <el-form-item :label="$t('optionScatter.dotSize')">
      <avue-slider v-model="main.activeOption.symbolSize">
      </avue-slider>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'scatter',
  inject: ["main"]
}
</script>

<style>
</style>