<template>
  <div class="compCont">
    <div class="left">
      {{ promptText }}
    </div>
    <div class="right">
      <div class="language">
        <el-dropdown @command="handleSetLanguage">
          <div class="el-dropdown-link">
            <span style="margin-right: 8px">{{ languageMap[language] }}</span>
            <img :src="require('../img/up.png')" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh-CN">中文</el-dropdown-item>
            <el-dropdown-item command="en-US">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="avatar">
        <el-dropdown hide-on-click @command="logOut">
          <div class="el-dropdown-link">
            <el-avatar :size="32" :src="circleUrl" style="margin-right: 8px"></el-avatar>
            <span style="margin-right: 8px">{{ nickName }}</span>
            <img :src="require('../img/up.png')" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getUserInfo, logOut } from '@/api/user';
export default {
  computed: {
    ...mapGetters(['language']),
  },
  data() {
    return {
      languageMap: {
        'zh-CN': '中文',
        'en-US': 'English',
      },
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      promptText: 'Good Morning',
      nickName: '',
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    setGreet(data) {
      var str = ''
      const now = new Date();
      const hour = now.getHours();

      if (hour >= 6 && hour < 12) {
        str = 'Good morning!'
        console.log('Good morning!');
      } else if (hour >= 12 && hour < 14) {
        str = 'Good noon!'
        console.log('Good noon!');
      } else if (hour >= 14 && hour < 18) {
        str = 'Good afternoon!'
        console.log('Good afternoon!');
      } else {
        str = 'Good evening!'
      }
      this.promptText = `${str} ${data.nickname}`
    },
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('app/setState', {
        language: lang,
      });
      localStorage.setItem('locale', lang);
      window.location.reload();
    },
    async getUserInfo() {
      let res = await getUserInfo();
      if (res.data.success) {
        const data = res.data.data;
        console.log('data: ', data);
        this.setGreet(data)
        this.$store.commit('app/setState', {
          user: data,
        });
        this.nickName = data.nickname;

        var authoritie = this.$router.history.current.meta.authoritie
        console.log('getUserInfo', this.$router.history.current.meta.authoritie);
        // 判断页面是否有权限
        const { authorities } = data
        if (authoritie) {
          if (authorities.indexOf(authoritie) == -1) {
            this.$router.replace('/noAuthorty')
          }
        }
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    async logOut(command) {
      if (!command == 'logout') return;
      let toPath = '/';
      let flag = this.$router.currentRoute.fullPath && this.$router.currentRoute.fullPath != '/login';
      if (flag) toPath = this.$router.currentRoute.fullPath;
      let obj = {
        accessToken: window.localStorage.getItem('screen_token'),
        refreshToken: window.localStorage.getItem('screen_refreshToken'),
      };

      let res = await logOut(obj);
      if (res.data.success) {
        window.localStorage.removeItem('screen_token'), this.$router.push(`/login?toPath=${toPath}`);
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.compCont {
  height: 63px;
  border-bottom: 1px solid #2c2c2c;
  padding: 0 24px;
  padding-right: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    color: #e9e9e9;
    font-size: 24px;
    font-weight: bold;
  }

  .right {
    display: flex;
    align-items: center;

    ::v-deep .el-dropdown {
      color: #fff;
    }

    .language {
      margin-right: 20px;
      cursor: pointer;
    }

    .avatar {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .el-dropdown-link {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
      }

      img {
        height: 16px;
      }
    }
  }
}

.el-dropdown-menu__item {
  line-height: 26px;
  padding: 0 16px;
}
</style>
