<!-- clapper配置 -->
<template>
  <div>
    <!-- text:地址 -->
    <el-form-item :label="$t('optionClapper.address')">
      <avue-input type="textarea"
                  v-model="main.activeObj.data.value"></avue-input>
    </el-form-item>
     <!-- text:自动播放 -->
    <el-form-item :label="$t('optionClapper.autoplay')">
      <avue-switch v-model="main.activeOption.autoplay"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'clapper',
  inject: ["main"]
}
</script>

<style>
</style>