export default {
  userControl: {
    add:'新增',
    username: '姓名',
    nickname: '昵称',
    sex: '性别',
    phone: '手机号',
    createTime: '创建时间',
    action: '操作',
    delete:'删除',
    popover:'确定要删除吗？',
    tip:'提示',
    change_pwd:'修改密码',
    add: '添加',
    description:'添加一个用户',
  },
  // 弹窗
  userDialog: {
    Name: '姓名',
    Nickname: '昵称',
    Gender: '性别',
    phoneNumber: '手机号',
    Password: '密码',
    confirmPassword: '确认密码',
    Cancel: '取消',
    Confirm: '确定',
    form1: '请输入昵称',
    form2: '请输入姓名',
    form3: '请选择',
    form4: '请输入您的手机号码',
    form5: '请输入密码',
    form6: '请再次输入密码',
    form7: '两次输入的密码不一致',
    form9: '请选择您的性别',
    form10: '请输入正确的手机号码',
    viewUser:'查看用户',
    editUser:'编辑用户',
    male:'男',
    female:'女',
    addUser:'添加用户',
  },
  changPwdDialog:{
    oldPwd:'旧密码',
    form4:'请输入旧密码',
    Password:'新密码',
    confirmPassword:'确认新密码',
    form5:'请输入新密码',
    form6:"请输入确认新密码",
    title:'修改密码',
    Cancel: '取消',
    Confirm: '确定',
  }
};
