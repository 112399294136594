export default {
  openPlatform: {
    add: '新建大屏嵌入',
    name: '名称',
    openPerson: '开通人',
    openTime: '开通时间',
    operation: '操作',
    edit:'编辑',
    del:'删除'
  },
  newReport: {
    embeddedReport: '新增嵌入大屏',
    dataObjectType: '数据对象类型',
    dataObjectName:'数据对象名称',
    workspace: '工作空间',
    reportName: '请输入报表名称',
    embeddingEnabled: '开通嵌入',
    name: '数据对象名称',
    type: '类型',
    cancel: '取消',
    confirm: '开通嵌入',
    option: '请输入内容',
    select: '请选择',
  },
  embeddedReport: {
    bind_User: '绑定用户',
    validity_Period: '有效时长',
    watermark_Parameter: '水印参数',
    access_Count: '访问次数',
    embedded_Object: '嵌入对象',
    security_Authentication_Type: '安全认证类型',
    ticket_Link: 'Ticket链接',
    get_Embed_Code_URL: '获取嵌入代码',
    iframe_Code: 'Iframe代码',
    copy: '复制',
    close_Embed: '关闭嵌入',
    uRL_Link: 'URL链接',
    embed_Configuration: '嵌入配置',
    ticket_Authentication: 'Ticket认证',
    quick_Generate: '快捷生成',
    collapse: '收起',
    generate_Ticket: '生成Ticket',

    minutes: '分钟',
    you_are_debugging: '你正在调试',
    embed_code: '嵌入代码',
    please_enter: '请输入Ticket链接',
    the_default: '默认过期时间为240分钟，支持自定义传失效时间。',
    watermark_parameter: '报表的水印参数，不得超过50个字符。',
    if_you: '若输入1，表示生成的链接仅支持被访问1次。默认为1，最大支持输入9999次。',
    embeddedObject: '全部页面',
    watermarking:'报表的水印参数，不得超过50个字符',
    GenerateTicket:'生成Ticket',
    cancel: '取消',
    confirm:'确定',
    tip:'关闭嵌入后已经生成的链接会失效。确定要关闭吗？',
    successful_replication:'复制成功：',
    replication_failure:'复制失败：',
    succeeded_Ticket:'生成Ticket成功'
  },
};
