<!-- video配置 -->
<template>
  <div>
    <!-- text:地址 -->
    <el-form-item :label="$t('optionVideo.address')">
      <avue-input type="textarea"
                  v-model="main.activeObj.data.value"></avue-input>
    </el-form-item>
    <!-- text:封面 -->
    <el-form-item :label="$t('optionVideo.cover')">
      <img v-if="main.activeOption.poster"
           :src="main.activeOption.poster"
           alt=""
           width="100%" />
      <el-input v-model="main.activeOption.poster">
        <div @click="main.handleOpenImg('activeOption.poster')"
             slot="append">
          <i class="iconfont icon-img"></i>
        </div>
      </el-input>
    </el-form-item>
    <!-- text:自动播放 -->
    <el-form-item :label="$t('optionVideo.autoPlay')">
      <avue-switch v-model="main.activeOption.autoplay"></avue-switch>
    </el-form-item>
    <!-- text:控制台 -->
    <el-form-item :label="$t('optionVideo.controls')">
      <avue-switch v-model="main.activeOption.controls"></avue-switch>
    </el-form-item>
    <!-- text:循环 -->
    <el-form-item :label="$t('optionVideo.loop')">
      <avue-switch v-model="main.activeOption.loop"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'video',
  inject: ["main"]
}
</script>

<style>
</style>