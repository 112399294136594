<!-- 图片框配置 -->
<template>
  <div>
    <!-- text:背景色 -->
    <el-form-item :label="$t('optionImgborder.backgroundColor')">
      <avue-input-color v-model="main.activeOption.backgroundColor"></avue-input-color>
    </el-form-item>
     <!-- text:图片透明度 -->
    <el-form-item :label="$t('optionImgborder.imageOpacity')">
      <avue-slider v-model="main.activeOption.opacity"></avue-slider>
    </el-form-item>
     <!-- text:图片地址 -->
    <el-form-item :label="$t('optionImgborder.imageURL')">
      <img v-if="main.activeObj.data"
           :src="main.activeObj.data"
           alt=""
           width="100%" />
      <el-input v-model="main.activeObj.data">
        <div @click="main.handleOpenImg('activeObj.data','border')"
             slot="append">
          <i class="iconfont icon-img"></i>
        </div>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'imgborder',
  inject: ["main"]
}
</script>

<style>
</style>