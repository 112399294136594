<!-- 通用形配置 -->
<template>
  <div>
    <!-- text:在线文档  点击查看-->
    <el-form-item :label="$t('optionCommon.configurationList')">
      <a href="https://echarts.apache.org/examples/zh/index.html"
      
         target="_blank">{{$t('optionCommon.clickToView')}}</a>
    </el-form-item>
    <!-- text:配置列表 -->
    <el-form-item :label="$t('optionCommon.configurationList')">
      <!-- text:编辑 -->
      <el-button size="small"
                 type="primary"
                 @click="openCode">{{$t('optionCommon.edit')}}</el-button>
    </el-form-item>
    <!-- text:配置列表 -->
    <codeedit @submit="codeClose"
              :title="$t('optionCommon.configurationList')"
              v-model="code.obj"
              v-if="code.box"
              :type="code.type"
              :visible.sync="code.box"></codeedit>
  </div>
</template>

<script>
import codeedit from '../../page/group/code';
export default {
  name: 'common',
  inject: ["main"],
  data () {
    return {
      code: {
        box: false,
        obj: {},
      }
    }
  },
  components: {
    codeedit
  },
  methods: {
    codeClose (value) {
      this.main.activeObj[this.code.type] = value;
    },
    openCode () {
      this.code.type = 'echartFormatter';
      this.code.obj = this.main.activeObj[this.code.type];
      this.code.box = true;
    },
  }
}
</script>

<style>
</style>