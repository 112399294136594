export default {
  build: {
    Layer: 'Layer',
    Configuration: 'Configuration',
    Data: 'Data',
    Interaction: 'Interaction',
    Event: 'Event',
    Parameter: 'Parameter',
    Horizontal_Alignment: 'Horizontal Alignment',
    Left_Alignment: 'Left Alignment',
    Center_Alignment: 'Center Alignment',
    Right_Alignment: 'Right Alignment',
    Vertical_Alignment: 'Vertical Alignment',
    Top_Alignment: 'Top Alignment',
    Middle_Alignment: 'Middle Alignment',
    Bottom_Alignment: 'Bottom Alignment',
    Group: 'Group',
    Delete: 'Delete',
    Layer_Name: 'Layer Name',
    Hide: 'Hide',
    Lock: 'Lock',
    System_Colors: 'System Colors',
    Screen_Size: 'Screen Size',
    Screen_Description: 'Screen Description',
    Background_Color: 'Background Color',
    Background_Image: 'Background Image',
    Preview_Mode: 'Preview Mode',
    X_Axis_Fill: 'X Axis Fill, Y Axis Scroll',
    Y_Axis_Fill: 'Y Axis Fill, X Axis Scroll',
    Stretch_Fill_View: 'Stretch Fill View',
    More_Settings: 'More Settings',
    Data_Type: 'Data Type',
    SQL_Statement: 'SQL Statement',
    API_Address: 'API Address',
    Request_Method: 'Request Method',
    WS_Address: 'WS Address',
    Data_Set_Selection: 'Data Set Selection',
    Refresh_Time: 'Refresh Time',
    Edit_Style: 'Edit Style',
    Request_Data: 'Request Data',
    Subclass: 'Subclass',
    Parameter_Name: 'Parameter Name',
    Mapped_Field: 'Mapped Field',
    Default_Value: 'Default Value',
    Other_Parameters: 'Other Parameters',
    Add_Parameter: 'Add Parameter',
    Click_Event: 'Click Event',
    Edit: 'Edit',
    Tooltip_Event: 'Tooltip Event',
    Title_Event: 'Title Event',
    Number: 'Number',
    Enter_Animation: 'Enter Animation',
    Click_View_Animation_Type: 'Click View Animation Type',
    Position: 'Position',
    Size: 'Size',
    Font: 'Font',
    Input_Font_Library_URL: 'Input Font Library URL',
    Perspective: 'Perspective',
    Scale: 'Scale',
    Opacity: 'Opacity',
    X_Rotation: 'X Rotation',
    Y_Rotation: 'Y Rotation',
    Z_Rotation: 'Z Rotation',
    Global_Request_Address: 'Global Request Address',
    Global_Request_Parameters: 'Global Request Parameters',
    Global_Request_Headers: 'Global Request Headers',
    Screen_Watermark: 'Screen Watermark',
    Content: 'Content',
    bigSize:'Size',
    Color: 'Color',
    Angle: 'Angle',
    Data_Value: 'Data Value',
    Edit_Data_Value: 'Edit Data Value',
    Import_Data_Excel: 'Import Data (Excel)',
    Data_Source_Selection: 'Data Source Selection',
    If_you_want: 'If you want to get variables, just write it as a function returning SQL statement',
    Request_Configuration: 'Request Configuration',
    Request_Parameter: 'Request Parameter (Body)',
    JSON_Data: 'JSON Data',
    FORM_Data: 'FORM Data',
    Request_parameters: 'Request Parameters',
    Request_headers: 'Request Headers',
    Request_header:'Request header',
    Filter: 'Filter',
    Edit_filter: 'Edit Filter',
    Response_data: 'Response Data',
    Copy_successful: 'Copy Successful',
    Copy_failed: 'Copy Failed',
    Import_successful: 'Import Successful',
    Request_data: 'Request Data Successful',
    Copy_component: 'Copy Component Successful',
    Paste_component: 'Paste Component Successful',
    Batch_delete: 'Batch Delete Selected Layers?',
    Prompt: 'Prompt',
    OK: 'OK',
    Cancel: 'Cancel',
  },
};
