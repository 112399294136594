<template>
  <div class="login">
    <div class="loginBox">
      <div class="bg"></div>
      <div class="blur"></div>
      <div class="loginBoxTitle">
        {{ $t('login.WELCOME') }}
      </div>

      <div class="forms">
        <el-form ref="form" :model="form" :rules="rules" :autocomplete="autocomplete">
          <!-- text：用户名 -->
          <el-form-item :label="$t('login.name')" style="margin-bottom: 28px" prop="username">
            <!-- text:请输入用户名 -->
            <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <!-- text：密码 -->
          <el-form-item :label="$t('login.password')" style="margin-bottom: 16px" prop="password">
            <!-- text:请输入密码 -->
            <el-input v-model="form.password" show-password placeholder="请输入密码"></el-input>
          </el-form-item>

          <div style="padding-left: 16px">
            <!-- text:记住我 -->
            <el-checkbox v-model="checked" size="medium" @change="getChecked">{{ $t('login.Rememberme') }}</el-checkbox>
          </div>
          <!-- text:登录 -->
          <el-button type="primary" @click="login">{{ $t('login.button') }}</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { bindLocalesToVue } from '@/utils/utils';
import { login } from '@/api/user';
import locales from './locales';
export default {
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales);
    return {
      autocomplete: 'on',
      checked: true,
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    async login() {
      this.$refs['form'].validate(async valid => {

        const { toPath } = this.$router.history.current.query;
        let routerUrl = toPath || '/'
        if (!valid) return;
        window.localStorage.removeItem('screen_token');
        let res = await login(this.form);
        if (res.data.success) {
          window.localStorage.setItem('screen_token', res.data.data.accessToken);
          window.localStorage.setItem('screen_refreshToken', res.data.data.refreshToken);
          this.$router.push({ path: routerUrl });
        } else {
          this.$message.error(res.data.subMsg);
        }
      });
    },
    getChecked(val) {
      if (val) {
        this.autocomplete = 'on';
      } else {
        this.autocomplete = 'off';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  background-image: url(./img/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  * {
    box-sizing: border-box;
  }
}

.loginBox {
  position: relative;
  left: 210px;
  top: 140px;
  width: 578px;
  height: 604px;
  border-radius: 24px;
  padding-top: 60px;
  border: 2px solid #676f86;
  overflow: hidden;

  .blur {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    filter: blur(10px);
  }

  .bg {
    border-radius: 24px;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.36;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(183, 204, 255, 1));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    filter: blur(10px);
    filter: opacity(36%);
  }
}

.loginBoxTitle {
  position: relative;
  font-size: 36px;
  text-align: center;
  color: #fff;
  z-index: 2;
  font-weight: bold;
  margin-bottom: 50px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  text-shadow: 0px 0px 8px #000;

}

.forms {
  width: 326px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

::v-deep .el-form-item__label {
  padding-left: 0;
  line-height: 1;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 16px;
}

::v-deep .el-form-item__content {
  padding-right: 0;
}

::v-deep .el-input__inner {
  padding: 0 16px;
  border: 2px solid transparent;
  border-radius: 24px;
  height: 50px;
  box-shadow: 0px 0px 1px 0px rgba(155, 227, 255, 0.5);
  background: #ffffff;
  border-color: transparent;
  color: rgba(0, 0, 0, 1);

  &:hover {
    border: 2px solid rgba(84, 117, 255, 1);
  }
}

::v-deep .el-checkbox__label {
  color: #fff !important;
}

::v-deep .el-checkbox__inner {
  // border-radius: 100%;
}

.el-button {
  margin-top: 50px;
  width: 100%;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  height: 50px;
  background: linear-gradient(180deg, #8a8fff 0%, #5475ff 100%);
  box-shadow: 0px 0px 7px -1px rgba(155, 227, 255, 0.5);
  border-radius: 25px;
  border: 1px solid #94a9ff;
}
</style>
