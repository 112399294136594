<!-- 表格配置 -->
<template>
  <div>
    <!-- text:开启排名 -->
    <el-form-item :label="$t('optionTable.enableRanking')">
      <avue-switch v-model="main.activeOption.index"></avue-switch>
    </el-form-item>
     <!-- text:边框 -->
    <el-form-item :label="$t('optionTable.border')">
      <avue-switch v-model="main.activeOption.border">
      </avue-switch>
    </el-form-item>
     <!-- text:追加模式 -->
    <el-form-item :label="$t('optionTable.appendMode')">
      <avue-switch v-model="main.activeOption.dataAppend">
      </avue-switch>
    </el-form-item>
     <!-- text:开启滚动 -->
    <el-form-item :label="$t('optionTable.enableScrolling')">
      <avue-switch v-model="main.activeOption.scroll">
      </avue-switch>
    </el-form-item>
    <template v-if="main.activeOption.scroll">
       <!-- text:滚动间隔 -->
      <el-form-item :label="$t('optionTable.scrollingInterval')">
        <avue-input-number v-model="main.activeOption.scrollTime">
        </avue-input-number>
      </el-form-item>
       <!-- text:滚动速度 -->
      <el-form-item :label="$t('optionTable.scrollingSpeed')">
        <avue-input-number v-model="main.activeOption.scrollSpeed">
        </avue-input-number>
      </el-form-item>
    </template>
    <el-collapse accordion>
       <!-- text:表头设置 -->
      <el-collapse-item :title="$t('optionTable.tableHeaderSetting')">
         <!-- text:显示 -->
        <el-form-item :label="$t('optionTable.display')">
          <avue-switch v-model="main.activeOption.showHeader">
          </avue-switch>
        </el-form-item>
         <!-- text:字体大小 -->
        <el-form-item :label="$t('optionTable.fontSize')">
          <avue-input-number v-model="main.activeOption.headerFontSize">
          </avue-input-number>
        </el-form-item>
         <!-- text:背景颜色 -->
        <el-form-item :label="$t('optionTable.backgroundColor')">
          <avue-input-color type="textarea"
                            v-model="main.activeOption.headerBackground"></avue-input-color>
        </el-form-item>
         <!-- text:字体颜色 -->
        <el-form-item :label="$t('optionTable.fontColor')">
          <avue-input-color type="textarea"
                            v-model="main.activeOption.headerColor"></avue-input-color>
        </el-form-item>
         <!-- text:对其方式 -->
        <el-form-item :label="$t('optionTable.alignment')">
           <!-- text:开启排名 -->
          <avue-select v-model="main.activeOption.headerTextAlign"
                       :dic="dicOption.textAlign">
          </avue-select>
        </el-form-item>
      </el-collapse-item>
       <!-- text:表格设置 -->
      <el-collapse-item :title="$t('optionTable.tableSetting')">
         <!-- text:显示行数 -->
        <el-form-item :label="$t('optionTable.displayRows')">
          <avue-input-number v-model="main.activeOption.count">
          </avue-input-number>
        </el-form-item>
         <!-- text:字体大小 -->
        <el-form-item :label="$t('optionTable.fontSize')">
          <avue-input-number v-model="main.activeOption.bodyFontSize">
          </avue-input-number>
        </el-form-item>
         <!-- text:对其方式 -->
        <el-form-item :label="$t('optionTable.alignment')">
          <avue-select v-model="main.activeOption.bodyTextAlign"
                       :dic="dicOption.textAlign">
          </avue-select>
        </el-form-item>
         <!-- text:文字颜色 -->
        <el-form-item :label="$t('optionTable.textColor')">
          <avue-input-color type="textarea"
                            v-model="main.activeOption.bodyColor"></avue-input-color>
        </el-form-item>
         <!-- text:奇行颜色 -->
        <el-form-item :label="$t('optionTable.oddRowColor')">
          <avue-input-color type="textarea"
                            v-model="main.activeOption.nthColor"></avue-input-color>
        </el-form-item>
         <!-- text:偶行颜色 -->
        <el-form-item :label="$t('optionTable.evenRowColor')">
          <avue-input-color type="textarea"
                            v-model="main.activeOption.othColor"></avue-input-color>
        </el-form-item>
      </el-collapse-item>
       <!-- text:表格列设置 -->
      <el-collapse-item :title="$t('optionTable.tableColumnSetting')">
        <avue-crud :option="tableOption"
                   :data="main.activeOption.column"
                   v-model="form"
                   @row-save="rowSave"
                   @row-del="rowDel"
                   @row-update="rowUpdate">
          <template slot="formatterForm"
                    slot-scope="{}">
            <monaco-editor v-model="form.formatter"
                           language="javascript"
                           disabled
                           height="100"></monaco-editor>
                            <!-- text:编辑 -->
            <el-button size="small"
                       type="primary"
                       icon="el-icon-edit"
                       @click="openCode('formatter','格式化')">{{$t('optionTable.edit')}}</el-button>
          </template>
          <template slot="menuLeft"
                    slot-scope="{}">
                     <!-- text:编辑数据 -->
            <el-button size="small"
                       icon="el-icon-s-operation"
                       @click="openCode('column','表格列设置')"
                       type="primary">{{$t('optionTable.editData')}}</el-button>
          </template>
        </avue-crud>
      </el-collapse-item>
    </el-collapse>
    <codeedit @submit="codeClose"
              :title="code.title"
              v-model="code.obj"
              v-if="code.box"
              :type="code.type"
              :visible.sync="code.box"></codeedit>
  </div>
</template>

<script>
import MonacoEditor from '@/page/components/editor'
import { tableOption, dicOption } from '@/option/config'
import codeedit from '../../page/group/code';
export default {
  name: 'table',
  data () {
    return {
      form: {},
      dicOption: dicOption,
      tableOption: tableOption,
      code: {
        box: false,
        type: '',
        obj: {},
      }
    }
  },
  inject: ["main"],
  components: {
    codeedit,
    MonacoEditor
  },
  methods: {
    rowSave (row, done) {
      this.main.activeOption.column.push(this.deepClone(row));
      done()
    },
    rowDel (row, index) {
      this.main.activeOption.column.splice(index, 1);
    },
    rowUpdate (row, index, done) {
      this.main.activeOption.column.splice(index, 1, this.deepClone(row));
      done()
    },
    codeClose (value) {
      if (this.code.type == 'formatter') {
        this.form.formatter = value;
      } else {
        this.main.activeOption.column = value;
      }

    },
    openCode (type, title) {
      this.code.title = title;
      this.code.type = type;
      if (type == 'formatter') {
        this.code.obj = this.form.formatter
      } else {
        this.code.obj = this.main.activeOption.column
      }
      this.code.box = true;
    },
  }
}
</script>

<style>
</style>