<!-- 柱状图配置 -->
<template>
  <div>
    <!-- text:竖展示 -->
    <el-form-item :label="$t('optionBar.verticalDisplay')">
      <avue-switch type="textarea"
                   v-model="main.activeOption.category"></avue-switch>
    </el-form-item>
    <el-collapse accordion>
      <!-- text:柱体设置 -->
      <el-collapse-item :title="$t('optionBar.columnWidth')">
         <!-- text:最大宽度 -->
        <el-form-item :label="$t('optionBar.maxWidth')">
          <avue-slider v-model="main.activeOption.barWidth">
          </avue-slider>
        </el-form-item>
         <!-- text:圆角 -->
        <el-form-item :label="$t('optionBar.cornerRadius')">
          <avue-slider v-model="main.activeOption.barRadius">
          </avue-slider>
        </el-form-item>
         <!-- text:最小高度 -->
        <el-form-item :label="$t('optionBar.minHeight')">
          <avue-slider v-model="main.activeOption.barMinHeight">
          </avue-slider>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'bar',
  inject: ["main"]
}
</script>

<style>
</style>