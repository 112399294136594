<!-- 实时时间配置 -->
<template>
  <div>
    <!-- text: 时间格式 -->
    <el-form-item :label="$t('optionDatetime.dateFormat')">
      <avue-select v-model="main.activeOption.format"
                   :dic="dicOption.format">
      </avue-select>
    </el-form-item>
    <!-- text: 自定义格式 -->
    <el-form-item :label="$t('optionDatetime.customFormat')">
      <avue-input v-model="main.activeOption.format">
      </avue-input>
    </el-form-item>
    <!-- text: 字体间距 -->
    <el-form-item :label="$t('optionDatetime.fontSpacing')">
      <avue-input-number v-model="main.activeOption.split"
                         :max="200"></avue-input-number>
    </el-form-item>
    <!-- text: 字体大小 -->
    <el-form-item :label="$t('optionDatetime.fontSize')">
      <avue-input-number v-model="main.activeOption.fontSize"
                         :max="200"></avue-input-number>
    </el-form-item>
    <!-- text: 字体背景 -->
    <el-form-item :label="$t('optionDatetime.fontBackground')">
      <avue-input-color v-model="main.activeOption.backgroundColor"></avue-input-color>
    </el-form-item>
    <!-- text: 对其方式 -->
    <el-form-item :label="$t('optionDatetime.alignment')">
      <avue-select v-model="main.activeOption.textAlign"
                   :dic="dicOption.textAlign">
      </avue-select>
    </el-form-item>
    <!-- text: 文字粗细 -->
    <el-form-item :label="$t('optionDatetime.fontWeight')">
      <avue-select v-model="main.activeOption.fontWeight"
                   :dic="dicOption.fontWeight">
      </avue-select>
    </el-form-item>
    <!-- text: 字体颜色 -->
    <el-form-item :label="$t('optionDatetime.fontColor')">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
  </div>
</template>

<script>
import { dicOption } from '@/option/config'
export default {
  name: 'datetime',
  data () {
    return {
      dicOption: dicOption
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>