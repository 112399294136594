<!-- 通用形配置 -->
<template>
  <div>
    <!-- text:组件  编辑 -->
    <el-form-item :label="$t('optionVue.component')">
      <el-button size="small"
                 type="primary"
                 @click="openCode">{{$t('optionVue.edit')}}</el-button>
      <el-button type="primary"
                 size="small"
                 @click="api.box=true"
                 icon="el-icon-search"></el-button>
    </el-form-item>
     <!-- text:配置列表-->
    <codeedit @submit="codeClose"
              :title="$t('optionVue.configurationList')"
              :rules="false"
              v-model="code.obj"
              v-if="code.box"
              :type="code.type"
              :visible.sync="code.box"></codeedit>

 <!-- text:组件库 -->
    <el-dialog :title="$t('optionVue.componentLibrary')"
               :visible.sync="api.box"
               width="60%">
      <component-list v-if="api.box"
                      @change="handleApiChange"
                      :menu="false"></component-list>
      <span slot="footer"
            class="dialog-footer">
          <!-- text:确 定 -->  
        <el-button type="primary"
                   @click="handleApiSubmit()"
                   size="small">{{$t('optionVue.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import codeedit from '../../page/group/code';
import componentList from '../../page/compLibrary'
import { getObj } from '@/api/components'
export default {
  name: 'vue',
  inject: ["main"],
  components: {
    componentList,
    codeedit
  },
  data () {
    return {
      api: {
        box: false,
        item: {}
      },
      code: {
        type: 'content',
        box: false,
        obj: {},
      }
    }
  },
  methods: {
    handleApiSubmit () {
      getObj(this.api.item.id).then(res => {
        const data = res.data.data;
        this.main.activeOption[this.code.type] = data.content
        this.api.box = false;
        this.api.item = {}
      })
    },
    handleApiChange (val) {
      this.api.item = val
    },
    codeClose (value) {
      this.main.activeOption[this.code.type] = value;
    },
    openCode () {
      this.code.obj = this.main.activeOption[this.code.type];
      this.code.box = true;
    },
  }
}
</script>

<style>
</style>