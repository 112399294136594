<template>
    <div class="myGroups">
        <div class="myGroupsTitle">
            <div class="icon"><i class="icon iconfont icon_icon_mygroups-01"></i></div>
            <div class="title">{{this.$t('layout.group')}}</div>
            <!-- <div class="addIcon" @click="openModual"><i class="el-icon-plus"></i></div> -->
        </div>

        <div class="myGroupsList">
            <div class="myGroupsListCont">
                <div :key="index" v-for="(item, index) in myGroups"
                    :class="categoryId == item.categoryValue ? 'myGroupsItem select' : 'myGroupsItem'"
                    @click="() => menuClick(item, index)">
                    <div class="title">{{ item.categoryKey }}</div>
                    <!-- <div class="right">
                <div class="icon" style="margin-right: 14px;"><i class="icon iconfont icon_icon_gm1-01"></i></div>
                <div class="icon"><i class="icon iconfont icon_icon_gm1_d-01"></i></div>
            </div> -->
                </div>
            </div>
        </div>

        <myGroupsModual ref="myGroupsModual" :change="myGroupsModualChange" />
    </div>
</template>
<script>
import { getCategory } from '@/api/visual';
import { mapGetters } from 'vuex'
import myGroupsModual from './myGroupsModual.vue';
export default {
    components: {
        myGroupsModual
    },
    data() {
        return {
            categoryId: this.setCategoryId(),
            dialogFormVisible: false,
            title: '新增',
            form: {
                categoryKey: '',
                categoryValue: ''
            },
        }
    },
    computed: {
        ...mapGetters(['myGroups']),
    },
    created() {
        this.getCategory()
    },
    watch: {
        //监听路由地址的改变
        $route: {
            immediate: true,
            handler() {
                this.setCategoryId()
            }
        }
    },
    methods: {

        // 设置 分类id
        setCategoryId() {
            this.categoryId = this.$router.history.current.query.categoryId
        },
        // 菜单跳转
        menuClick(item, index) {
            var id = this.$router.history.current.query.categoryId
            if (id == item.categoryValue) return
            this.$router.push({ path: '/', query: { categoryId: item.categoryValue } })
        },
        // 获取我的分组数据
        async getCategory() {
            var rs = await getCategory()
            var data = rs.data.data
            this.$store.commit('home/setState', {
                myGroups: data
            })
            var query = this.$router.history.current.query
            var currentPath = this.$router.history.current.path
            // 获取第一个分类
            if (currentPath =='/' && !query.categoryId && data[0]) {
                setTimeout(() => {
                    this.$router.replace({ path: '/', query: { categoryId: data[0].categoryValue } })
                }, 50)
            }
        },

        // 打开弹出框
        openModual() {
            this.$refs.myGroupsModual.open({
                title: '新增',
                type: 'add',
                id: '',
            })
        },

        // 弹出框发生变化
        myGroupsModualChange() {
            this.getCategory()
        },
    }
}
</script>
<style lang="scss" scoped>
.myGroups {
    flex: 1;
    padding: 0 8px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    * {
        box-sizing: border-box;
    }

    .myGroupsTitle {
        line-height: 1;
        display: flex;
        align-items: center;
        padding: 8px 8px;
        border-bottom: 1px solid #2F2A32;
        margin-bottom: 20px;
        position: relative;

        .icon {
            font-size: 20px;
            margin-right: 6px;
        }

        .title {
            font-size: 14px;

        }

        .addIcon {
            position: absolute;
            right: 8px;
            top: 10px;
            cursor: pointer;
        }
    }

    .myGroupsList {
        position: relative;
        flex: 1;

        .myGroupsListCont {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
        }
    }

    .myGroupsItem {
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0px;
        padding-left: 40px;
        border-radius: 10px;
        transition: all 0.3s;
        cursor: pointer;
        margin-bottom: 8px;
        padding-right: 20px;
        min-height: 38px;

        &:hover {
            background: #2D3038;

            .icon {
                display: block;
            }

            .title {
                color: #E9E9E9;
            }
        }

        .title {
            font-size: 12px;
            color: #848484;
            transition: all 0.3s;
        }

        .right {
            display: flex;
            align-items: center;
        }

        .icon {
            display: none;
            transition: all 0.3s;
            cursor: pointer;
            font-size: 16px;
        }
    }

    .select {
        background: #4E4CBE !important;

        .title {
            color: #fff !important;
        }

    }
}


.formCont {
    display: flex;
}
</style>