<template>
  <div>
    <!-- text:导入导出  -->
    <el-dialog append-to-body
               @open="open"
               :title="$t('groupResult.import_export')"
               :close-on-click-modal="false"
               :visible.sync="show"
               width="60%">
      <monaco-editor v-model="json"
                     height="400"
                     language="javascript"></monaco-editor>
      <span slot="footer"
            class="dialog-footer">
             <!-- text:导入组件  -->
        <el-button @click="openBlock"
                   size="small"
                   type="success">{{$t('groupResult.import_component')}}</el-button>
                    <!-- text:导入数据  -->
        <el-button @click="importData"
                   size="small"
                   type="primary">{{$t('groupResult.import_data')}}</el-button>
                    <!-- text:导出数据  -->
        <el-button @click="exportData"
                   size="small"
                   type="danger">{{$t('groupResult.export_data')}}</el-button>
      </span>

    </el-dialog>
     <!-- text:导入组件  -->
    <el-dialog append-to-body
               @open="open"
               :title="$t('groupResult.import_component')"
               :close-on-click-modal="false"
               :visible.sync="show1"
               width="30%">
      <monaco-editor v-model="json1"
                     height="200"
                     language="javascript"></monaco-editor>
      <span slot="footer"
            class="dialog-footer">
             <!-- text:导入组件  -->
        <el-button @click="importBlock"
                   size="small"
                   type="primary">{{$t('groupResult.import_component')}}</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import MonacoEditor from '@/page/components/editor'
export default {
  components: { MonacoEditor },
  inject: ["contain"],
  data () {
    return {
      show: false,
      show1: false,
      json: {},
      json1: {}
    }
  },
  methods: {
    open () {
      this.json = {
        detail: this.contain.config,
        component: this.contain.nav
      }
      this.json1 = {}
    },
    importBlock () {
      try {
        let json = typeof (this.json1) == 'string' ? JSON.parse(this.json1) : this.json1
        this.contain.nav.unshift(json)
        this.show = false
        this.show1 = false
        //text: 导入组件成功
        this.$message.success(this.$t('groupResult.import_component_success'))
      } catch (err) {
         //text: 导入数据错误
        this.$message.error(this.$t('groupResult.import_component_error'))
      }
    },
    openBlock () {
      this.show1 = true
    },
    importData () {
      try {
        let json = typeof (this.json) == 'string' ? JSON.parse(this.json) : this.json
        this.contain.config = json.detail
        this.contain.nav = json.component
        this.show = false;
         //text: 数据导入成功
        this.$message.success(this.$t('groupResult.import_data_success'))
      } catch (err) {
         //text:导入数据错误
        this.$message.error(this.$t('groupResult.import_data_error'))
      }
    },
    exportData () {
      var zip = new window.JSZip();
      zip.file("data.txt", this.json);
      zip.generateAsync({ type: "base64" })
        .then((content) => {
          this.downFile("data:application/zip;base64," + content, 'data.zip')
        });
    }
  }
}
</script>

<style>
</style>