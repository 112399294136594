<template>
  <div class="compLibrary">
    <tableSty>
      <avue-crud ref="crud" :option="option" v-model="form" @row-save="rowSave" @row-update="rowUpdate" @row-del="rowDel"
        :before-open="beforeOpen" @selection-change="selectionChange" :data="data">
        <template slot-scope="{size,type,row,index}" slot="menu">
          <el-button :size="size" icon="el-icon-edit" :type="type" @click="handleView(row, index)">{{
            $t('compLibrary.Preview') }}</el-button>
        </template>
        <template slot-scope="{}" slot="contentForm">
          <monaco-editor v-model="form.content" :language="options.language" :height="options.height"
            :options="options"></monaco-editor>
          <br />
          <el-button v-if="!view" type="primary" size="small" icon="el-icon-check" @click="handleOption">{{
            $t('compLibrary.Run') }}</el-button>
        </template>
        <template slot-scope="{}" slot="viewForm">
          <avue-echart-vue :option="vueOption" width="100%" height="100%"></avue-echart-vue>
        </template>
        <template slot-scope="scope" slot="menuLeft">
          <optionsButton :list="optionsList" />
        </template>
      </avue-crud>
      <el-dialog :title="$t('compLibrary.PreviewComponent')" :visible.sync="box" width="60%">
        <avue-echart-vue :option="vueOption" width="100%" height="100%"></avue-echart-vue>
      </el-dialog>

    </tableSty>
  </div>
</template>

<script>
import AvueEchartVue from '@/echart/packages/vue';
import MonacoEditor from '@/page/components/monaco-editor'
import { getList, getObj, addObj, delObj, updateObj } from '@/api/components'
import tableSty from '@/page/components/tableSty';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales';
import optionsButton from '@/page/components/optionsButton';
export default {
  components: {
    AvueEchartVue,
    MonacoEditor,
    tableSty,
    optionsButton,
  },
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales);
    return {
      box: false,
      options: {
        height: 300,
        language: 'javascript',
        fontSize: 12
      },
      content: '',
      index: -1,
      form: {},
      vueOption: {},
      data: [],
      option: {

        // 取消菜单 Fixed
        menuFixed: false,
        indexFixed: false,

        addBtn: false,

        // 新建按钮
        addBtnText: this.$t('crud.add'),

        // 编辑按钮
        editBtnText: this.$t('crud.editBtnText'),
        editBtnIcon: 'el-icon-edit-outline',

        // 删除按钮
        delBtnText: this.$t('crud.delBtnText'),
        delBtnIcon: 'icon iconfont icon_icon_gm1_d-01',

        // 修改按钮
        updateBtnText: this.$t('crud.update'),

        // 取消按钮
        cancelBtnText: this.$t('crud.cancel'),

        // 复制按钮
        copyBtnText: this.$t('crud.copyBtnText'),

        // 保存按钮
        saveBtnText: this.$t('crud.saveBtnText'),

        // 操作文字
        menuTitle: this.$t('crud.action'),

        tip: false,
        header: true,
        menu: true,
        selection: false,
        dialogWidth: '70%',
        labelWidth: 100,
        index: true,
        align: 'center',
        headerAlign: 'center',
        column: [
          {
            label: this.$t('compLibrary.ComponentName'),
            prop: 'name',
            row: true,
            rules: [{
              required: true,
              message: this.$t('compLibrary.PlsEnterComponentName'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('compLibrary.ComponentData'),
            prop: 'content',
            span: 24,
            hide: true,
          },
          {
            label: this.$t('compLibrary.ComponentPreview'),
            prop: 'view',
            span: 24,
            hide: true,
          }
        ]
      },
      optionsList: [
        {
          icon: require('@/page/category/img/add.png'),
          title: this.$t('compLibrary.add'),
          description: this.$t('compLibrary.description'),
          click: () => {
            if (!this.authorityVerify('RESOURCE_COMPONENTMANAGEMENT_ADD_BTN')) return
            this.$refs.crud.rowAdd()
          }
        },
      ],
    }
  },
  created() {
    if (this.menu === false) {
      this.option.header = false
      this.option.menu = false
    }
    this.getList()
  },
  methods: {
    selectionChange(val) {
      this.$emit('change', val[0])
    },
    vaildData(id) {
      return [0, 1, 2].includes(id)
    },
    beforeOpen(done, type) {
      if (type == 'edit') {
        if (!this.authorityVerify('RESOURCE_DATASETMANAGEMENT_UPDATE_BTN')) return
        getObj(this.form.id).then(res => {
          const data = res.data.data;
          this.form = data
          this.handleOption()
          done()
        })
      } else {

        this.form.content = ''
        this.handleOption()
        done()
      }

    },
    rowDel(row, index) {
      if (!this.authorityVerify('RESOURCE_COMPONENTMANAGEMENT_DEL_BTN')) {
        return
      }
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.$confirm(this.$t('compLibrary.DelConfirm'), this.$t('compLibrary.Prompt'), {
        confirmButtonText: this.$t('compLibrary.Confirm'),
        cancelButtonText: this.$t('compLibrary.Cancel'),
        type: 'warning'
      }).then(() => {
        delObj(row.id).then(() => {
          this.$message.success(this.$t('compLibrary.DelSuccess'));
          this.getList()
        })
      })
    },
    handleOption() {
      this.vueOption = this.deepClone(this.form)
    },
    rowUpdate(row, index, done) {
      if (this.vaildData(index) && this.$website.isDemo) {
        done();
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      updateObj(row).then(() => {
        done();
        this.$message.success(this.$t('compLibrary.ModSuccess'));
        this.getList()

      })
    },
    rowSave(row, done) {
      addObj(row).then(() => {
        this.$message.success(this.$t('compLibrary.AddSuccess'));
        this.getList()
        done();
      })
    },
    handleView(row, index) {
      if (!this.authorityVerify('RESOURCE_COMPONENTMANAGEMENT_VIEW_BTN')) return
      getObj(row.id).then(res => {
        const data = res.data.data;
        this.vueOption = this.deepClone({
          content: data.content
        })
        this.box = true
      })
    },
    getList() {
      getList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.data = data.records;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.components {
  &_content {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  &_left,
  &_right {
    width: 100%;
  }
}


.compLibrary {
  padding-top: 10px;

  ::v-deep .iconfont {
    font-size: 14px;
  }

  ::v-deep i {
    font-size: 14px;
  }

  ::v-deep .el-button--text {
    color: rgb(128, 126, 255);
  }

  ::v-deep .el-button--text:nth-child(2) {
    color: rgb(255, 126, 126);
  }

  ::v-deep .avue-crud {
    padding: 0;
  }
}
</style>