export default {
    category: {
        "ModName": "模块名",
        "PlsEnterModName": "请输入模块名",
        "ModValue": "模块值",
        "PlsEnterModValue": "请输入模块值",
        "DelConfirm": "此操作将永久删除 是否继续?",
        "Confirm": "确定",
        "Cancel": "取消",
        "DelSuccess": "删除成功",
        "ModSuccess": "修改成功",
        "AddSuccess": "新增成功",
        "prompt":"提示",
        "AddCategory": "添加分类",
        "AddScreenCategory": "添加一个大屏分类，根据业务区分大屏"
    }
}
