export default {
  record: {
    data_value: '数据值',
    import_data_excel: '导入数据(Excel)',
    data_source_selection: '数据源选择',
    sql_statement: 'SQL语句',
    api_address: '接口地址',
    request_method: '请求方式',
    ws_address: 'WS地址',
    request_config: '请求配置',
    request_body: '请求参数（Body）',
    json_data: 'JSON数据',
    form_data: 'FORM表单',
    request_headers: '请求头（Headers）',
    filter: '过滤器',
    response_data: '响应数据',
    refresh_data: '响应数据',
    name: '名称',
    please_enter_name: '请输入名称',
    type: '类型',
    please_select_type: '请选择类型',
    data_refresh_success: '数据刷新成功',
    import_success: '导入成功',
    confirm_delete: '此操作将永久删除, 是否继续?',
    prompt: '提示',
    confirm: '确定',
    cancel: '取消',
    delete_success: '删除成功',
    update_success: '修改成功',
    add_success: '新增成功',
    add: '添加',
    description:'添加一个数据集',
  },
};
