<!-- 轮播配置 -->
<template>
  <div>
    <!-- text:类型  -->
    <el-form-item :label="$t('optionSwiper.type')">
      <avue-radio v-model="main.activeOption.type"
                  :dic="dicOption.swiperType"></avue-radio>
    </el-form-item>
     <!-- text:轮播时间  -->
    <el-form-item :label="$t('optionSwiper.carouselTime')">
      <avue-input v-model="main.activeOption.interval"></avue-input>
    </el-form-item>
     <!-- text:选择器  -->
    <el-form-item :label="$t('optionSwiper.selector')">
      <avue-radio v-model="main.activeOption.indicator"
                  :dic="dicOption.swiperIndicator">
      </avue-radio>
    </el-form-item>
     <!-- text:图片透明度  -->
    <el-form-item :label="$t('optionSwiper.imageOpacity')">
      <avue-slider v-model="main.activeOption.opacity"></avue-slider>
    </el-form-item>
     <!-- text:视频自动播放  -->
    <el-form-item :label="$t('optionSwiper.autoPlayVideo')">
      <avue-switch v-model="main.activeOption.autoplay"></avue-switch>
    </el-form-item>
     <!-- text:视频控制台  -->
    <el-form-item :label="$t('optionSwiper.videoControls')">
      <avue-switch v-model="main.activeOption.controls"></avue-switch>
    </el-form-item>
     <!-- text:视频循环  -->
    <el-form-item :label="$t('optionSwiper.loopVideo')">
      <avue-switch v-model="main.activeOption.loop"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
import { dicOption } from '@/option/config'
export default {
  name: 'swiper',
  data () {
    return {
      dicOption: dicOption
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>