<template>
  <div class="compCont">
    <div class="logo">
      <img :src="require('../img/logo.png')" alt="" />
    </div>

    <div class="menuList">
      <div :key="index" v-for="(item, index) in list" :class="active == item.path ? 'item select' : 'item'"
        @click="() => menuClick(item, index)">
        <div class="icon"><i :class="item.icon"></i></div>
        <div class="title">{{ item.name }}</div>
      </div>
    </div>
    <template v-if="this.authorityVerify('RESOURCE_MYGROUPMANAGEMENT', true)">
      <myGroups />
    </template>

  </div>
</template>
<script>
import myGroups from './myGroups';
import { mapGetters } from 'vuex';
export default {
  components: {
    myGroups,
  },
  data() {
    return {
      active: this.$route.path,
      list: [],
      initList: [
        {
          icon: 'icon iconfont icon_icon_Home-01',
          // text: '大屏管理',
          name: this.$t('navs.dashboard'),
          authoritie: 'RESOURCE_SCREENMANAGEMENT',
          path: '/',
        },
        {
          icon: 'icon iconfont  icon_a-icon_SettingClassification-01',
          // text: '分类管理',
          name: this.$t('navs.category'),
          authoritie: 'RESOURCE_CATEGORYMANAGEMENT',
          path: '/category',
        },
        {
          icon: 'icon iconfont  icon_a-icon_SetUpMap2x-01-01',
          // text: '地图管理',
          name: this.$t('navs.mapManagement'),
          authoritie: 'RESOURCE_MAPMANAGEMENT',
          path: '/map',
        },

        {
          icon: 'icon iconfont icon_icon_DataManagement-01',
          // text: '数据源管理',
          name: this.$t('navs.db'),
          authoritie: 'RESOURCE_DATASOURCEMANAGEMENT',
          path: '/db',
        },
        {
          icon: 'icon iconfont icon_icon_ComponentGallery-01',
          // text: '组件库',
          name: this.$t('navs.Component'),
          authoritie: 'RESOURCE_COMPONENTMANAGEMENT',
          path: '/components',
        },
        {
          icon: 'icon iconfont icon_icon_DataSet-01',
          // text: '数据集管理',
          name: this.$t('navs.record'),
          authoritie: 'RESOURCE_DATASETMANAGEMENT',
          path: '/record',
        },

        {
          icon: 'icon iconfont icon_icon_users-01',
          // text: '用户管理',
          name: this.$t('navs.userControl'),
          authoritie: 'RESOURCE_USERMANAGEMENT',
          path: '/userControl',
        },
        // 开放平台
        {
          icon: 'icon iconfont icon_a-icon_OpenSite-01',
          name: this.$t('navs.openPlatform'),
          authoritie: 'RESOURCE_DEVPLATFORMMANAGEMENT',
          path: '/openPlatform',
        },
      ],
    };
  },
  created() {
    this.active = this.$route.path;
  },
  watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        this.active = this.$route.path;
      },
    },
    user(newVal) {
      this.getList(newVal.username)
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    menuClick(item, index) {
      // 新窗口打开
      if (item.open) {
        window.open(item.path);
        return;
      }
      this.active = item.path;
      this.$router.push({ path: item.path });
    },
    getList(data) {
      const auths = this.$store.state.app.user.authorities
      this.initList = this.initList.filter(item => {
        // console.log(item.authoritie, auths.includes(item.authoritie));
        if (item.authoritie) {
          if (auths.includes(item.authoritie)) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      })
      this.$nextTick(() => {
        if (data != 'admin') {
          this.list = this.initList.filter(item => {
            return item.path != '/userControl';
          })
          return;
        }
        this.list = this.initList
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.compCont {
  width: 240px;
  min-height: 100vh;
  background: linear-gradient(4deg, #2d1f33 0%, #1e1824 100%);
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  padding: 16px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }
}

.logo {
  padding: 0 16px;
  margin-bottom: 40px;

  img {
    width: 80px;
    height: 80px;
  }
}

.menuList {
  padding: 0 8px;

  .select {
    background: #4e4cbe;
  }

  .item {
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 8px 8px;
    border-radius: 10px;
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 8px;
    min-height: 38px;

    .icon {
      font-size: 20px;
      margin-right: 6px;
    }

    .title {
      font-size: 14px;
    }
  }
}
</style>
