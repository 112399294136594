<!-- 刻度盘配置 -->
<template>
  <div>
    <!-- text: 刻度值 -->
    <el-form-item :label="$t('optionGauge.scaleValue')">
      <avue-switch v-model="main.activeOption.axisLabelShow"></avue-switch>
    </el-form-item>
    <!-- text: 刻度字号 -->
    <el-form-item :label="$t('optionGauge.scaleFontSize')"
                  v-if="main.activeOption.axisLabelShow">
      <avue-input-number v-model="main.activeOption.axisLabelFontSize"></avue-input-number>
    </el-form-item>
    <!-- text: 刻度粗度 -->
    <el-form-item :label="$t('optionGauge.scaleFontWeight')">
      <avue-input-number v-model="main.activeOption.lineSize"></avue-input-number>
    </el-form-item>
    <!-- text: 刻度颜色 -->
    <el-form-item :label="$t('optionGauge.scaleColor')">
      <avue-input-color v-model="main.activeOption.lineColor"></avue-input-color>
    </el-form-item>
    <!-- text: 指标字体大小 -->
    <el-form-item :label="$t('optionGauge.indicatorFontSize')">
      <avue-input-number v-model="main.activeOption.nameFontSize"></avue-input-number>
    </el-form-item>
    <!-- text: 指标单位 -->
    <el-form-item :label="$t('optionGauge.indicatorUnit')">
      <avue-input v-model="main.activeOption.unit"></avue-input>
    </el-form-item>

  </div>
</template>

<script>
export default {
  name: 'gauge',
  inject: ["main"]
}
</script>

<style>
</style>