export default {
  db: {
    test_connection: '测试连接',
    name: '名称',
    enter_data_source_name: '请输入数据源名称',
    driver_class: '驱动类',
    enter_driver_class: '请输入驱动类',
    username: '用户名',
    enter_username: '请输入用户名',
    password: '密码',
    enter_password: '请输入密码',
    connection_url: '连接地址',
    enter_connection_url: '请输入连接地址',
    note: '备注',
    operation_success: '操作成功!',
    confirm_to_delete_data: '确定将选择数据删除？',
    confirm: '确定',
    cancel: '取消',
    connect_success: '连接成功',
    connect_failure_check_config: '连接失败，请检查相关配置参数',
    add: '添加',
    description:'添加一个数据源',
    please_enter:'例如'
  },
};
