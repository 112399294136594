<template>
    <el-dialog :title="title" :visible.sync="visible" width="1000px">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <div class="formCont">
                <el-form-item label="模块名" style="flex:1" prop="categoryKey">
                    <el-input v-model="form.categoryKey" autocomplete="off" style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="模块值" style="flex:1" prop="categoryValue">
                    <el-input v-model="form.categoryValue" autocomplete="off" style="width:100%"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit">保 存</el-button>
            <el-button @click="visible = false">取 消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { addObj } from '@/api/category'
export default {
    props: {
        change: { type: Function, default: () => { } },
    },
    data() {
        return {
            visible: false,
            title: '新增',
            form: {
                categoryKey: '',
                categoryValue: ''
            },
            rules: {
                categoryKey: [
                    { required: true, message: '请输入模块名', trigger: 'blur' }
                ],
                categoryValue: [
                    { required: true, message: '请输入模块值', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        open(params) {
            Object.assign(this, {
                ...params,
                visible: true
            })
        },
        async submit() {
            this.$refs.form.validate(async valid => {
                if (!valid) return
                var reqData = { ...this.form }
                var rs = await addObj(reqData)
                if (rs.data.success) {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.visible = false
                    this.change()
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.formCont {
    display: flex;
}
</style>