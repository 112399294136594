/** 
 * 自定义组件参考文档
 * https://cn.vuejs.org/v2/guide/components-registration.html
*/
import { website } from '@/config.js'
import $Echart from '../echart/common';
import { KEY_COMPONENT_NAME } from '../echart/variable';

// 这是一个自执行函数
export default (() => {
  let components = {}
  const mixins = [$Echart]

  // 整合项目内自定义组件
  const requireComponent = require.context('.', true, /\w+.(vue|js)$/)
  requireComponent.keys().forEach(fileName => {
    if (fileName.includes('index.vue')) {
      const cmp = requireComponent(fileName).default
      cmp.mixins = mixins
      components[`${KEY_COMPONENT_NAME}${cmp.name}`] = cmp
    }
  })

  // 整合外部引入的js资源组件
  website.componentsList.map(ele => ele.component).forEach(cmp => {
    try {
      // 此时 cmp 是字符串 eval之后 去 window 上寻找 cmp字符串的变量
      cmp = eval(cmp)
      cmp.mixins = mixins
      components[`${KEY_COMPONENT_NAME}${cmp.name}`] = cmp
    } catch (err) {
      console.log(err)
    }
  })

  return components
})()
