<template>
  <div>
  </div>
</template>
<script>
import { login } from '@/api/user';
export default {

  mounted() {
    console.log("login");
    this.demoLogin();
  },
  methods: {
    async demoLogin() {
      window.localStorage.removeItem('screen_token');
      let res = await login({ username: "demo" });
      if (res.data.success) {
        window.localStorage.setItem('screen_token', res.data.data.accessToken);
        window.localStorage.setItem('screen_refreshToken', res.data.data.refreshToken);
        console.log(this.$router.push)

        this.$router.replace({ path: '/' });
      } else {
        this.$message.error(res.data.subMsg);
      }
    },

  },
};
</script>
<style lang="scss" scoped></style>
