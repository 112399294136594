<!-- 进度条配置 -->
<template>
  <div>
    <!-- text:类型  -->
    <el-form-item :label="$t('optionProgress.type')">
      <avue-radio v-model="main.activeOption.type"
                  :dic="dicOption.line">
      </avue-radio>
    </el-form-item>
     <!-- text:宽度  -->
    <el-form-item :label="$t('optionProgress.width')">
      <avue-input-number v-model="main.activeOption.strokeWidth"
                         :max="50"></avue-input-number>
    </el-form-item>
     <!-- text:颜色  -->
    <el-form-item :label="$t('optionProgress.color')">
      <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
    </el-form-item>
     <!-- text:背景颜色  -->
    <el-form-item :label="$t('optionProgress.backgroundColor')">
      <avue-input-color v-model="main.activeOption.defineBackColor"></avue-input-color>
    </el-form-item>
     <!-- text:字体大小  -->
    <el-form-item :label="$t('optionProgress.fontSize')">
      <avue-input-number v-model="main.activeOption.fontSize"
                         :max="200"></avue-input-number>
    </el-form-item>
     <!-- text:字体颜色  -->
    <el-form-item :label="$t('optionProgress.fontColor')">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
     <!-- text:文字粗细  -->
    <el-form-item :label="$t('optionProgress.fontWeight')">
      <!-- <avue-select v-model="main.activeOption.FontWeight"
                   :dic="dicOption.fontWeight"> -->
      <avue-select v-model="main.activeOption.fontWeight"
                   :dic="dicOption.fontWeight">
      </avue-select>
    </el-form-item>
     <!-- text:前缀字体大小  -->
    <el-form-item :label="$t('optionProgress.prefixFontSize')">
      <avue-input-number v-model="main.activeOption.suffixFontSize"
                         :max="200"></avue-input-number>
    </el-form-item>
     <!-- text:前缀字体颜色  -->
    <el-form-item :label="$t('optionProgress.prefixFontColor')">
      <avue-input-color v-model="main.activeOption.suffixColor"></avue-input-color>
    </el-form-item>
     <!-- text:前缀文字粗细  -->
    <el-form-item :label="$t('optionProgress.prefixFontWeight')">
      <avue-select v-model="main.activeOption.suffixFontWeight"
                   :dic="dicOption.fontWeight">
      </avue-select>
    </el-form-item>
  </div>
</template>

<script>
import { dicOption } from '@/option/config'
export default {
  name: 'progress',
  data () {
    return {
      dicOption: dicOption
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>