<!-- iframe配置 -->
<template>
  <div>
    <!-- text: 装饰类型-->
    <el-form-item :label="$t('optionBorderBox.decorationType')">
      <avue-select v-model="main.activeOption.type"
                   :dic="dic">
      </avue-select>
    </el-form-item>
    <!-- text: 主颜色-->
    <el-form-item :label="$t('optionBorderBox.primaryColor')">
      <avue-input-color v-model="main.activeOption.color1">
      </avue-input-color>
    </el-form-item>
    <!-- text: 副颜色-->
    <el-form-item :label="$t('optionBorderBox.secondaryColor')">
      <avue-input-color v-model="main.activeOption.color2">
      </avue-input-color>
    </el-form-item>
    <!-- text: 背景色-->
    <el-form-item :label="$t('optionBorderBox.backgroundColor')">
      <avue-input-color v-model="main.activeOption.backgroundColor">
      </avue-input-color>
    </el-form-item>
    <!-- text: 动画时长-->
    <el-form-item :label="$t('optionBorderBox.animationDuration')">
      <avue-input-number v-model="main.activeOption.dur">
      </avue-input-number>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'borderBox',
  inject: ["main"],
  computed: {
    dic () {
      let result = new Array(12).fill('')
      return result.map((ele, index) => {
        index = index + 1;
        return {
          // text: 边框
          label: this.$t('optionBorderBox.border') + index,
          value: index
        }
      })
    }
  }
}
</script>

<style>
</style>