<template>
  <div class="tableSty">
    <slot></slot>
  </div>
</template>
<script>

export default {

};
</script>
<style lang="scss" scoped>
.tableSty {
  ::v-deep .el-table tr {
    background-color: transparent;
  }

  ::v-deep .el-table {
    background: transparent;
  }

  ::v-deep .el-table th.el-table__cell {
    background-color: transparent;
    border-color: #fff;
  }

  ::v-deep .el-table th.el-table__cell>.cell {
    color: #fff;
  }

  ::v-deep .pagination {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }

  ::v-deep .hover-row .el-table__cell {
    background-color: transparent !important;
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: transparent !important;
  }

  ::v-deep .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-color: #47414A !important;
  }

  ::v-deep .el-table__row {
    transition: all 0.3s;
    color: #fff;

    &:hover {
      background: rgba(84, 90, 255, 0.15);
      box-shadow: inset 0px 0px 12px 0px #5475FF;
      border: 1px solid #6E54FF;
      transition: all 0.3s;
    }
  }

  ::v-deep .el-table .el-table__cell {
    padding: 11px 0;
  }

  ::v-deep .el-table::before {
    background: rgba(255, 255, 255, 0.01);
  }


  ::v-deep .options {
    display: flex;
    align-items: center;
    line-height: 1;

    i {
      font-size: 14px;
      margin-right: 4px;
      position: relative;
      top: 1px;
    }

    .edit {
      color: rgba(128, 126, 255, 1);
      margin-right: 8px;
    }

    .del {
      color: rgba(255, 126, 126, 1);
    }
  }
}
</style>
