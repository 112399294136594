export default {
  record: {
    data_value: 'Data Value',
    import_data_excel: 'Import Data (Excel)',
    data_source_selection: 'Data Source Selection',
    sql_statement: 'SQL Statement',
    api_address: 'API Address',
    request_method: 'Request Method',
    ws_address: 'WebSocket Address',
    request_config: 'Request Configuration',
    request_body: 'Request Body',
    json_data: 'JSON Data',
    form_data: 'Form Data',
    request_headers: 'Request Headers',
    filter: 'Filter',
    response_data: 'Response Data',
    refresh_data: 'Refresh Data',
    name: 'Name',
    please_enter_name: 'Please Enter Name',
    type: 'Type',
    please_select_type: 'Please Select Type',
    data_refresh_success: 'Data Refresh Success',
    import_success: 'Import Success',
    confirm_delete: 'Are you sure you want to permanently delete it?',
    prompt: 'Prompt',
    confirm: 'Confirm',
    cancel: 'Cancel',
    delete_success: 'Delete Success',
    update_success: 'Update Success',
    add_success: 'Add Success',
    add: 'Add',
    description:'Add Data set',
  },
};
