export default {
  build: {
    Layer: '图层',
    Configuration: '配置',
    Data: '数据',
    Interaction: '交互',
    Event: '事件',
    Parameter: '参数',
    Horizontal_Alignment: '水平方式',
    Left_Alignment: '左对齐',
    Center_Alignment: '居中对齐',
    Right_Alignment: '右对齐',
    Vertical_Alignment: '垂直方式',
    Top_Alignment: '顶对齐',
    Middle_Alignment: '中部对齐',
    Bottom_Alignment: '底对齐',
    Group: '成组',
    Delete: '删除',
    Layer_Name: '图层名称',
    Hide: '隐藏',
    Lock: '锁定',
    System_Colors: '系统配色',
    Screen_Size: '大屏大小',
    Screen_Description: '大屏简介',
    Background_Color: '背景颜色',
    Background_Image: '背景图片',
    Preview_Mode: '预览方式',
    X_Axis_Fill: 'X轴铺满，Y轴自适应滚动',
    Y_Axis_Fill: 'Y轴铺满，X轴自适应滚动',
    Stretch_Fill_View: '强行拉伸画面，填充所有视图',
    More_Settings: '更多设置',
    Data_Type: '数据类型',
    SQL_Statement: 'SQL语句',
    API_Address: '接口地址',
    Request_Method: '请求方式',
    WS_Address: 'WS地址',
    Data_Set_Selection: '数据集选择',
    Refresh_Time: '刷新时间',
    Edit_Style: '编辑样式',
    Request_Data: '请求数据',
    Subclass: '子类',
    Parameter_Name: '参数名称',
    Mapped_Field: '映射字段',
    Default_Value: '默认为value',
    Other_Parameters: '其它参数',
    Add_Parameter: '新增参数',
    Click_Event: '点击事件',
    Edit: '编辑',
    Tooltip_Event: '提示事件',
    Title_Event: '标题事件',
    Number: '序号',
    Enter_Animation: '进入动画',
    Click_View_Animation_Type: '点击查看动画类型',
    Position: '位置',
    Size: '尺寸',
    Font: '字体',
    Input_Font_Library_URL: '输入字体库的url',
    Perspective: '透视',
    Scale: '缩放',
    Opacity: '透明度',
    X_Rotation: 'X旋转度',
    Y_Rotation: 'Y旋转度',
    Z_Rotation: 'Z旋转度',
    Global_Request_Address: '全局请求地址',
    Global_Request_Parameters: '全局请求参数',
    Global_Request_Headers: '全局请求头',
    Screen_Watermark: '大屏水印',
    Content: '内容',
    bigSize:'大小',
    Color: '颜色',
    Angle: '角度',
    Data_Value: '数据值',
    Edit_Data_Value: '编辑数据值',
    Import_Data_Excel: '导入数据(Excel)',
    Data_Source_Selection: '数据源选择',
    If_you_want: '如果要获取变量，直接写成函数返回sql语句即可',
    Request_Configuration: '请求配置',
    Request_Parameter: '请求参数（Body）',
    JSON_Data: 'JSON数据',
    FORM_Data: 'FORM表单',
    Request_parameters: '请求参数',
    Request_headers: '请求头（Headers）',
    Request_header:'请求头',
    Filter: '过滤器',
    Edit_filter: '编辑过滤器',
    Response_data: '响应数据',
    Copy_successful: '复制成功',
    Copy_failed: '复制失败',
    Import_successful: '导入成功',
    Request_data: '请求数据成功',
    Copy_component: '复制组件成功',
    Paste_component: '粘贴组件成功',
    Batch_delete: '是否批量删除所选图层？',
    Prompt: '提示',
    OK: '确定',
    Cancel: '取消',
  },
};
