export default {
  userControl: {
    add:'Add',
    username: 'Name',
    nickname: 'Nickname',
    sex: 'Gender',
    phone: 'Phone Number',
    createTime: 'Create Time',
    action: 'Action',
    delete:'Delete',
    popover:'Are you sure you want to delete it?',
    tip:'Tip',
    change_pwd:'Change password',
    add: 'Add',
    description:'Add User',
  },
  // 弹窗
  userDialog: {
    Name: 'Name',
    Nickname: 'Nickname',
    Gender: 'Gender',
    phoneNumber: 'Phone Number',
    Password: 'Password',
    confirmPassword: 'Confirm Password',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    form1: 'Please enter a nickname',
    form2: 'Please enter your name',
    form3: 'Please select an option',
    form4: 'Please enter your mobile phone number',
    form5: 'Please enter a password',
    form6: 'Please confirm your password',
    form7: 'The passwords entered do not match',
    form8: 'View user',
    form9: 'Please select your gender',
    form10: 'Please enter a valid phone number',
    viewUser:'View user',
    editUser:'Edit user',
    male:'Male',
    female:'Female',
    addUser:'Add user',
  },
  changPwdDialog:{
    "oldPwd": "Old Password",
    "form4": "Please enter old password",
    "Password": "New Password",
    "confirmPassword": "Confirm New Password",
    "form5": "Please enter new password",
    "form6": "Please enter confirm new password",
    "title": "Change Password",
    Cancel: 'Cancel',
    Confirm: 'Confirm',
  }
};
