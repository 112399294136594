<template>
  <div class="top-nav">
    <el-menu class="nav" mode="horizontal" background-color="#212528" text-color="#fff" active-text-color="#2681ff" @mousedown="contain.handleInitActive">
      <el-submenu :index="index + ''" v-for="(item, index) in baseList" :key="index">
        <template slot="title">
          <el-tooltip effect="dark" :content="item.label" placement="top">
            <i :class="'nav__icon iconfont ' + item.icon"></i>
          </el-tooltip>
        </template>
        <div style="width: 390px">
          <el-menu-item v-for="(citem, cindex) in item.children" @click="handleAdd(citem.option, true)" :key="cindex" class="menu-inline" :index="`${index}-${cindex}`">
            <div class="usehove">
              <img :src="citem.option.img" class="inside-img" />
              <div class="bottom-text">{{ citem.label }}</div>
            </div>
          </el-menu-item>
        </div>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { uuid } from '@/utils/utils';
import { website } from '@/config.js';
import { mapGetters } from 'vuex';
export default {
  inject: ['contain'],
  provide() {
    return {
      contain: this.contain,
    };
  },
  computed: {
    ...mapGetters(['language']),
  },
  data() {
    return {
      baseList: website.baseList,
    };
  },
  created() {
    window.addEventListener('storage', (event) => {
      this.handleStorageEvent(event)
    });
  },
  
  methods: {
    handleAdd(option, first = false) {
      let obj = this.deepClone(option);
      let index = uuid();
      obj.left = 0;
      obj.top = 0;
      obj.index = index;
      if (first) {
        this.contain.nav.unshift(obj);
      } else {
        this.contain.nav.push(obj);
      }
      setTimeout(() => this.contain.selectNav(index));
    },
    handleStorageEvent(event) {
      if (event.key === 'language') {
        history.go(0)
      }
    }
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('storage', this.handleStorageEvent)
  },
};
</script>

<style lang="scss">
.top-nav {
  .nav {
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    border-color: #333;
  }
  .nav__icon {
    margin-right: 5px;
  }
  .nav .el-submenu .el-submenu__title,
  .nav .el-menu-item {
    height: 45px;
    line-height: 45px;
    font-size: 12px;
  }

  .el-menu-item {
    height: 100px !important;
  }
}
.el-menu--horizontal {
  .menu-inline {
    text-align: center;
    display: inline-block !important;
  }
  .bottom-text {
    color: #b1b1b1;
  }
  .inside-img {
    width: 110px;
    height: 70px;
    border: 2px solid transparent;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .inside-img:hover {
    border-color: #006eff;
  }
  .usehove:hover {
    .bottom-text {
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
