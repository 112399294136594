export default {
    map: {
        "AddMoreMaps": "添加更多地图",
        "MapName": "地图名称",
        "PlsEnterMapName": "请输入地图名称",
        "MapData": "地图数据",
        "DelConfirm": "此操作将永久删除, 是否继续?",
        "Prompt": "提示",
        "Confirm": "确定",
        "Cancel": "取消",
        "DelSuccess": "删除成功",
        "ModSuccess": "修改成功",
        "AddSuccess": "新增成功",
        "AddMap": "添加地图",
        "AddMapDescription": "添加一个地图数据，在大屏中使用",
        "AddMapDescription2": "添加更多地图数据，数据来自第三方",
    }
}
