<!-- 图片配置 -->
<template>
  <div>
    <!-- text:开启旋转  -->
    <el-form-item :label="$t('optionImg.enableRotation')">
      <avue-switch v-model="main.activeOption.rotate"></avue-switch>
    </el-form-item>
    <!-- text:透明度  -->
    <el-form-item :label="$t('optionImg.opacity')">
      <el-slider v-model="main.activeOption.opacity"
                 :max="1"
                 :step="0.1"></el-slider>
    </el-form-item>
    <!-- text:边框圆角  -->
    <el-form-item :label="$t('optionImg.borderRadius')">
      <el-input v-model="main.activeOption.borderRadius">
        <span slot="append">px</span>
      </el-input>
    </el-form-item>
    <template v-if="main.activeOption.rotate">
      <!-- text:旋转时间  -->
      <el-form-item :label="$t('optionImg.rotationTime')">
        <avue-input-number v-model="main.activeOption.duration"></avue-input-number>
      </el-form-item>
    </template>
    <!-- text:图片地址  -->
    <el-form-item :label="$t('optionImg.imageURL')">
      <img v-if="main.activeObj.data.value"
           :src="main.activeObj.data.value"
           alt=""
           width="100%" />
      <el-input v-model="main.activeObj.data.value">
        <div @click="main.handleOpenImg('activeObj.data.value')"
             slot="append">
          <i class="iconfont icon-img"></i>
        </div>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'img',
  inject: ["main"]
}
</script>

<style>
</style>