export default {
  db: {
    test_connection: 'Test Connection',
    name: 'Name',
    enter_data_source_name: 'Please enter data source name',
    driver_class: 'Driver Class',
    enter_driver_class: 'Please enter driver class',
    username: 'Username',
    enter_username: 'Please enter username',
    password: 'Password',
    enter_password: 'Please enter password',
    connection_url: 'Connection URL',
    enter_connection_url: 'Please enter connection URL',
    note: 'Note',
    operation_success: 'Operation Success',
    confirm_to_delete_data: 'Are you sure to delete the selected data?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    connect_success: 'Connect Success',
    connect_failure_check_config: 'Connect Failure, please check the relevant configuration parameters',
    add: 'Add',
    description:'Add a data source.',
    please_enter:'For example'
  },
};
