// import { url } from '@/config';
import request from '../axios';

let url = process.env.userApiUrl


// 用户列表
export const getList = (data) => {
  return request({
    url: url + '/user/pageQuery',
    method: 'post',
    data: data,
  })
}

//新增用户
export const addUser = (data) => {
  return request({
    url: url + '/user/unAuthenticated',
    method: 'post',
    data: data,
  })
}
// 删除用户
export const removeUser = (data) => {
  return request({
    url: url + '/user',
    method: 'delete',
    data: data
  })
}

// 登录
export const login = (data) => {
  return request({
    url: url + '/auth/login',
    method: 'post',
    data: data
  })
}

// 退出登录
export const logOut = (data) => {
  return request({
    url: url + '/user/logout',
    method: 'post',
    data: data
  })
}
// 登录信息
export const getUserInfo = () => {
  return request({
    url: url + '/user',
    method: 'get',
  })
}
// 修改密码
export const changePassword = (data) => {
  return request({
    url: url + '/user/updatePwd',
    method: 'put',
    data:data,
  })
}

