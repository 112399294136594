<template>
  <div class="pageCont">
    <div class="header">
      <div class="left">
        <!-- text:删除 -->
        <el-button :disabled="!(multipleSelection.length > 0)" type="primary" @click="deleteDialog()" size="small">{{
          $t('openPlatform.del') }}</el-button>
      </div>
      <div class="right">
        <el-input v-model="visualName" placeholder="请输入内容" style="width: 300px; margin-right: 20px" @change="getList"
          size="small"></el-input>
        <!-- text:新增 -->
        <el-button type="primary" icon="el-icon-plus" size="small" @click="addEmb">{{ $t('openPlatform.add')
        }}</el-button>
      </div>
    </div>
    <tableSty>
      <div class="tableList">
        <el-table :data="tableData" style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- text:名称 -->
          <el-table-column prop="visualName" :label="$t('openPlatform.name')"></el-table-column>
          <!-- text:开通人 -->
          <el-table-column prop="createUserName" :label="$t('openPlatform.openPerson')"></el-table-column>
          <!-- text:开通时间 -->
          <el-table-column :label="$t('openPlatform.openTime')">
            <template slot-scope="{ row }">
              {{ row.createTime | conversionTime }}
            </template>
          </el-table-column>
          <!-- text:操作 -->
          <el-table-column :label="$t('openPlatform.operation')" width="150">
            <template slot-scope="scope">
              <div class="options">
                <!-- text:编辑 -->
                <el-button @click="createTicket(scope.row, 'edit')" type="text" size="small" class="edit">
                  <i class="el-icon-edit-outline"></i>
                  {{ $t('openPlatform.edit') }}
                </el-button>
                <!-- 删除 -->
                <el-button @click="deleteDialog(scope.row)" type="text" size="small" class="del">
                  <i class="icon iconfont icon_icon_gm1_d-01"></i>
                  {{ $t('openPlatform.del') }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination v-if="total > 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="current" :page-sizes="[10, 20, 30, 50, 100]" :page-size="10" :layout="layout" background
            :total="total"></el-pagination>
        </div>
      </div>
    </tableSty>
    <!-- 新建报表嵌入 -->
    <NewEmbeddedDialog @refreshList="getList" ref="NewEmbeddedDialog" />
    <!-- 编辑 -->
    <EmbConfigurationDialog @refreshList="getList" ref="EmbConfigurationDialog" />
    <DeleteDialog @refreshList="getList" @deleteConfirm="deleteEmb" ref="deleteDialog" />
  </div>
</template>
<script>
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales';
import NewEmbeddedDialog from './newEmbeddedDialog.vue';
import EmbConfigurationDialog from './embConfigurationDialog.vue';
import { getList, removeUser } from '@/api/openPlatform';
import DeleteDialog from '@/page/components/deleteDialog';
import tableSty from '@/page/components/tableSty';
export default {
  components: {
    NewEmbeddedDialog,
    EmbConfigurationDialog,
    DeleteDialog,
    tableSty,
  },
  // 状态数据
  data() {
    // 设置国际化语言
    bindLocalesToVue(this, locales);

    return {
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,

      multipleSelection: [], //tabale多选数据
      tableData: [],
      visualName: '',//搜索名称
    };
  },
  created() {
    // 设置国际化语言
    Object.keys(locales).forEach(key => {
      this.$i18n.mergeLocaleMessage(key, locales[key]);
    });
    this.getList();
  },
  methods: {
    // 新增
    addEmb() {
      if (!this.authorityVerify('RESOURCE_DEVPLATFORMMANAGEMENT_ADD_BTN')) return
      this.$refs.NewEmbeddedDialog.onOpen({ type: 'add' });
    },
    // 编辑生成ticket
    createTicket(data) {
      if (!this.authorityVerify('RESOURCE_DEVPLATFORMMANAGEMENT_UPDATE_BTN')) return
      this.$refs.EmbConfigurationDialog.onOpen(data);
    },
    async getList() {
      const { size, current, visualName } = this;
      const obj = {
        size,
        current,
        visualName,
      };
      let res = await getList(obj);
      if (res.data.success) {
        const { total, size, current, records } = res.data.data;
        this.size = Number(size);
        this.total = Number(total);
        this.current = Number(current);
        this.tableData = records;
        this.$nextTick(() => {
          this.tableData.forEach((item, index) => {
            if (
              this.multipleSelection.findIndex(v => {
                return v == item.id;
              }) >= 0
            ) {
              this.$refs.multipleTable.toggleRowSelection(this.$refs.multipleTable.data[index], true);
            }
          });
        });
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    // 改变size
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 改变Current
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },
    // table多选
    handleSelectionChange(val) {
      if (val.length) {
        val.forEach(row => {
          if (!this.multipleSelection.find(item => item == row.id)) {
            this.multipleSelection.push(row.id);
          }
        });
      } else {
        this.tableData.forEach(row => {
          this.multipleSelection = this.multipleSelection.filter(item => item != row.id);
        });
      }
    },
    // 删除弹窗
    deleteDialog(val) {
      if (val) {
        if (!this.authorityVerify('RESOURCE_DEVPLATFORMMANAGEMENT_DEL_BTN')) return
        this.multipleSelection = [];
        this.multipleSelection.push(val.id);
      }
      if (!this.authorityVerify('RESOURCE_DEVPLATFORMMANAGEMENTT_MULTI_DEL_BTN')) return
      this.$refs.deleteDialog.onOpen();
    },
    // 删除嵌入
    async deleteEmb() {
      let res = await removeUser({ embedAnalyIds: this.multipleSelection });
      if (res.data.success) {
        this.getList();
        this.$message.success(res.data.msg);
        this.$refs.deleteDialog.close();
        this.multipleSelection = [];
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.options {
  display: flex;
  align-items: center;
  line-height: 1;

  i {
    font-size: 14px;
    margin-right: 4px;
  }

  .edit {
    color: rgba(128, 126, 255, 1);
    margin-right: 8px;
  }

  .del {
    color: rgba(255, 126, 126, 1);
  }
}

.pageCont {
  padding: 10px 5px;
}
</style>
