<template>
    <div class="homeCont">
        <optionsButton :list="optionsList" />
        <div class="header">
            <div class="myProjectHeader">
                <div class="left">
                    <i class="icon iconfont icon_icon_myp-01"></i>
                    {{ $t('home.myProject') }}
                </div>
                <!-- <div class="right">
                    <i class="icon iconfont icon_icon_px-01"></i>
                    Sort by modification
                    <i class="icon iconfont icon_icon_XX_grey-01"></i>
                </div> -->
            </div>
        </div>
        <div class="tabCopnt">
            <div class="table">
                <div class="tabItem" v-for="(item, index) in list" :key="index">
                    <div class="tabItemLeft">
                        <div class="status">
                            <div class="released" v-if="item.status == 1">{{ $t('home.published') }}</div>
                            <div class="unpublished" v-else>{{ $t('home.unpublished') }}</div>
                        </div>

                        <img :src="item.backgroundUrl" alt="" />
                        <div class="itemMenu">
                            <el-tooltip :content="$t('home.preview')">
                                <i class="el-icon-view" @click="handleViews(item, index)"></i>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="tabItemRight">
                        <div class="tabItemName">{{ item.title }}</div>
                        <div class="time"><i class="icon iconfont icon_icon_time-01"></i>{{ item.updateTime }}</div>

                        <div class="redact" @click="handleEdit(item)">
                            {{ $t('home.edit') }}
                        </div>
                        <div class="icons">
                            <div class="iconItem">
                                <el-tooltip :content="$t('home.delete')">
                                    <i class="icon iconfont icon_icon_gm1_d-01" @click="handleDel(item, index)"></i>
                                </el-tooltip>
                            </div>
                            <div class="iconItem">

                                <el-tooltip :content="$t('home.copy')">
                                    <i class="icon iconfont icon_a-icon_copy1-01" @click="handleCopy(item, index)"></i>
                                </el-tooltip>
                            </div>
                            <div class="iconItem">
                                <el-tooltip :content="$t('home.export')">
                                    <i class="icon iconfont icon_icon_downl-01" @click="handleExport(item, index)"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <el-pagination v-if="page.total > 0" layout="total, sizes, prev, pager, next, jumper" background size="small"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="page.size"
                :current-page.sync="page.page" :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { getList, updateObj, delObj, getCategory, copyObj } from '@/api/visual';
import { getObj } from '@/api/visual'
import { url } from '@/config';
import { mapGetters } from 'vuex'
import optionsButton from '@/page/components/optionsButton';
import { bindLocalesToVue } from '@/utils/utils';
import locales from './locales'
export default {
    name: "list",
    components: {
        optionsButton
    },
    computed: {
        ...mapGetters(['myGroups']),
    },
    data() {
        // 设置国际化语言
        bindLocalesToVue(this, locales)

        return {
            optionsList: [
                {
                    icon: require('./img/img2.png'),
                    title: this.$t('home.createScreen1'),
                    description: this.$t('home.description1'),
                    click: () => {
                        if (this.authorityVerify('RESOURCE_SCREENMANAGEMENT_CREATE_TEMPLATE_BTN')) this.handleAdd()
                    }
                },
                {
                    icon: require('./img/img1.png'),
                    title: this.$t('home.createScreen2'),
                    description: this.$t('home.description2'),
                    click: () => {
                        if (this.authorityVerify('RESOURCE_SCREENMANAGEMENT_CREATE_TEMPLATE_USE_BLANK_BTN')) this.handleAdd()
                    }
                },
            ],
            categoryId: '',

            height: 0,
            typeList: [],
            box: false,
            option: {
                submitText: this.$t('home.modifyScreen'),
                column: [
                    {
                        label: this.$t('home.group'),
                        prop: 'category',
                        span: 24,
                        labelWidth: 100,
                        type: 'select',
                        dicUrl: url + '/category/list',
                        props: {
                            label: 'categoryKey',
                            value: 'categoryValue'
                        },
                        rules: [
                            {
                                required: true,
                                message: this.$t('home.selectGroup'),
                                trigger: 'blur'
                            }
                        ]
                    },
                    {
                        label: this.$t('home.screenName'),
                        span: 24,
                        labelWidth: 100,
                        prop: 'title',
                        rules: [
                            {
                                required: true,
                                message: this.$t('home.inputScreenName'),
                                trigger: 'blur'
                            }
                        ]
                    },
                    {
                        label: this.$t('home.password'),
                        span: 24,
                        type: 'password',
                        labelWidth: 100,
                        prop: 'password'
                    },
                    {
                        label: this.$t('home.publishStatus'),
                        prop: 'status',
                        span: 24,
                        labelWidth: 100,
                        type: 'select',
                        dicData: [
                            {
                                label: this.$t('home.unpublishedStatus'),
                                value: 0
                            },
                            {
                                label: this.$t('home.publishedStatus'),
                                value: 1
                            }
                        ]
                    }
                ]
            },
            page: {
                page: 1,
                size: 50,
                total: 0
            },
            form: {},
            activeName: '',
            list: []
        }
    },
    created() {
        this.initCategoryId()
        this.getCategory()
    },
    mounted() {
        this.height = document.body.clientHeight - 230
    },
    watch: {
        //监听路由地址的改变
        $route: {
            immediate: true,
            handler() {
                this.setCategoryId()
                this.handleSelect(this.categoryId)
            }
        }
    },
    methods: {
        // 设置 分类id
        setCategoryId() {
            this.categoryId = this.$router.history.current.query.categoryId
        },
        // 检测分类id 没有参数跳转
        initCategoryId() {
            var query = this.$router.history.current.query
            var myGroups = this.myGroups
            // 获取第一个分类
            if (!query.categoryId && myGroups[0]) {
                setTimeout(() => {
                    this.$router.replace({ path: '/', query: { categoryId: myGroups[0].categoryValue } })
                }, 50)
            }
        },
        handleSelect(key) {
            this.activeName = key;
            this.page.page = 1;
            this.getList();
        },
        vaildData(id) {
            const list = [];
            for (var i = 0; i < 20; i++) {
                list.push(i)
            }
            return list.includes(id)
        },
        getCategory() {
            getCategory().then(res => {
                const data = res.data.data;
                this.typeList = data;
                this.activeName = (data[0] || {}).categoryValue;
                this.getList();
            })
        },
        handleExport(item) {
            if (!this.authorityVerify('RESOURCE_SCREENMANAGEMENT_IMP_BTN')) return
            getObj(item.id).then(res => {
                this.$message.success(this.$t('home.exportSuccess'))
                const data = res.data.data;
                let mode = {
                    detail: JSON.parse(data.config.detail),
                    component: JSON.parse(data.config.component)
                }
                var zip = new window.JSZip();
                zip.file("view.js", `const option =${JSON.stringify(mode, null, 4)}`);
                zip.file("说明.txt", `把view.js替换index.zip中文件即可,运行index.html即可看到效果`);
                zip.generateAsync({ type: "base64" })
                    .then((content) => {
                        this.downFile("data:application/zip;base64," + content, 'view.zip')
                        setTimeout(() => {
                            location.href = '/index.zip'
                        }, 1000);
                    });
            })
        },
        handleCopy(item) {
            if (!this.authorityVerify('RESOURCE_SCREENMANAGEMENT_CP_BTN')) return

            if (this.$website.isDemo) {
                this.$message.error(this.$website.isDemoTip)
                return
            }
            this.$confirm(this.$t('home.confirmCopy'), this.$t('home.prompt'), {
                confirmButtonText: this.$t('home.confirm'),
                cancelButtonText: this.$t('home.cancel'),
                type: 'warning'
            }).then(() => {
                copyObj(item.id).then((res) => {
                    this.$message.success(this.$t('home.copySuccess'));
                    const id = res.data.data;
                    this.handleEdit({ id })
                })
            }).catch(() => {

            });
        },
        handleDel(item, index) {
            if (!this.authorityVerify('RESOURCE_SCREENMANAGEMENT_DEL_BTN')) return
            if (this.vaildData(index) && this.$website.isDemo) {
                this.$message.error(this.$website.isDemoTip)
                return false;
            }
            this.$confirm(this.$t('home.confirmDelete'), this.$t('home.prompt'), {
                confirmButtonText: this.$t('home.confirm'),
                cancelButtonText: this.$t('home.cancel'),
                type: 'warning'
            }).then(() => {
                delObj(item.id).then(() => {
                    this.list.splice(index, 1)
                    this.$message.success(this.$t('home.deleteSuccess'))
                })
            }).catch(() => {

            });
        },
        handleAdd() {
            this.$router.push({
                path: '/create', query: {
                    category: this.activeName
                }
            })
        },
        handleUpdate(item, index) {
            // if (!this.authorityVerify('2222')) return
            if (this.vaildData(Number(index)) && this.$website.isDemo) {
                this.$message.error(this.$website.isDemoTip)
                return false;
            }
            this.form = item
            this.form.category = this.form.category + '';
            this.box = true;
        },
        handleEdit(item) {
            if (!this.authorityVerify('RESOURCE_SCREENMANAGEMENT_UPDATE_BTN')) return
            let routeUrl = this.$router.resolve({
                path: '/build/' + item.id
            })
            window.open(routeUrl.href, '_blank');
        },
        handleViews(item) {
            let routeUrl = this.$router.resolve({
                path: '/view/' + item.id
            })
            window.open(routeUrl.href, '_blank');
        },
        handleSave(form, done) {
            updateObj({
                id: this.form.id,
                category: this.form.category,
                password: this.form.password,
                status: this.form.status,
                title: this.form.title
            }).then(() => {
                done();
                this.$message.success(this.$t('home.modifySuccess'));
                this.getList();

            })
        },
        handleCurrentChange(val) {
            this.page.page = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.size = val;
            this.getList();
        },
        getList(category) {
            this.list = []
            getList({
                // category: category || this.activeName,
                category: this.categoryId,
                current: this.page.page,
                size: this.page.size,
            }).then(res => {
                const data = res.data.data;
                this.page.total = parseInt(data.total);
                this.list = data.records
                this.initData();
            })
        },
        initData() {
            this.list.forEach((ele, index) => {
                // this.$set(this.list[index], '_menu', false)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.homeCont {
    padding-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    * {
        box-sizing: border-box;
    }
}

.header {
    padding-right: 30px;
    margin-top: 10px;
}

.myProjectHeader {
    border-bottom: 1px solid #2C2C2C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;

    .left {
        border-bottom: 2px solid #5475FF;
        padding-bottom: 10px;

        i {
            font-size: 14px;
        }
    }

    .right {
        color: #B3B3B3;
        font-size: 14px;
        cursor: pointer;
        line-height: 1;
    }
}

.tabCopnt {
    flex: 1;
    position: relative;

    .table {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        line-height: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding-right: -30px;
    }


    .tabItem {
        margin-right: 18px;
        margin-bottom: 18px;
        display: flex;
        border-radius: 6px;
        overflow: hidden;
        position: relative;

        .tabItemLeft {
            flex: 1;
            height: 100%;
            position: relative;
            background-color: #10253A;

            &:hover {
                .itemMenu {
                    opacity: 1;
                    transition: all 0.3s;
                    z-index: 1;
                }
            }

            img {
                width: 320px;
                height: 190px;
            }

            img[src=''],
            img:not([src]) {
                opacity: 0;
            }

            .itemMenu {
                transition: all 0.3s;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: -1;
                opacity: 0;

                i {
                    cursor: pointer;
                    font-size: 20px;
                }
            }

        }

        .tabItemRight {
            z-index: 2;
            margin-left: -10px;
            width: 170px;
            background: #2D3038;
            border-radius: 16px 6px 6px 16px;
            padding: 16px 13px;

            .tabItemName {
                line-height: 1.4em;
                color: #D3D3D4;
                font-size: 16px;
                margin-bottom: 8px;
                max-height: 40px;
                font-weight: 600;
            }

            .time {
                font-size: 12px;
                color: #9D9D9D;

                i {
                    margin-right: 4px;
                    font-size: 12px;
                }
            }
        }
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.redact {
    display: inline-block;
    background: #5475FF;
    border-radius: 18px;
    padding: 10px 16px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    position: absolute;
    bottom: 48px;
    min-width: 68px;
    text-align: center;
}

.icons {
    position: absolute;
    bottom: 16px;
    display: flex;

    .iconItem {
        margin-right: 8px;
        border-right: 1px solid #fff;
        padding-right: 8px;
        line-height: 1;

        &:last-child {
            border-right: 0;
        }
    }

    i {
        color: #fff;
        cursor: pointer;
    }
}

.status {
    position: absolute;
    left: 8px;
    top: 8px;

    .released {
        background: rgba(9, 255, 198, 0.3);
        border-radius: 12px;
        line-height: 1;
        min-width: 84px;
        padding: 6px 16px;
        font-size: 12px;
        text-align: center;
    }

    .unpublished {
        text-align: center;
        background: rgba(255, 139, 9, 0.3);
        border-radius: 12px;
        line-height: 1;
        min-width: 84px;
        padding: 6px 16px;
        font-size: 12px;
    }
}
</style>