<template>
  <div class="newReport">
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :title="title" width="950px" @doClose="dialogVisible = false">
      <div class="header">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <!-- text: 数据对象类型：-->
          <el-form-item :label="$t('newReport.dataObjectType')">
            <!-- text:请选择 -->
            <el-select v-model="formInline.category" :placeholder="$t('newReport.select')" @change="getEmbList">
              <el-option v-for="item in categoryList" :key="item.id" :label="item.categoryKey" :value="item.categoryValue"></el-option>
            </el-select>
          </el-form-item>
          <!-- text:数据对象名称 -->
          <el-form-item :label="$t('newReport.dataObjectName')">
            <!-- text:请输入内容 -->
            <el-input v-model="formInline.visualName" :placeholder="$t('newReport.option')" style="width: 200px; margin-right: 20px" :show-checkbox="true" @change="getEmbList"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <tableSty>
        <div class="tableList">
          <el-table ref="multipleTable" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <!-- text:数据对象名称 -->
            <el-table-column prop="visualName" :label="$t('newReport.name')"></el-table-column>
            <!-- text:类型 -->
            <el-table-column prop="categoryName" :label="$t('newReport.type')"></el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination v-if="total > 0"  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="[10, 20, 30, 50, 100]" :page-size="10" :layout="layout" :total="total" background></el-pagination>
          </div>
        </div>
      </tableSty>
      <div slot="footer" v-if="!disabled">
        <!-- text:取消 -->
        <el-button @click="dialogVisible = false">{{ $t('newReport.cancel') }}</el-button>
        <!-- text: 开通嵌入  -->
        <el-button type="primary" @click="openEmbedding">{{ $t('newReport.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEmbList, getOption, add } from '@/api/openPlatform.js';
import tableSty from '@/page/components/tableSty';
export default {
  name: 'NewEmbeddedDialog',
  components: {
    tableSty
  },
  props: [],
  data() {
    return {
      dialogVisible: false,
      disabled: false, //是否可编辑
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      multipleSelection: [], //多选选中的table
      // text:新增嵌入报表
      title: this.$t('newReport.embeddedReport'),
      tableData: [],//table数据
      categoryList: [], //数据对象类型
      formInline: {
        category: '', //数据对象类型
        visualName: '', //大屏名称
      },
    };
  },
  created() {
    this.getEmbList();
    this.getOption();
  },
  methods: {
    // 获取可嵌入的报表
    async getEmbList() {
      const { size, current } = this;
      const { category, visualName } = this.formInline;

      const obj = {
        size,
        current,
        category,
        visualName,
      };
      let res = await getEmbList(obj);
      if (res.data.success) {
        const { total, size, current, records } = res.data.data;
        this.size = Number(size);
        this.total = Number(total);
        this.current = Number(current);
        this.tableData = records;
        this.$nextTick(() => {
          this.tableData.forEach((item, index) => {
            if (
              this.multipleSelection.findIndex(v => {
                return v == item.visualId;
              }) >= 0
            ) {
              //多选表格当前项，是否被选中
              this.$refs.multipleTable.toggleRowSelection(this.$refs.multipleTable.data[index], true);
            }
          });
        });
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    // 数据类型
    async getOption() {
      let res = await getOption();
      if (res.data.success) {
        this.categoryList = res.data.data;
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    onOpen() {
      this.dialogVisible = true;
      this.formInline = {
        category: '', //数据对象类型
        visualName: '', //大屏名称
      };
      this.size=10;
      this.current=1;
      this.multipleSelection = [];
      this.getEmbList();
    },
    close() {
      this.dialogVisible = false;
    },
    // 开通嵌入
    async openEmbedding() {
      // 调用接口
      let res = await add({ visualIds: this.multipleSelection });
      if (res.data.success) {
        this.$emit('refreshList');
        this.close();
      } else {
        this.$message.error(res.data.subMsg);
      }
    },
    // 改变size
    handleSizeChange(val) {
      this.size = val;
      this.getEmbList();
    },
    // 改变Current
    handleCurrentChange(val) {
      this.current = val;
      this.getEmbList();
    },
    // 多选
    handleSelectionChange(val) {
      if (val.length) {
        val.forEach(row => {
          if (!this.multipleSelection.find(item => item == row.visualId)) {
            this.multipleSelection.push(row.visualId);
          }
        });
      } else {
        this.tableData.forEach(row => {
          this.multipleSelection = this.multipleSelection.filter(item => item != row.visualId);
        });
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
