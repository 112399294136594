<template>
  <div class="changPwdDialog">
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :title="title" width="650px">
      <el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="180px">
        <!-- text：旧密码-->
        <el-form-item :label="$t('changPwdDialog.oldPwd')" prop="password">
          <!-- text： 请输入旧密码  -->
          <el-input v-model="formData.password" :placeholder="$t('changPwdDialog.form4')" clearable show-password :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <!-- text：新密码-->
        <el-form-item :label="$t('changPwdDialog.Password')" prop="password">
          <!-- text： 请输入新密码  -->
          <el-input v-model="formData.newPassword" :placeholder="$t('changPwdDialog.form5')" clearable show-password :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <!-- text：确认新密码 -->
        <el-form-item :label="$t('changPwdDialog.confirmPassword')" prop="assignPassword">
          <!-- text： 请输入确认新密码  -->
          <el-input v-model="formData.assignPassword" :placeholder="$t('changPwdDialog.form6')" clearable show-password :style="{ width: '100%' }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" v-if="!disabled">
        <!-- text:取消 -->
        <el-button @click="dialogVisible = false">{{ $t('changPwdDialog.Cancel') }}</el-button>
        <!-- text: 确定  -->
        <el-button type="primary" @click="handelConfirm">{{ $t('changPwdDialog.Confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { changePassword } from '@/api/user';
export default {
  name: 'UserDialog',
  components: {},
  props: [],
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        // text:请再次输入密码
        callback(new Error(this.$t('userDialog.form6')));
      } else if (value !== this.formData.newPassword) {
        // text:两次输入密码不一致
        callback(new Error(this.$t('userDialog.form7')));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      disabled: false, //是否可编辑
      // text:添加用户
      title: this.$t('changPwdDialog.title'),
      parentData: {},
      formData: {
        password: '',
        newPassword: '',
        assignPassword: '',
      },
      rules: {
        password: [
          {
            required: true,
            // text:请输入密码
            message: this.$t('changPwdDialog.form5'),
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            required: true,
            // text:请输入密码
            message: this.$t('changPwdDialog.form5'),
            trigger: 'blur',
          },
        ],
        assignPassword: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
    };
  },
  methods: {
    // 打开弹窗
    onOpen(data) {
      this.parentData = data;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    close() {
      this.dialogVisible = false;
    },
    // 确定修改密码
    handelConfirm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return;
        let obj = {
          accountId: this.parentData.id,
          ...this.formData,
        };
        let res = await changePassword(obj);
        if (res.data.success) {
          this.$message.success(res.data.subMsg);
          this.close();
        } else {
          this.$message.error(res.data.subMsg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
