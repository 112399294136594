<template>
    <div class="buttonRoot">
        <div class="options">
            <div class="button" :key="index" v-for="(item, index) in list" @click="item.click">
                <div class="buttonCont">
                    <div class="img"><img :src="item.icon" alt=""></div>
                    <div class="texts">
                        <div class="title">{{ item.title }}</div>
                        <div class="description">
                            {{ item.description }}
                        </div>
                    </div>
                    <div class="icon">
                        <i class="icon iconfont icon_icon_add-01"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: []
        },
    },
}
</script>
<style lang="scss" scoped>
.buttonRoot {
    color: #fff;
    display: flex;
    flex-direction: column;

    * {
        box-sizing: border-box;
    }
}

.options {
    .button {
        margin-right: 24px;
        cursor: pointer;
        display: inline-block;
        padding: 30px 24px 34px 24px;
        border-radius: 24px;
        width: 372px;
        position: relative;
        background: #23252C;
        border: 1px solid #3E3E3E;
        transition: all 0.3s;
        margin-bottom: 24px;

        &:hover {
            box-shadow: inset 0px 0px 12px 0px #5475FF;
            border: 1px solid #6E54FF;
            background: rgba(84, 90, 255, 0.15);
            transition: all 0.3s;

            .description {
                transition: all 0.3s;
                color: #fff !important;
            }
        }

        .buttonCont {
            display: flex;
        }

        .img {
            margin-top: 10px;
            margin-right: 16px;

            img {
                height: 34px;
            }
        }

        .texts {
            width: 240px;
            position: relative;
            top: 10px;

            .title {
                font-size: 14px;
                margin-bottom: 4px;
                line-height: 1;
            }

            .description {
                font-size: 12px;
                color: #747474;
                transition: all 0.3s;
                line-height: 17px;
            }
        }

        .icon {
            font-size: 14px;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -4px;
            color: #D9D9DA;
        }
    }
}
</style>