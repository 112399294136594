<template>
  <div class="userDialog">
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :title="title" width="750px">
      <el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="150px">
        <!-- text: 姓名-->
        <el-form-item :label="$t('userDialog.Name')" prop="username">
          <!-- text： 请输入昵称 -->
          <el-input :disabled="disabled" v-model="formData.username" :placeholder="$t('userDialog.form1')" clearable :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <!-- text:昵称 -->
        <el-form-item :label="$t('userDialog.Nickname')" prop="nickname">
          <!-- text： 请输入姓名  -->
          <el-input :disabled="disabled" v-model="formData.nickname" :placeholder="$t('userDialog.form2')" clearable :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <!-- text：性别 -->
        <el-form-item :label="$t('userDialog.Gender')" prop="sex">
          <!-- text： 请选择  -->
          <el-select v-model="formData.sex" :placeholder="$t('userDialog.form3')" style="width: 540px">
            <!-- text:男 -->
            <el-option :label="$t('userDialog.male')" value="M"></el-option>
            <!-- text:女 -->
            <el-option :label="$t('userDialog.female')" value="F"></el-option>
          </el-select>
        </el-form-item>
        <!-- text：手机号-->
        <el-form-item :label="$t('userDialog.phoneNumber')" prop="phone">
          <!-- text： 请输入手机号  -->
          <el-input :disabled="disabled" v-model="formData.phone" :placeholder="$t('userDialog.form4')" clearable :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <!-- text：密码-->
        <el-form-item :label="$t('userDialog.Password')" prop="password">
          <!-- text： 请输入密码  -->
          <el-input :disabled="disabled" v-model="formData.password" :placeholder="$t('userDialog.form5')" clearable show-password :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <!-- text：确认密码 -->
        <el-form-item :label="$t('userDialog.confirmPassword')" prop="assignPassword">
          <!-- text： 请输入确认密码  -->
          <el-input :disabled="disabled" v-model="formData.assignPassword" :placeholder="$t('userDialog.form6')" clearable show-password :style="{ width: '100%' }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" v-if="!disabled">
        <!-- text:取消 -->
        <el-button @click="dialogVisible = false">{{ $t('userDialog.Cancel') }}</el-button>
        <!-- text: 确定  -->
        <el-button type="primary" @click="handelConfirm">{{ $t('userDialog.Confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addUser } from '@/api/user';
export default {
  name:'UserDialog',
  components: {},
  props: [],
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        // text:请再次输入密码
        callback(new Error(this.$t('userDialog.form6')));
      } else if (value !== this.formData.password) {
        // text:两次输入密码不一致
        callback(new Error(this.$t('userDialog.form7')));
      }else{
        callback();
      }
    };
    return {
      dialogVisible: false,
      disabled: false, //是否可编辑
      // text:添加用户
      title: this.$t('userDialog.addUser'),
      formData: {
        username: '',
        nickname: '',
        sex: '',
        phone: '',
        password: '',
        assignPassword: '',
      },
      rules: {
        // 用户名长度限制
        username: [
          {
            required: true,
            // text:请输入昵称
            message: this.$t('userDialog.form1'),
            trigger: 'blur',
          },
        ],
        nickname: [
          {
            required: true,
            // text:请输入姓名
            message: this.$t('userDialog.form2'),
            trigger: 'blur',
          },
        ],
        sex: [
          {
            required: true,
            // text:请选择性别
            message: this.$t('userDialog.form9'),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            // text:请输入手机号
            message: this.$t('userDialog.form4'),
            trigger: 'blur',
          },
          {
            pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
            trigger: 'blur',
            // text:请输入正确的手机号
            message: this.$t('userDialog.form10'),
          },
        ],
        password: [
          {
            required: true,
            // text:请输入密码
            message: this.$t('userDialog.form5'),
            trigger: 'blur',
          },
        ],
        assignPassword: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
    };
  },
  methods: {
    // 新开
    onOpen({ type, id = '' }) {
      if (type == 'view' && id) {
        //查接口
        this.disabled = true;
        // text:查看用户
        this.title = this.$t('userDialog.viewUser');
      }
      if (type == 'edit' && id) {
        //查接口
        this.disabled = false;
        // text:编辑用户
        this.title = this.$t('userDialog.editUser');
      }
      this.formData = {
        username: '',
        nickname: '',
        sex: '',
        phone: '',
        password: '',
        assignPassword: null,
      },
      this.dialogVisible = true;
    },
    // 关闭
    close() {
      this.dialogVisible = false;
    },
    // 登录
    handelConfirm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return;
        let res = await addUser(this.formData);
        if (res.data.success) {
          this.$message.success(res.data.subMsg);
          this.$emit('refreshList');
          this.close();
        }else{
          this.$message.error(res.data.subMsg);
        }
        
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
