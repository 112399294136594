<!-- 字符云配置 -->
<template>
  <div>
    <!-- text：最小字体 -->
    <el-form-item :label="$t('optionWordcloud.minimumFontSize')">
      <avue-input-number v-model="main.activeOption.minFontSize"></avue-input-number>
    </el-form-item>
    <!-- text：最大字体 -->
    <el-form-item :label="$t('optionWordcloud.maximumFontSize')">
      <avue-input-number v-model="main.activeOption.maxFontSize"></avue-input-number>
    </el-form-item>
     <!-- text：间距 -->
    <el-form-item :label="$t('optionWordcloud.spacing')">
      <avue-input-number v-model="main.activeOption.split"></avue-input-number>
    </el-form-item>
     <!-- text：旋转 -->
    <el-form-item :label="$t('optionWordcloud.rotation')">
      <avue-switch v-model="main.activeOption.rotate"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'wordcloud',
  inject: ["main"]
}
</script>

<style>
</style>